import axios from "axios";
import {getCookie} from "../../utils/index";

const API_URL = '/tags/'

// Get types for events
const fetchTags = (args) => {
    var url = API_URL

    url += args['q'] ? '?q=' + args['q'] : ''

    return axios
        .get(url)
        .then((response) => {
            return response.data;
        });
};

const TagService = {
    fetchTags,
};

export default TagService;
import React from 'react';
import DateTime from 'react-datetime';
import moment from 'moment'
import translateLabel from 'react-rrule-generator/src/lib/utils/translateLabel'
import TimePicker from 'rc-time-picker';
import { ClockIcon } from '../svg';

import { TIME_FORMAT, DATE_TIME_FORMAT, DATE_FORMAT } from '../../constants'

export default function HvirfillTimePicker(props) {
    const { onChange, startTime, endTime } = props

    return (
        <div className="row mb-50">
            <div className="col time-picker-container position-relative">
                <TimePicker
                    value={startTime}
                    style={{ width: "100%" }}
                    showSecond={false}
                    allowEmpty={false}
                    placeholder={'Frá klukkan'}
                    format={TIME_FORMAT}
                    className="rdt"
                    onChange={(inputTime) => {
                        const editedEvent = {
                            target: {
                                value: moment(inputTime).format(TIME_FORMAT),
                                name: 'startTime',
                            },
                        };
                        onChange(editedEvent);
                    }}
                />
                <ClockIcon />
            </div>
            <div className="col time-picker-container position-relative">
                <TimePicker
                    value={endTime}
                    style={{ width: "100%" }}
                    showSecond={false}
                    allowEmpty={false}
                    placeholder={'Til klukkan'}
                    format={TIME_FORMAT}
                    className="rdt"
                    onChange={(inputTime) => {
                        const editedEvent = {
                            target: {
                                value: moment(inputTime).format(TIME_FORMAT),
                                name: 'endTime',
                            },
                        };
                        onChange(editedEvent);
                    }}
                />
                <ClockIcon />
            </div>
        </div>
    )
    // }
}

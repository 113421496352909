import React from 'react';

export default class FileUploader extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      files: [],
    };
  }

  componentDidMount() {
    var $form = $('.box');

    if (this.isAdvancedUpload()) {
      $form.addClass('has-advanced-upload');
    }
  }

  handleChange(e) {

    // get the files
    let files = e.target.files;

    // Process each file
    var allFiles = [];
    for (var i = 0; i < files.length; i++) {

      let file = files[i];

      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {

        // Make a fileInfo Object
        let fileInfo = {
          name: file.name,
          type: file.type,
          size: Math.round(file.size / 1000) + ' kB',
          base64: reader.result,
          file: file,
        };

        // Push it to the state
        allFiles.push(fileInfo);

        // If all files have been proceed
        if (allFiles.length == files.length) {
          // Apply Callback function
          if (this.props.multiple) this.props.onDone(allFiles);
          else this.props.onDone(allFiles[0]);
          this.props.setFiles(allFiles)
        }
      } 
    }
  }

  isAdvancedUpload() {
    var div = document.createElement('div');
    return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window;
  };

  removeImage(){
    this.props.removeImage();
  }

  render() {
    const { hideImage } = this.props;

    if (this.props.files.length > 0) {
      let allFiles = this.props.files.map((file) => {
        return (
          <div className="container pr-0 pl-0">
            <div className="row mb-4">
              <div className="col-1"><div className="erase-btn" onClick={this.removeImage.bind(this)}><span>&times;</span></div></div>
              <h5 className="file-name col-10 pl-2 pt-1">{file.name}</h5>
            </div>
            {!hideImage &&
              <div className="file-uploader no-border">
                <img src={file.base64} />
              </div>
            }
          </div>
        )
      })
      return allFiles;
    } else {
      return (
        <form className="file-uploader box" method="post" action="" encType="multipart/form-data">
          <div className="box__input">
            <input
              className="box__file"
              type="file"
              onChange={this.handleChange.bind(this)}
              multiple={this.props.multiple} />
            <div className="box__dragndrop">
              <svg width="101" height="73" xmlns="http://www.w3.org/2000/svg"><path d="M0 .42V72.26H101V.42H0zm2.295 2.317h96.41v57.249L76.61 35.544a1.141 1.141 0 00-1.04-.362 1.145 1.145 0 00-.646.362l-17.288 18.54-24.64-27.81a1.141 1.141 0 00-1.04-.362 1.145 1.145 0 00-.646.362L2.295 57.74V2.737zm56.24 9.27c-4.424 0-8.035 3.645-8.035 8.111 0 4.466 3.61 8.111 8.034 8.111 4.424 0 8.034-3.645 8.034-8.11 0-4.467-3.61-8.112-8.034-8.112zm-.575 2.317c.194-.02.375 0 .574 0 3.183 0 5.739 2.58 5.739 5.794 0 3.214-2.556 5.794-5.739 5.794s-5.739-2.58-5.739-5.794c0-3.013 2.262-5.498 5.165-5.794zM32.1 28.772l36.513 41.172H2.295v-8.872l29.805-32.3zm43.65 9.27l22.955 25.384v6.518H71.697L59.18 55.82l16.57-17.779z" fill="#606060" fillRule="nonzero" /></svg>
              <label htmlFor="file"><strong>Smelltu hér til að sækja mynd</strong><span > eða dragðu hana hingað</span>.</label>
            </div>
            {/* <button className="box__button" type="submit">Upload</button> */}
          </div>
          <div className="box__uploading">Uploading&hellip;</div>
          <div className="box__success">Done!</div>
          <div className="box__error">Error! <span></span>.</div>
        </form>
      );
    }
  }
}

FileUploader.defaultProps = {
  multiple: false,
  hideImage: false,
};
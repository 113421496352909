import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useCopyEventMutation, useDeleteEventMutation } from "../../api/events-list";
import ContextMenu from "../context-menu";
import Spinner from "../spinner";
import { EllipsisVerticalIcon } from "../svg";

/**
 * Event card component
 * @param {Object} props
 * @param {Object} props.event - Event object
 * @param {typeof import("../../constants").EVENT_GROUP_TYPES[number]} props.type - Event type
 */
export default function EventCard({ event, type }) {
    const navigate = useNavigate();
    const user = useSelector((state) => state.user.info);
    const [allowEdit, setAllowEdit] = useState(false);
    const [showContextMenu, setShowContextMenu] = useState(false);
    const [thinking, setThinking] = useState(false);

    const [copyEvent] = useCopyEventMutation();
    const [deleteEvent] = useDeleteEventMutation();

    useEffect(() => {
        if (
            event.owner !== undefined &&
            user.id !== undefined &&
            (event.owner == user.id || user.is_superuser)
        ) {
            setAllowEdit(true);
        }
    }, [user, event]);

    const selectEvent = useCallback(() => {
        navigate(`/events/${event.id}`);
    }, [event]);

    const doEditEvent = useCallback(() => {
        navigate(`/events/${event.id}/edit`);
    }, [event]);

    const doDelete = useCallback(async () => {
        if (window.confirm("Ertu viss um að þú viljir eyða þessum viðburði?")) {
            setThinking(true);

            try {
                await deleteEvent({ id: event.id }).unwrap();
            } catch (error) {
                console.error(error);

                setThinking(false);
            }
        }
    }, [event]);

    const doCopy = useCallback(async () => {
        setThinking(true);

        try {
            const data = await copyEvent({ id: event.id }).unwrap();

            if (data) {
                navigate(`/events/${data.id}/edit`);
            }
        } catch (error) {
            console.error(error);

            setThinking(false);
        }
    }, [event]);

    const onContextMenuClose = useCallback(() => {
        setShowContextMenu(false);
    }, []);

    return (
        <div className="col-lg-3 col-md-4 col-6 card">
            <div className="card-inner h-100 d-flex flex-column position-relative">
                <div className="cursor-pointer" onClick={selectEvent}>
                    <img src={event.event_image} alt={event.title} loading="lazy" />
                    <div className="card-content-container d-flex flex-column justify-content-between">
                        <h3 className="card-name bold">{event.title}</h3>
                        <EventCardDate dates={event.dates} type={type} />
                    </div>
                </div>
                {allowEdit && event.template && (
                    <div className="position-absolute top-0 right-0 p-2">
                        <div
                            className="cursor-pointer position-relative p-1 bg-light rounded-circle"
                            role="button"
                            onClick={() => setShowContextMenu(true)}
                        >
                            <EllipsisVerticalIcon className="w-12" />
                            <ContextMenu
                                show={showContextMenu}
                                edit={doEditEvent}
                                del={doDelete}
                                copy={doCopy}
                                close={onContextMenuClose}
                                placeholders={{
                                    edit: "Breyta viðburði",
                                    delete: "Eyða viðburði",
                                    copy: "Afrita viðburð",
                                }}
                            />
                        </div>
                    </div>
                )}
                {event.festivals && (
                    <div className="py-3 d-flex mt-auto align-items-end">
                        {event.festivals.map((festival, i) => (
                            <div className="d-flex" key={i}>
                                <h5 className="festival">{festival.title}</h5>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            {thinking && <Spinner />}
        </div>
    );
}

/**
 * Event card date component
 * @param {Object} props
 * @param {Array<{start: string, end: string}>} props.dates - Event dates
 * @param {typeof import("../../constants").EVENT_GROUP_TYPES[number]} props.type - Event type
 */
const EventCardDate = React.memo(({ dates, type }) => {
    const eventStartDate = useMemo(() => {
        if (!dates || dates.length == 0) return "";

        return moment(dates[0].start).format("D. MMMM YYYY");
    }, [dates]);

    const eventEndDate = useMemo(() => {
        if (!dates || dates.length == 0) return "";

        // Dates are sorted by start date
        // If there is only one date, we know the end date
        if (dates.length == 1) {
            return moment(dates[0].end).format("D. MMMM YYYY");
        }

        // If there are more than one date, we have to sort the dates by end date (descending)
        const sortedDates = [...dates].sort((a, b) => new Date(b.end) - new Date(a.end));
        return moment(sortedDates[0].end).format("D. MMMM YYYY");
    }, [dates]);

    return (
        <div className="text-balance">
            {(type === "past" || type === "now") && (
                <>
                    {eventStartDate} - &shy;{eventEndDate}
                </>
            )}
            {type === "future" && `Hefst: ${eventStartDate}`}
            {type === "nodate" && "Engin dagsetning"}
        </div>
    );
});

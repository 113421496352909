import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import UserService from "../../api/user";

export const getUser = createAsyncThunk(
    "user/get",
    async (args, thunkAPI) => {
      try {
        const response = await UserService.getUser(args);
        //thunkAPI.dispatch(setMessage(response.data.message));
        return response;
      } catch (error) {
          console.log("Error: ", error)
          const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        //thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
);

export const resetUserPass = createAsyncThunk(
    "user/reset_password",
    async (args, thunkAPI) => {
      try {
        const response = await UserService.resetUserPass(args);
        //thunkAPI.dispatch(setMessage(response.data.message));
        return response;
      } catch (error) {
          console.log("Error: ", error)
          const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        //thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
);

const initialState = {
    info: [],
};


const userSlice = createSlice({
    name: "user",
    initialState,
    extraReducers: {
      [getUser.fulfilled]: (state, action) => {
          state.info = action.payload;
      },
      [getUser.rejected]: (state, action) => {
          state.error = "Ekki tókst að sækja notanda";
      },
      [resetUserPass.fulfilled]: (state, action) => {
          // state.info = action.payload;
          console.log("great success")
      },
      [resetUserPass.rejected]: (state, action) => {
          state.error = "Ekki tókst að breyta lykilorði";
      },
    },
  });
  
  const { reducer } = userSlice;
  export default reducer;

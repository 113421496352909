import React from 'react'
import { Provider, useDispatch } from 'react-redux'
import { store } from '../../store'
import Frontpage from './frontpage';
import ErrorBoundary from '../../components/error-boundary';

export default function Wrapper(props) {
    return (
        <Provider store={store}>
            <ErrorBoundary>
                <Frontpage {...props}/>
            </ErrorBoundary>
        </Provider>
    )
  }
import React, { Component, useEffect, useState } from 'react'; 
import { useDispatch, useSelector } from 'react-redux'
// import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

import { Map, GoogleApiWrapper, Marker, InfoWindow, Listing } from 'google-maps-react';
// import { withGoogleMap, GoogleMap, withScriptjs, InfoWindow, Marker, Map, GoogleApiWrapper } from "react-google-maps";
const mapStyles = {
  width: '100%',
  height: '100%'
};

export function EventMap(props) {
    const {location, google, event} = props
    const lat = location[0]
    const lng = location[1]
    // const {lat, lng} = location

    return (
      <Map
        google={google}
        zoom={14}
        style={mapStyles}
        initialCenter={
          {
            lat: lat,
            lng: lng
          }
        }
      > 
        <Marker name={event.title} position={{ lat: lat, lng: lng }}></Marker>
      </Map>
    );
}
export default GoogleApiWrapper({
  apiKey: 'AIzaSyBepmwfdPRFXkdV55-JOECBdELipeyaEiw' 
})(EventMap);


// import React from 'react'
// import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

// const containerStyle = {
//   width: '400px',
//   height: '400px'
// };

// const center = {
//   lat: -3.745,
//   lng: -38.523
// };

// function MyComponent() {
//   const { isLoaded } = useJsApiLoader({
//     id: 'google-map-script',
//     googleMapsApiKey: "AIzaSyBepmwfdPRFXkdV55-JOECBdELipeyaEiw"
//   })

//   const [map, setMap] = React.useState(null)

//   const onLoad = React.useCallback(function callback(map) {
//     // This is just an example of getting and using the map instance!!! don't just blindly copy!
//     const bounds = new window.google.maps.LatLngBounds(center);
//     map.fitBounds(bounds);

//     setMap(map)
//   }, [])

//   const onUnmount = React.useCallback(function callback(map) {
//     setMap(null)
//   }, [])

//   return isLoaded ? (
//       <GoogleMap
//         mapContainerStyle={containerStyle}
//         center={center}
//         zoom={10}
//         onLoad={onLoad}
//         onUnmount={onUnmount}
//       >
//         { /* Child components, such as markers, info windows, etc. */ }
//         <></>
//       </GoogleMap>
//   ) : <></>
// }

// export default React.memo(MyComponent)
import axios from "axios";

// Public routes
const EVENT_URL = '/search_homepage_events/';
const FESTIVAL_URL = '/api/v1/frontpage_festivals/';
const LIMIT = 200;

// Open, non-template events
const getEvents = (args) => {
    let url = EVENT_URL + `?limit=${LIMIT}`
    if(args && args['offset']){
        url += `&offset=${args['offset'] * LIMIT}`
    }

    return axios
        .get(url)
        .then((response) => {
            return response.data;
        });
};

const searchEvents = ({searchTerm, limit=10, offset=0}) => {
    let url = EVENT_URL + `?term=${searchTerm}&limit=${limit}&offset=${offset}`

    return axios
        .get(url)
        .then((response) => {
            return response.data;
        });
}

const getFestivals = (args) => {    
    return axios
        .get(FESTIVAL_URL)
        .then((response) => {
            return response.data;
        });
};

const getFestivalById = (args) => {
    var url = `${FESTIVAL_URL}${args['id']}/`
    
    return axios
        .get(url)
        .then((response) => {
            return response.data;
        });
};

const GuestService = {
    getEvents,
    searchEvents,
    getFestivals,
    getFestivalById
};

export default GuestService;

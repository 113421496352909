// const $ = require( "jquery" )( window );
import $ from 'jquery';
window.$ = $;
window.jQuery = $;
const bootstrap = require('bootstrap');

require('./views/frontpage-container');
require('./views/navsearch-container');
require('./views/dashboard-container');


import "../../sass/main.scss";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import HvirfillTimepicker from './timepicker'
import HvirfillDatepicker from './datepicker'
import moment from 'moment'
import { CloseIcon, ClockIcon } from "../svg";
import ImgCropper from "../img-cropper";
import AddressSearch from '../address-autocomplete/index';
import { addSelected, removeSelected } from "../../slices/address";
import { createService, editService, updateService, cancelEditCopy, cancelEditService, getService } from "../../slices/services";
import BasicMap from "../map";
import { removeElementFromArray } from "../../utils";
import Spinner from "../spinner";
import { SERVICE_ERRORS, OPENING_HOURS_DEFAULT, WEEKDAYS, DATE_FORMAT } from '../../constants'

export default function NewService(props){
    const dispatch = useDispatch()
    const { register, setValue, handleSubmit, reset, resetField, watch, getValues, clearErrors, formState: { errors } } = useForm();
    const services = useSelector((state) => state.services.results)
    const editing = useSelector((state) => state.services.editing)
    const editingCopy = useSelector((state) => state.services.cpEdit)
    const editedService = useSelector((state) => state.services.selectedService)
    const categories = useSelector((state) => state.categories.services)
    const address = useSelector((state) => state.addressSearch.selectedItem)
    const [publish, setPublish] = useState(false)
    const [imageSrc, setImageSrc] = useState('')
    const [openingHours, setOpeningHours] = useState(OPENING_HOURS_DEFAULT)
    const [openingHoursOverrides, setOpeningHoursOverrides] = useState([])      // Different opening hours
    const [openingHoursExceptions, setOpeningHoursExceptions] = useState([])    // Closed on these dates
    const [selectedCategories, setSelectedCategories] = useState([])
    const [wheelchairAccess, setWheelchairAccess] = useState(false)
    const [saving, setSaving] = useState(false)
    
    const params = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        if (params.id) {
            let service = services.filter(f => +f.id === +params.id)
            if (service.length > 0) {
                dispatch(editService(service[0]))
            } else {
                dispatch(getService(+params.id))
            }

        }
        return () => {
            dispatch(cancelEditCopy())
            dispatch(cancelEditService())
        }
    }, [])

    // Only purpose with this is to remove errors regarding missing input
    // Nothing worked as it should so we needed a small workaround
    useEffect(() => {
        setValue('wheelchair_access', wheelchairAccess)
    }, [wheelchairAccess])

    const emailPatternObj =  { 
        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: 'Tölvupóstur ekki á réttu formi',
    }

    const onSubmit = (data) => {
        setSaving(true)
        let addr = data.address
        data['formatted_address'] = addr.address;
        data['location'] = addr.lat + " " + addr.lng;
        data['postal'] = addr.postal;
        data['street'] = addr.street;
        data['city'] = addr.city;
        
        data['active'] = publish
        data['wheelchair_access'] = wheelchairAccess

        // Do not attach image file if editing and not adding
        if(data.img && data.img['0'].base64){
            let image = data.img['0']
            data['image'] = { ...image, file: image.base64}
        }

        function getFullPath(obj){
            let tmp = obj
            if(!obj.includes('//')){
                tmp = 'http://' + obj
            }
            return tmp
        }

        // Ignore if no input
        data['website'] = data['website'] ? getFullPath(data['website']) : ''

        data['servicetype_ids'] = selectedCategories
        data['opening_hours_default'] = openingHours
        data['opening_hours_exceptions'] = openingHoursExceptions
        data['opening_hours_overrides'] = openingHoursOverrides

        if(editing){
            dispatch(updateService({'id': editedService.id, 'service': data})).then(() => {
                reset()
                navigate('/services')
            })
        } else {
            dispatch(createService({'service': data})).then(() => {
                reset()
                navigate('/services')
            })
        }
    };
    
    useEffect(() => {
        if (editing || editingCopy){

            let addrObj = {
                address: editedService.street,
                city: editedService.city,
                lat: editedService.latitude,
                lng: editedService.longitude,
                postal: editedService.postal,
                street: editedService.street
            }

            dispatch(addSelected(addrObj))

            // dispatch(addSelected({
            //     address: editedService.street,
            //     city: editedService.city,
            //     lat: editedService.latitude,
            //     lng: editedService.longitude,
            //     postal: editedService.postal,
            //     street: editedService.street
            // }
            // ))

            let active = editingCopy ? false : editedService.active

            setPublish(active)
            setImageSrc(editedService.thumbnail_image)
            setOpeningHours(editedService.opening_hours_default)
            setOpeningHoursOverrides(editedService.opening_hours_overrides)
            setOpeningHoursExceptions(editedService.opening_hours_exceptions)
            setSelectedCategories(editedService.servicetypes.ids)
            setWheelchairAccess(editedService.wheelchair_access)


            let t = editedService.title
            let t_en = editedService.title_en

            if(editingCopy){
                t += ' afrit'
                t_en += ' copy'
            }

            reset({
                title: t,
                title_en: t_en,
                description: editedService.description,
                description_en: editedService.description_en,
                website: editedService.website,
                phone: editedService.phone,
                img: editedService.thumbnail_image,
                email: editedService.email,
                address: addrObj,
            })

            // Add animations to filled fields
            Object.keys(getValues()).map((key) => {
                $('label[for=' + key + ']').addClass("animate-label")
            })
            
        }
    }, [editedService])

    useEffect(() => {
        slideLabelsUp();
    }, [])

    function setValClearErr(name, value) {
        setValue(name, value) 
        clearErrors(name)
    }

    const getServiceErrorMsg = (key) => { return SERVICE_ERRORS[key] }

    // react-hook-form template
    const getInputField = (name, required, id="", classname="", pattern={}) => {
        return (<>
                <input id={name} className={classname} {...register(name, {required: required, pattern: pattern,})}/>
                { errors[name] ? <div className="error-msg text-left">{ getServiceErrorMsg(name) }</div> : null } 
            </>)
    }

    // React-hook-form template
    const getTextArea = (name, required=false, id="", classname="", pattern={}) => {
        return (
            <>
                <textarea  id={name} className={classname} 
                    {...register(name, { required: required, pattern: pattern })}></textarea>
                { errors[name] ? <div className="error-msg text-left">{ getServiceErrorMsg(name) }</div> : null} 
            </>
        )
    }

    // Change pening hours, keys: 'open', 'from' and 'to'
    function updateOpeningValue(day, key, value){
        const updatedOpenings = {
            ...openingHours,
            [day]: {
                ...openingHours[day],
                [key]: value
            }
        }
        setOpeningHours(updatedOpenings)
    }

    // Change times for override dates
    function handleChangeOverrideTimeDefault(e) {
        let updatedOverride = [...openingHoursOverrides]
        updatedOverride.map((item) => {
            if(item.date === e.value.format(DATE_FORMAT)){
                item[e.type] = e.value.format('HH:mm')
            }
        })
        setOpeningHoursOverrides(updatedOverride)
    }

    
    function handleCategoryChange(idStr) {
        let id = parseInt(idStr)

        let updatedCategories = [...selectedCategories]
        if(updatedCategories.includes(id)){
            updatedCategories = removeElementFromArray(updatedCategories, id)
        } else {
            updatedCategories.push(id)
        }
        setSelectedCategories(updatedCategories)
    }

    function removeOverride(date){
        let tmp = [...openingHoursOverrides]
        let filtered = tmp.filter(function(el) { return el.date != date; }); 
        setOpeningHoursOverrides(filtered)

    }

    function removeException(date){
        let tmp = [...openingHoursExceptions]
        let filtered = tmp.filter(function(el) { return el.date != date; }); 
        setOpeningHoursExceptions(filtered)
    }

    const Categories = () => {
        return <> 
            { categories && categories.map((category) => {
                return <>
                    <div className={`${category.display_full_width ? 'col-md-12' : 'col-md-6'} col-12 mb-70`}>
                        <h4 className="bold mb-3" id="headingTwo">{category.title}</h4>
                        <div className="container h-100 gray-bg px-4 py-5 ">
                            <div className="row">
                                {category.servicetypes.map((type) =>
                                    <div className={`${category.display_full_width ? 'col-lg-3 col-md-6 col-12' : 'col-lg-6 col-12'} category mb-4 d-flex align-items-center`}>
                                        <input className="mr-3" type="checkbox" value={type.id} id={type.id} checked={selectedCategories.includes(type.id)} onChange={(e) => handleCategoryChange(e.target.value)}></input>
                                        <label className="mb-0" htmlFor={type.id}><h5 className="mb-0">{type.title}</h5></label>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            })
        }
    </>
    }

    const Location = () => {
        return  (<>
            <div className="row mb-5">
                <div className="col-12">
                    <h4 className="bold">Staðsetning</h4>
                </div>
            </div>
            <div className="row mb-50">
                <div className="col-md-6 col-12">
                    <AddressSearch ref={register('address', {required: true})} handleChange={(val) => {setValClearErr('address', val) }}></AddressSearch>
                    {errors['address'] ? <div className="error-msg text-left">{getServiceErrorMsg('address')}</div> : null}
                </div>
                <div className="col-md-6 col-12">
                    <div style={{minHeight: '314px'}} className="service-map-container">
                        <BasicMap marker={address} mapStyles={{ width: '100%', height: '100%'}}></BasicMap>
                    </div>
                </div>
            </div>
        </>)
    }

    const OpeningHours = () => {
        return (<>
            <div className="col-lg-4 col-12">
                <div className="d-flex mb-4 align-items-center justify-content-between">
                    <h5 htmlFor="openings" className="opening-hrs bold">Almennur opnunartími</h5>
                    <div className="days-header d-flex">
                        <div className="d-flex align-items-center mr-5"><p className="smaller mb-0 mr-3">Frá</p><ClockIcon /></div>
                        <div className="d-flex align-items-center"><p className="smaller mb-0 mr-3">Til</p><ClockIcon /></div>
                    </div>
                </div>
                <div id="openings">
                    <div className="card opening-hrs-inner-container">
                        { Object.keys(WEEKDAYS).map((day, val) => {
                            return (<div className="category days">
                                <div className="d-flex align-items-center day border-bottom w-100">
                                    <input className="mr-4" type="checkbox" value={day} id={day} checked={ openingHours[day]['open'] ? "checked" : "" } onChange={() => updateOpeningValue(day, 'open', !openingHours[day]['open'])}></input>
                                    <h5><label className="mb-0" htmlFor={day}>{WEEKDAYS[day]}</label></h5>
                                    {openingHours[day]['open'] &&
                                        <>
                                        <div className="d-flex ml-auto">
                                            <HvirfillTimepicker time={openingHours[day]['from']} type={"from"} handleChangeTime={(e) => updateOpeningValue(day, 'from', e.value.format('HH:mm'))} dayOfWeek={day}></HvirfillTimepicker>
                                            <HvirfillTimepicker time={openingHours[day]['to']} type={"to"} handleChangeTime={(e) => updateOpeningValue(day, 'to', e.value.format('HH:mm'))} dayOfWeek={day}></HvirfillTimepicker>
                                        </div>
                                        </>
                                    }
                                </div>
                            </div>)
                        })}
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-12">
                <HvirfillDatepicker placeholder={'Breyttir opnunartímar'} date={moment()} type={"overrides"} handleChangeDate={(e) => setOpeningHoursOverrides(openingHoursOverrides => [...openingHoursOverrides, {date: e.value, open_from: '08:00', open_to: '16:00'}])}></HvirfillDatepicker>
                { openingHoursOverrides && openingHoursOverrides.map((item) => {
                    return (<div className="category days">
                        <div className="d-flex align-items-center justify-content-between day border-bottom w-100">
                            <h5>{moment(item.date, DATE_FORMAT).format('dddd DD.MM.YYYY')} </h5>
                            <>
                            <div className="d-flex mr-4">
                                <HvirfillTimepicker date={moment(item.date, DATE_FORMAT)} time={item['open_from']} type={"open_from"} handleChangeTime={(e) => handleChangeOverrideTimeDefault(e)}></HvirfillTimepicker>
                                <HvirfillTimepicker date={moment(item.date, DATE_FORMAT)} time={item['open_to']} type={"open_to"} handleChangeTime={(e) => handleChangeOverrideTimeDefault(e)}></HvirfillTimepicker>
                            </div>
                            </>
                            <span className="remove-date" onClick={() => removeOverride(item.date)}>
                                <CloseIcon />
                            </span>
                        </div>
                    </div>)
                })}
            </div>
            <div className="col-lg-4 col-12 closed-days">
                <HvirfillDatepicker placeholder={'Lokanir'} date={moment()} type={"exceptions"} handleChangeDate={(e) => setOpeningHoursExceptions(openingHoursExceptions => [...openingHoursExceptions, {date: e.value}])}></HvirfillDatepicker>
                {openingHoursExceptions.map((key) => {
                    return (<div className="category days">
                        <div className="selected-dates d-flex align-items-center justify-content-between day border-bottom w-100">
                            <h5>{moment(key.date, DATE_FORMAT).format('dddd DD/MM/YYYY')} </h5>
                            <span className="remove-date" onClick={() => removeException(key.date)}>
                                <CloseIcon />
                            </span>
                        </div>
                    </div>)
                })}
            </div>
        </>)
    }

    return (
        <>
        <div className="new-service-container">
            <div className={"new-service"}>
                <div className="container">
                    <div className="row header-row">
                        <div className="col-12 d-flex justify-content-between align-items-center">
                            { editing ? <h2 className="bold">Breyta stað</h2> : <h2 className="bold">Nýr staður</h2> }
                            <button className="close-button secondary" onClick={() => history.back()}>Hætta við</button>
                        </div>
                    </div>
                </div>
                <form className="container" onSubmit={handleSubmit(onSubmit)}>
                    <div className="row mb-5">
                        {/* islenska */}
                        <div className="col-md-6 col-12">
                            <h4 className="bold mb-4">Íslenska</h4>
                            <div className="input-container">
                                <label htmlFor="title">Heiti Staðar</label>
                                { getInputField('title', true, 'title', 'is-placeName')}
                            </div>
                            <div className="input-container mb-0">
                                <label htmlFor="description">Lýsing á stað</label>
                                { getTextArea('description', true, 'description', 'description')}
                            </div>
                        </div>
                        {/* enska */}
                        <div className="col-md-6 col-12">
                            <h4 className="bold mb-4">Enska</h4>
                            <div className="input-container">
                                <label htmlFor="title_en">Heiti staðar á ensku</label>
                                { getInputField('title_en', true, 'title_en', 'placeName_en')}
                            </div>
                            <div className="input-container mb-0">
                                <label htmlFor="description_en">Lýsing á stað á ensku</label>
                                { getTextArea('description_en', true, 'description_en', 'description_en')}
                            </div>
                        </div>
                    </div>  {/*  is-en-row */}
                    
                    <div className="row mb-50">
                        <div className="col-md-4 col-12">
                            <div className="input-container">
                                <label htmlFor="website">Vefsíða</label>
                                { getInputField('website', true, 'website')}
                            </div>
                        </div>
                        <div className="col-md-4 col-12">
                            <div className="input-container">
                                <label htmlFor="email">Tölvupóstur</label>
                                { getInputField('email', true, 'email' , emailPatternObj) }
                            </div>
                        </div>
                        <div className="col-md-4 col-12">
                            <div className="input-container">
                                <label htmlFor="phone">Sími</label>
                                { getInputField('phone', true, 'phone')}
                            </div>
                        </div>

                        <div className="col-12 row">
                            <h4 className="bold col-4">Hjólastólaaðgengi?</h4>
                            <div className="col-8">
                                <input className="mr-2" type="radio" id="yes" name="wheelchair_access" checked={wheelchairAccess == true} onClick={() => setWheelchairAccess(true)} {...register("wheelchair_access", { required: true})}/>
                                <label className="mr-4" htmlFor="yes">Já</label>
                                <input className="mr-2" type="radio" id="no" name="wheelchair_access" checked={wheelchairAccess == false} onClick={() => setWheelchairAccess(false)} {...register("wheelchair_access", { required: true})}/>
                                <label htmlFor="no">Nei</label>
                            </div>
                        </div>
                        <div className="col-12">
                            { errors["wheelchair_access"] ? <div className="error-msg text-left">{ getServiceErrorMsg("wheelchair_access") }</div> : null } 
                        </div>
                    </div>


                    <div className="row mb-5">
                        <div className="col-12">
                            <h4 className="bold">Mynd</h4>
                        </div>
                    </div>
                    <ImgCropper register={register} ref={register('img', { required: true})} onUpload={(e) => { setValClearErr('img', e) }} onFocalSelect={(rect) => setValClearErr('focal_point', rect)}></ImgCropper>
                    {errors['img'] ? <div className="error-msg img text-left mb-50">Mynd vantar</div> : null}
                    {errors['focal_point'] ? <div className="error-msg img text-left mb-50">Vinsamlegast veljið fókussvæði</div> : null}
                    { imageSrc && 
                    <>
                        <div className="row mb-50">
                            <div className="col-lg-6 col-12">
                                <h5 className="bold mb-4">Núverandi mynd:</h5>
                                <div style={{height: '400px'}}><img className="h-100 w-100" style={{objectFit: 'contain'}} src={imageSrc} /></div>
                            </div>
                        </div>
                    </>
                    }
                    <Location></Location>
                    <div className="row mb-5">
                        <div className="col-12">
                            <h4 className="bold">Opnunartímar</h4>
                        </div>
                    </div>
                    <div className="row mb-50">
                        <OpeningHours></OpeningHours>
                    </div>
                    <div className="row mb-5">
                        <div className="col-12">
                            <h4 className="bold">Flokkun</h4>
                        </div>
                    </div>
                    <div className="row mb-50">
                        <Categories></Categories>
                    </div>
                    <div className="row d-flex justify-content-end">
                        <div className="col-12 d-flex justify-content-between justify-content-md-end">
                            <div className="toggle-outer-container d-flex align-items-center">
                                <label className="mr-5" htmlFor={'publishNow'}>{ editing ? 'Staður í birtingu' : 'Birta stað strax'}</label>
                                <div className="toggle-container"><input className="toggle" type="checkbox" value={publish} id={'publishNow'} checked={publish} onChange={(e) => setPublish(!publish)}></input></div>
                            </div>
                            { saving && <Spinner></Spinner> }
                            <button className="primary" type="submit" disabled={saving}> Vista stað </button>
                        </div>
                    </div>
                </form>
            </div> {/*  new-service */}
        </div> {/*  new-service-container */}
        </>
        )
}
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import moment from 'moment'
// import { CloseIcon } from "../svg";
import ImgCropper from "../img-cropper";
import { createFestival, updateFestival, editFestival, setSelectedFestival, getUserFestivals, clearSelectedFestival } from "../../slices/festivals";
import translations from "../../utils/translations";
import FestivalDatePicker from "./dates";
import { FESTIVAL_ERRORS, OPENING_HOURS_DEFAULT, WEEKDAYS, DATE_FORMAT } from '../../constants'
import { UserIcon } from "../svg";
import Spinner from "../spinner";
import { getFestivalAdmin } from "../../slices/festivaladmin";

export default function NewFestival(props) {
    const dispatch = useDispatch()
    const { register, handleSubmit, reset, setValue, getValues, resetField, watch, clearErrors, formState: { errors } } = useForm();
    // const [editing, setEdit] = useState(false)
    const editedFestival = useSelector((state) => state.festivals.selectedFestival)
    const editing = useSelector((state) => state.festivals.editing)
    const [publish, setPublish] = useState(false)
    const [startDate, setStartDate] = useState('')
    const [datepickerType, setDatepickerType] = useState('single')
    const [endDate, setEndDate] = useState('')
    const [saving, setSaving] = useState(false)
    const [festivalImageSrc, setFestivalImageSrc] = useState('')
    const festivalAdmins = useSelector((state) => state.festivaladmins.festivalAdmins)
    const [festivalAdmin, setFestivaladmin] = useState(null)

    const params = useParams()
    const navigate = useNavigate()


    useEffect(() => {
        slideLabelsUp();
    }, [])

    function setValClearErr(name, value) {
        setValue(name, value) 
        clearErrors(name)
    }

    useEffect(() => {
        dispatch(getFestivalAdmin())
        if (params.id) {
            dispatch(getUserFestivals()).then((data) => {
                let f = data.payload.results.all.filter(f => +f.id === +params.id)
                dispatch(editFestival(f[0]))
            })
        }
        return () => {
            dispatch(clearSelectedFestival())
        }
    }, [])
    
    useEffect(() => {
        if(editing){

            reset({
                title: editedFestival.title,
                title_en: editedFestival.title_en,
                description: editedFestival.description,
                description_en: editedFestival.description_en,
                active: editedFestival.active,
                website: editedFestival.website,
                tickets: editedFestival.tickets,
                email: editedFestival.email,
                phone: editedFestival.phone,
                facebook: editedFestival.facebook,
                img: editedFestival.festival_image,
            })

            setFestivalImageSrc(editedFestival.festival_image)
            setPublish(editedFestival.active)
            setFestivaladmin(editedFestival.festival_admin)

            setStartDate(editedFestival.from_date)
            setEndDate(editedFestival.to_date)
            setValue('date', editedFestival.from_date)
            if(editedFestival.from_date !== editedFestival.to_date){
                setDatepickerType('multiple')
            }

             // Add class to labels when editing
             Object.keys(getValues()).map((key) => {
                $('label[for=' + key + ']').addClass("animate-label")
            })

        }
    }, [editing])
    
    const onSubmit = (data) => {
        setSaving(true)
        // data['url'] = data['website']
        data['evs'] = []
        data['from_date'] = moment(startDate).format('YYYY-MM-DD')
        data['to_date'] = moment(endDate).format('YYYY-MM-DD')
        data['active'] = publish
        data['festival_admin'] = festivalAdmin 

        // Do not attach image file if editing and not adding
        if(data.img && data.img['0'].base64){
            let image = data.img['0']
            data['image'] = { ...image, file: image.base64}
        }

        function getFullPath(obj){
            let tmp = obj
            if(!obj.includes('//')){
                tmp = 'http://' + obj
            }
            return tmp
        }

        // Ignore if no input
        data['website'] = data['website'] ? getFullPath(data['website']) : ''
        data['tickets'] = data['tickets'] ? getFullPath(data['tickets']) : ''
        data['facebook'] = data['facebook'] ? getFullPath(data['facebook']) : ''

        if(editing){
            dispatch(updateFestival({ id: editedFestival.id, data: data})).then(() => {
                reset()
                navigate('/festivals')
            })
        } else {
            dispatch(createFestival(data)).then(() => {
                reset()
                navigate('/festivals')
            })
        }

    };

    const emailPatternObj =  { 
        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: 'Tölvupóstur ekki á réttu formi',
    }

    const phonePatternObj =  { 
        value: /^\d{7}$/,
        message: 'Sími ekki á réttu formi'
    }

    function handleDateChange(dateObj){
        if(dateObj.name === 'singleDate'){
            setStartDate(moment(dateObj.value, DATE_FORMAT))
            setEndDate(moment(dateObj.value, DATE_FORMAT))
        }
        else if(dateObj.name === 'startDate'){
            setStartDate(moment(dateObj.value, DATE_FORMAT))
        } else {
            setEndDate(moment(dateObj.value, DATE_FORMAT))
        }
        setValue('date', dateObj.value)
        clearErrors('date')
    }

    const handleFestivalAdminChange = (e) => {
        setFestivaladmin(e.target.value)
        setValue('festival_admin', e.target.value)
        clearErrors('festival_admin')
    }

    const getFestivalErrorMsg = (key) => { return FESTIVAL_ERRORS[key] }

    // react-hook-form template
    const getInputField = (name, required, classname="", pattern={}, validate={}) => {
        return (
            <>
                <input id={name} className={classname}
                    {...register(name, {
                        required: required,
                        pattern: pattern,
                    })}
                />
                { errors[name] ? <div className="error-msg text-left">{ errors[name].message ? errors[name].message : getFestivalErrorMsg(name) }</div> : null } 
            </>
        )
    }

    // React-hook-form template
    const getTextArea = (name, required=false, pattern={}) => {
        return (
            <>
                <textarea id={name}
                    {...register(name, { required: required, pattern: pattern })} ></textarea>
                { errors[name] ? <div className="error-msg text-left">{ getFestivalErrorMsg(name) }</div> : null} 
            </>
        )
    }

    const RenderEventManager = () => {
        return (
            <div className="eventmanagers-container">
                <h4 className="bold mb-2">Viðburðastjóri</h4>
                <div className="managers">
                    <div className="input-container mb-0">
                        <select {...register('festival_admin', { required: true})} id='festival_admin' className={'event-manager'} onChange={handleFestivalAdminChange}>
                            {[{label: '----', value: ''}].concat(festivalAdmins.map(f => { return {value: f.id, label: f.get_full_name}})).map(o => 
                                <option value={o.value} selected={festivalAdmin === o.value ? true : false}>{o.label}</option>
                            )}
                        </select>
                        <UserIcon />
                    </div>
                </div>
                { errors['festival_admin'] ? <div className="error-msg text-left">{ getFestivalErrorMsg('festival_admin') }</div> : null} 
            </div>
        )
    }

    return ( 
        <>
        <div className="new-festival-container">
            <div className="container">
                <div className="row header-row">
                    <div className="col-12 d-flex justify-content-between align-items-center">
                        { editing ? <h2 className="bold">Breyta hátíð</h2> : <h2 className="bold">Ný hátíð</h2> }
                        <button className="close-button secondary" onClick={() => history.back()}>Hætta við</button>
                    </div>
                </div>
            </div>
            <form className="container" onSubmit={handleSubmit(onSubmit)}>
                <div className="row mb-50">
                    {/* islenska */}
                    <div className="col-md-6 col-12">
                        <h4 className="bold mb-4">Íslenska</h4>
                        <div className="input-container">
                            <label htmlFor="title">Heiti hátíðar</label>
                            { getInputField('title', true, 'is-title')}
                        </div>
                        <div className="input-container">
                            <label htmlFor="description">Lýsing hátíðar</label>
                            { getTextArea('description', true, 'description')}
                        </div>
                    </div>
                    {/* enska */}
                    <div className="col-md-6 col-12">
                        <h4 className="bold mb-4">Enska</h4>
                        <div className="input-container">
                            <label htmlFor="title_en" >Heiti hátíðar á ensku</label>
                            { getInputField('title_en', true, 'title_en')}
                        </div>
                        <div className="input-container">
                            <label htmlFor="description_en" >Lýsing hátíðar á ensku</label>
                            { getTextArea('description_en', true, 'description_en')}
                        </div>
                    </div>
                </div>
                <div className='row mb-50'>
                    <div className="col-md-6 col-12 mb-50 mb-md-0">
                        <h4 className="bold">Dagsetning hátíðarinnar</h4>
                        <div className="tabs-container">
                            <FestivalDatePicker {...register('date', { required: true })} onChange={handleDateChange} type={datepickerType} startDate={startDate} endDate={endDate}></FestivalDatePicker>
                        </div>
                        { errors['date'] ? <div className="error-msg text-left">{ getFestivalErrorMsg('date') }</div> : null} 
                        {/* <RenderDatepicker startDate={startDate} endDate={endDate}></RenderDatepicker> */}
                    </div>
                    <div className="col-md-6 col-12">
                        <RenderEventManager></RenderEventManager>
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="col-12">
                        <h4 className="bold">Mynd</h4>
                    </div>
                </div>
                <ImgCropper register={register} ref={register('img', { required: true})} onUpload={(e) => {setValClearErr('img', e) }} onFocalSelect={(rect) => setValClearErr('focal_point', rect)}></ImgCropper>
                {errors['img'] ? <div className="error-msg img text-left mb-50">Mynd vantar</div> : null}
                {errors['focal_point'] ? <div className="error-msg img text-left mb-50">Vinsamlegast veljið fókussvæði</div> : null}
                { festivalImageSrc && 
                <>
                    <div className="row justify-content-center justify-content-md-start mb-50">
                        <div className="col-lg-6 col-11">
                            <h5 className="bold mb-4">Núverandi mynd:</h5>
                            <div style={{height: '400px'}}><img className="h-100 w-100" style={{objectFit: 'contain'}} src={festivalImageSrc} /></div>
                        </div>
                    </div>
                </>
                }
                <div className="row mb-4">
                    <div className="col-12">
                        <h4 className="bold mb-4">Hlekkir og ítarupplýsingar</h4>
                    </div>
                </div>
                <div className="row mb-50">
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="input-container">
                            <label htmlFor="website">Vefsíða hátíðar</label>
                            { getInputField('website', true)}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="input-container">
                            <label htmlFor="email">Netfang</label>
                            { getInputField('email', true, '', emailPatternObj) }
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="input-container">
                            <label htmlFor="phone">Símanúmer</label>
                            { getInputField('phone', true, '', phonePatternObj)}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="input-container">
                            <label htmlFor="tickets">Hlekkur til miðakaupa <span style={{fontSize:'13px'}}>(valkvætt)</span></label>
                            { getInputField('tickets', false)}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="input-container">
                        <label htmlFor="facebook">Hlekkur á facebook <span style={{fontSize:'13px'}}>(valkvætt)</span></label>
                            { getInputField('facebook', false)}
                        </div>
                    </div>
                </div>
                <div className="row d-flex justify-content-end">
                    <div className="col-12 d-flex justify-content-between justify-content-md-end">
                        <div className="toggle-outer-container d-flex align-items-center">
                            <label className="mr-5" htmlFor={'publishNow'}>Birta hátíð strax</label>
                            <div className="toggle-container"><input className="toggle" type="checkbox" value={publish} id={'publishNow'} checked={publish} onChange={(e) => setPublish(!publish)}></input></div>
                        </div>
                        { saving && <Spinner></Spinner> }
                        <button className="primary" type="submit"> Vista hátíð </button>
                    </div>
                </div>
            </form>
        </div> {/*  new-festival-container */}
        </>
    )
}
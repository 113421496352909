import React, { Component, useEffect, useState } from 'react'; 
import { useDispatch, useSelector } from 'react-redux'
import { Map, GoogleApiWrapper, Marker, InfoWindow, Listing } from 'google-maps-react';
// import { withGoogleMap, GoogleMap, withScriptjs, InfoWindow, Marker, Map, GoogleApiWrapper } from "react-google-maps";
const mapStyles = {
  width: '100%',
  height: '500%'
};

export function MapContainer(props) {
    const {places} = props;
    // const places = useSelector((state) => state.services.results)
    const [activeMarker, setActiveMarker] = useState('')
    const [selectedPlace, setSelectedPlace] = useState('')
    const [showInfo, setShowInfo] = useState(false)

    function markerClick(props, marker){
        setSelectedPlace(props)
        setActiveMarker(marker)
        setShowInfo(true)

    }

    return (
      <Map
        google={window.google}
        zoom={14}
        style={mapStyles}
        initialCenter={
          {
            lat: 64.1462,
            lng: -21.9424
          }
        }
      > 
        { places && places.map((place) => {
            return <Marker name={place.title} title={place.title} position={{ lat: place.latitude, lng: place.longitude }} onClick={(props, marker, e) => markerClick(props, marker)}></Marker>
        }) }
         <InfoWindow
          marker={activeMarker}
          visible={showInfo}>
            <div>
              <h4>{selectedPlace.title}</h4>
            </div>
        </InfoWindow>
      </Map>
    );
}
export default GoogleApiWrapper({
  apiKey: 'AIzaSyBepmwfdPRFXkdV55-JOECBdELipeyaEiw' 
})(MapContainer);

import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from 'react-image-crop';
import FileUploader from '../file-uploader';
import { canvasPreview } from "./canvasPreview";
import { ClipIcon } from "../svg";


function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        maxWidth: 50,
        maxHeight: 50,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}

function useDebounceEffect(fn, waitTime, deps) {
  useEffect(() => {
    const t = setTimeout(() => {
      fn.apply(undefined, deps)
    }, waitTime)

    return () => {
      clearTimeout(t)
    }
  }, deps)
}


export default function ImgCropper(props) {
  const { onUpload, onFocalSelect, register } = props;
  const imgContainerHeight = 300
  const imgContainerWidth = 450
  const [imgSrc, setImgSrc] = useState('')
  const [imgError, setImgError] = useState(false)
  const [targetWidth, setTargetWidth] = useState(800)
  const [targetHeight, setTargetHeight] = useState(500)
  // const [targetWidth, setTargetWidth] = useState(10)
  // const [targetHeight, setTargetHeight] = useState(5)
  const previewCanvasRef = useRef(null)
  // const portraitCanvasRef = useRef(null)
  const landscapeCanvasRef = useRef(null)
  const imgRef = useRef(null)
  const [crop, setCrop] = useState({
    unit: '%',
    maxWidth: 30,
    maxHeight: 30,
    aspect: 1
  })
  const [completedCrop, setCompletedCrop] = useState(null)
  // const [portraitCrop, setPortraitCrop] = useState(null)
  const [landscapeCrop, setLandscapeCrop] = useState(null)
  const [imageDimensions, setImageDimensions] = useState(null)
  const [mediaDimensions, setMediaDimensions] = useState(null)
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [aspect, setAspect] = useState(1) // Square
  const [files, setFiles] = useState([])
  const [maxCrop, setMaxCrop] = useState(80)

  useEffect(() => {
    onUpload(files)
  }, [files])

  // Set max crop area for different types of images
  useEffect(() => {
    // Ratio for landscape: 16/9
    // Ratio for portrait: 9/16
    if (mediaDimensions) {
      let max = (mediaDimensions.w / 16) * 9
      setMaxCrop(max)
      // if(imageDimensions.h > imageDimensions.w){
      //     let max = (mediaDimensions.w / 16) * 9
      //     setMaxCrop(max)
      // } else {
      //     let max = (mediaDimensions.h / 16) * 9
      //     setMaxCrop(max)
      // }
    }
  }, [mediaDimensions])

  function onImageLoaded(img) {
    imgRef = img
  }

  function onImageUpload(file) {
    setImgError(false)
    const updateDimensions = (dim) => {
      if (dim.w < targetWidth || dim.h < targetHeight) {
        // Image too small!
        removeFile()
        setImgError(true)
      }
      setImageDimensions({ ...dim })
    }

    var _URL = window.URL || window.webkitURL;
    var img = new Image();
    var objectUrl = _URL.createObjectURL(file.file);
    img.onload = function () {
      const dimensions = {
        w: this.width,
        h: this.height
      }
      updateDimensions(dimensions);
      _URL.revokeObjectURL(objectUrl);
    };
    img.src = objectUrl;
    const reader = new FileReader();
    reader.addEventListener('load', () =>
      setImgSrc(reader.result)
    );

    reader.readAsDataURL(file.file);
    // setFiles(file.file)
    // setFiles(file)
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
      setMediaDimensions({ h: height, w: width })
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop &&
        completedCrop.width &&
        completedCrop.height &&
        landscapeCrop &&
        landscapeCrop.width &&
        landscapeCrop.height &&
        // portraitCrop?.width &&
        // portraitCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        )
        // canvasPreview(
        //   imgRef.current,
        //   portraitCanvasRef.current,
        //   portraitCrop,
        //   scale,
        //   rotate,
        // )
        canvasPreview(
          imgRef.current,
          landscapeCanvasRef.current,
          landscapeCrop,
          scale,
          rotate,
        )
      }
    },
    100,
    [completedCrop, scale, rotate],
  )

  function handleChangeCrop(percentCrop) {
    setCrop(percentCrop)
  }

  function handleCompletedCrop(c, p) {
    let padding = (c.width / 9) * 3.5

    // Calculate landscape crop
    let landscapeX = c.x - padding
    if (landscapeX < 0) {
      landscapeX = 0
    }
    if (landscapeX + c.width + (padding * 2) > mediaDimensions.w) {
      landscapeX = mediaDimensions.w - c.width - (padding * 2)
    }
    let width = (c.width / 9) * 16
    setLandscapeCrop({ ...c, width: width, x: landscapeX })

    setCompletedCrop(c)
    // Get crop size from actual photo size
    let h = p.height/100 * imageDimensions.h
    let w = p.width/100 * imageDimensions.w
    // Get center points
    let x = p.x/100 * imageDimensions.w + w/2
    let y = p.y/100 * imageDimensions.h + h/2
    let fp = {
        height: h,
        width: w,
        x: x,
        y: y
    }
    onFocalSelect(fp)
  }

  function removeFile() {
    setFiles([])
    setImgSrc(null)
    previewCanvasRef.current = null
    // portraitCanvasRef.current = null
    landscapeCanvasRef.current = null
    setCompletedCrop(null)
    // setPortraitCrop(null)
    setLandscapeCrop(null)
  }


  return (
    <div className="image-cropper-container row justify-content-center mb-50">
      <div className="ImgCropper h-100 col-md-6 col-11 mb-4 mb-md-0 order-1">
        <div className="Crop-Controls">
          {/* <input type="file" accept="image/*" onChange={onSelectFile} /> */}
          <FileUploader
            removeImage={(e) => { removeFile() }}
            multiple={false}
            onDone={(f) => onImageUpload(f)}
            setFiles={(f) => setFiles(f)}
            files={files}
            hideImage
          />
        </div>
        {imgError && <div className="error-msg pt-3">Mynd of lítil</div>}
        {imgSrc && (
          <ReactCrop
            {...register('focal_point', { required: "Vinsamlegast veljið fókussvæði á mynd" })}
            src={imgSrc}
            crop={crop}
            onChange={(_, percentCrop) => handleChangeCrop(percentCrop)}
            onComplete={(c, p) => handleCompletedCrop(c, p)}
            onImageLoaded={(img) => onImageLoaded(img)}
            // ruleOfThirds
            aspect={aspect}
            maxWidth={maxCrop}
            maxHeight={maxCrop}
          >
            {imageDimensions &&
              <img
                // maxWidth={imgContainerWidth}
                // maxHeight={imgContainerHeight}
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                onLoad={onImageLoad}
              />
            }
          </ReactCrop>
        )}
      </div>
      <div className="col-md-6 col-11 order-3 order-md-2">
        <div className="instructions h-100 gray-bg">
          <h5 className="bold">Leiðbeiningar</h5>
          <ul>
            <li><h5>Hægt er að hlaða upp jpg eða png skrám.</h5></li>
            <li><h5>Hámarksstærð skráa er 2Mb.</h5></li>
            <li><h5>Lágmarkshæð mynda er 500px.</h5></li>
            <li><h5>Lágmarksbreidd mynda er 800px.</h5></li>
            <li><h5>Myndir geta verið notaðar í mismunandi hlutföllum eftir þörfum hverju sinni, sjá sýnishorn.</h5></li>
            <li><h5>Smellið á mynd og dragið mús yfir til að velja fókussvæði.</h5></li>
          </ul>
          <ClipIcon />
        </div>
      </div>
      <div className="col-12 d-flex mt-0 mt-md-4 mb-4 mb-md-0 order-2 order-md-3">
        {completedCrop && (
          <>
            <div className="cropped-img mr-4">
              <canvas
                ref={previewCanvasRef}
                style={{
                  objectFit: 'contain',
                  width: 90,
                  height: 90,
                }}
              />
            </div>
            {/* { portraitCrop && 
                          <canvas
                              ref={portraitCanvasRef}
                              style={{
                                  border: '1px solid black',
                                  objectFit: 'contain',
                                  width: 180,
                                  height: 320,
                              }}
                          />
                      } */}
            {landscapeCrop &&
              <div className="cropped-img">
                <canvas
                  ref={landscapeCanvasRef}
                  style={{
                    objectFit: 'contain',
                    width: 160,
                    height: 90,
                  }}
                />
              </div>
            }
          </>
        )}
      </div>
    </div>
  )

}
import axios from "axios";
import {getCookie} from "../../utils/index";

const API_URL = '/api/v1/services/'
const ALL_API_URL = '/api/v1/services_all/'


const getService = (id) => {
    var url = API_URL + id + '/'
    
    return axios
        .get(url)
        .then((response) => {
            return response.data;
        });
};

const getServices = (args) => {
    var url = API_URL + '?'
    if(args && args['owner']){
        url += '&owner=' + args['owner']
    }
    if(args && args['limit']){
        url += '&limit=' + args['limit']
    }
    
    return axios
        .get(url)
        .then((response) => {
            return response.data;
        });
};

const getAllServices = (args) => {
    var url = ALL_API_URL + '?'
    return axios
        .get(url)
        .then((response) => {
            return response.data;
        });
};

const createService = (args) => {
    let url = API_URL
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': window.csrf_token,
    }

    return axios
        .post(url, 
            JSON.stringify(args['service']),
            {headers: headers})
        .then((response) => {
            return response.data;
        });
}

const updateService = (args) => {
    let url = API_URL + args['id'] + '/'
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': window.csrf_token,
    }

    return axios
        .patch(url, 
            JSON.stringify(args['service']),
            {headers: headers})
        .then((response) => {
            return response.data;
        });
}

const deleteService = (args) => {
    let url = API_URL + args['id'] + '/'
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': window.csrf_token,
    }

    return axios
        .delete(url, {headers: headers})
        .then((response) => {
            return response.data;
        });
}


const ServiceService = {
    getService,
    getServices,
    getAllServices,
    createService,
    updateService,
    deleteService
};

export default ServiceService;

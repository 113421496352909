import React, { useRef, useCallback } from 'react';

import { useOnLostFocus } from '../../hooks';
import { CloseIcon, EditIcon, CopyIcon, TrashIcon } from '../svg';

export const ContextMenu = ({ edit, del, copy, show, close, placeholders }) => {
   const ref = useRef();

   useOnLostFocus(ref, close);

   const onClickClose = useCallback((e) => {
      e.stopPropagation();
      close();
   }, []);

   if (!show) return null;

   return (
      <div ref={ref} className='position-absolute context-menu dropdown-list'>
         <div className='close' onClick={onClickClose}>
            <CloseIcon />
         </div>
         <ul>
            <li>
               <button
                  className='border-0 w-100'
                  onClick={() => {
                     edit();
                     close();
                  }}
               >
                  <EditIcon />
                  {placeholders['edit']}
               </button>
            </li>
            <li>
               <button
                  className='border-0 w-100'
                  onClick={() => {
                     copy();
                     close();
                  }}
               >
                  <CopyIcon /> {placeholders['copy']}
               </button>
            </li>
            <li>
               <button
                  className='border-0 w-100'
                  onClick={() => {
                     del();
                     close();
                  }}
               >
                  <TrashIcon /> {placeholders['delete']}
               </button>
            </li>
         </ul>
      </div>
   );
};

export default ContextMenu;

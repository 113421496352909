import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { filterEvents, getParamEvents } from '../../slices/events';
import { getEventCategories } from '../../slices/categories';
import { setSelectedFestival, addEventsToFestival, getFestival } from '../../slices/festivals';
import { removeElementFromArray } from '../../utils/index';
import { SearchIcon } from '../svg';
import Spinner from '../spinner';
import moment from 'moment';

// View when user clicks a festival to edit
export default function ConnectEventsModal({ hide }) {
   const dispatch = useDispatch();
   const selectedFestival = useSelector((state) => state.festivals.selectedFestival);
   const singleEvents = useSelector((state) => state.events.filtered.single);
   const rangeEvents = useSelector((state) => state.events.filtered.range);
   const multiEvents = useSelector((state) => state.events.filtered.multiple);
   const [eventIds, setEventIds] = useState([]);
   const [saving, setSaving] = useState(false);
   const [modalSearch, setModalSearch] = useState('');
   const params = useParams();

   // Fetch events for festival dates
   useEffect(() => {
      if (params.id) {
         dispatch(getFestival(params.id)).then((data) => {
            dispatch(setSelectedFestival(data.payload));
         });
      }
      var fromStr = moment(selectedFestival.from_date).format('YYYY-MM-DD');
      var toStr = moment(selectedFestival.to_date).format('YYYY-MM-DD');
      dispatch(filterEvents({ range: fromStr + ',' + toStr }));
   }, []);

   useEffect(() => {
      dispatch(getParamEvents());
      dispatch(getEventCategories());
   }, []);

   useEffect(() => {
      if (!selectedFestival || !selectedFestival.evs) return;

      const ids = selectedFestival.evs.map((item) => item.id);
      setEventIds(ids);
   }, [selectedFestival]);

   const eventCheck = useCallback((id) => eventIds.includes(id), [eventIds]);

   // Add or remove event from festival
   function handleEventClick(id) {
      if (eventCheck(id)) {
         setEventIds(removeElementFromArray(eventIds, id));
      } else {
         setEventIds([...eventIds, id]);
      }
   }

   const attachEventsAndClose = useCallback(async () => {
      setSaving(true);
      await dispatch(addEventsToFestival({ id: selectedFestival.id, eventIds: eventIds }));
      const data = await dispatch(getFestival(params.id));
      await dispatch(setSelectedFestival(data.payload));
      setSaving(false);
      hide();
   }, [selectedFestival, eventIds, hide]);

   const getDates = useCallback((ev) => {
      let occ = ev.occurrences;
      if (ev.occurrence == 'single') {
         return <div>{moment(occ[0].start_time).format('DD.MM.yyyy')}</div>;
      } else if (ev.occurrence == 'range') {
         return (
            <div>
               {moment(occ[0].start_time).format('DD.MM.yyyy')} -{' '}
               {moment(occ[0].end_time).format('DD.MM.yyyy')}
            </div>
         );
      } else if (ev.occurrence == 'multiple') {
         return (
            <div>
               {moment(occ[0].start_time).format('DD.MM.yyyy')} -{' '}
               {moment(occ[occ.length - 1].end_time).format('DD.MM.yyyy')}
            </div>
         );
      } else {
         console.log('Error for: ', ev);
      }
   }, []);

   const applySearch = useCallback(
      (data) =>
         data.filter((ev) => {
            return ev.title.toLowerCase().includes(modalSearch.toLowerCase());
         }),
      [modalSearch]
   );

   // Get single date for event or range within selected dates
   function innerSection(header, data) {
      return (
         <div className='mt-50 table-container'>
            <table>
               <thead>
                  <tr>
                     <th></th>
                     <th>
                        <h5 className='bold'>{header.title}</h5>
                     </th>
                     <th>
                        <h5 className='bold text-right'>{header.dates}</h5>
                     </th>
                  </tr>
               </thead>
               <tbody>
                  {data.length > 0 ? (
                     <>
                        {applySearch(data).map((ev) => (
                           <tr key={ev.id}>
                              <td>
                                 <input
                                    className='mr-4'
                                    type='checkbox'
                                    checked={eventCheck(ev.id)}
                                    onChange={() => handleEventClick(ev.id)}
                                 ></input>
                              </td>
                              <td>
                                 <p className='smaller mb-0 pr-4'>{ev.title}</p>
                              </td>
                              <td>
                                 <div className='smaller mb-0 text-right'>{getDates(ev)}</div>
                              </td>
                           </tr>
                        ))}
                     </>
                  ) : (
                     <tr>
                        <td></td>
                        <td>
                           <p className='smaller mb-0 pr-4'>Engir viðburðir skráðir</p>
                        </td>
                        <td></td>
                     </tr>
                  )}
               </tbody>
            </table>
         </div>
      );
   }

   return (
      <>
         <div className='container pb-5 header'>
            <div className='row justify-content-center'>
               <div className='col-12 mb-5'>
                  <h2>Tengja viðburði</h2>
               </div>
            </div>
            <div className='row'>
               <div className='col-md-8 col-12'>
                  <div className='input-container search'>
                     <input
                        key={'modalSearch'}
                        type='text'
                        placeholder='Leitarorð'
                        onChange={(e) => setModalSearch(e.target.value)}
                        className='pt-0'
                     ></input>
                     <SearchIcon />
                  </div>
               </div>
            </div>
         </div>
         <div className='container pb-5 body'>
            <div className='row'>
               <div className='col-12'>
                  {singleEvents &&
                     innerSection({ title: 'Stakir viðburðir', dates: 'Dagsetning' }, singleEvents)}
               </div>
            </div>
            <div className='row'>
               <div className='col-12'>
                  {rangeEvents &&
                     innerSection(
                        { title: 'Lengri viðburðir', dates: 'Dagsetningar' },
                        rangeEvents
                     )}
               </div>
            </div>
            <div className='row'>
               <div className='col-12'>
                  {multiEvents &&
                     innerSection(
                        { title: 'Endurteknir viðburðir', dates: 'Dagsetningar' },
                        multiEvents
                     )}
               </div>
            </div>
            <div className='row my-5'>
               <div className='col-12 d-flex justify-content-between'>
                  {saving ? (
                     <Spinner></Spinner>
                  ) : (
                     <>
                        <button className='thin border-0' onClick={() => setEventIds([])}>
                           <p className='mb-0 smaller'>Hreinsa</p>
                        </button>
                        <button className='primary smaller' onClick={() => attachEventsAndClose()}>
                           Tengja
                        </button>
                     </>
                  )}
               </div>
            </div>
         </div>
      </>
   );
}

import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { filterEvents, getParamEvents, editEvent } from '../../slices/events';
import { getEventCategories } from '../../slices/categories';
import { setSelectedFestival, clearSelectedFestival, addEventsToFestival, updateFestival, editFestival, getFestival, setShowOwnEvents } from '../../slices/festivals';
import { removeElementFromArray } from '../../utils/index';
import { POSTAL_CODES } from '../../constants';
import { ClockIcon, BackArrowIcon, SearchIcon, PencilIcon } from '../svg';
import moment from 'moment'
import Modal from '../modal';
import Select from 'react-select';
import ConnectEventsModal from './connect-modal';

// Hook for opening modal
const useModal = () => {
    const [isShowing, setIsShowing] = useState(false);

    function toggle() {
        setIsShowing(!isShowing);
    }

    return {
        isShowing,
        toggle,
    }
};

// View when user clicks a festival to edit
export default function FestivalDetails(props) {
    const dispatch = useDispatch()
    const selectedFestival = useSelector((state) => state.festivals.selectedFestival)
    const showOwnEvents = useSelector((state) => state.festivals.showOwnEventsOnly)
    const festivalDates = useSelector((state) => state.festivals.selectedFestival.dates)
    const events = useSelector((state) => state.festivals.selectedFestival.evs)
    const categories = useSelector((state) => state.categories.events)
    const types = useSelector((state) => state.types.events)
    const user = useSelector((state) => state.user.info)
    const [categoryOptions, setCategoryOptions] = useState(false)
    const [postalCodeOptions, setPostalCodeOptions] = useState('')
    const { isShowing, toggle } = useModal();
    const [eventIds, setEventIds] = useState([])
    const [showDates, setShowDates] = useState([])
    const [allDates, setAllDates] = useState([])
    const navigate = useNavigate()
    const params = useParams()

    const [eventsPerDay, setEventsPerDay] = useState()

    // Filters
    const [filters, setFilters] = useState({}) // type, category, date, post
    const [selectedPost, setSelectedPost] = useState('')
    const [selectedCategory, setSelectedCategory] = useState('')
    const [selectedType, setSelectedType] = useState('')
    const [selectedDate, setSelectedDate] = useState('')

    // Fetch events for festival dates
    useEffect(() => {
        if (params.id) {
            dispatch(getFestival(params.id)).then((data) => {
                dispatch(setSelectedFestival(data.payload))
            })
        }
        var fromStr = moment(selectedFestival.from_date).format('YYYY-MM-DD')
        var toStr = moment(selectedFestival.to_date).format('YYYY-MM-DD')
        dispatch(filterEvents({ range: fromStr + ',' + toStr }))
    }, [])

    useEffect(() => {
        dispatch(getParamEvents())
        dispatch(getEventCategories())

        setPostalCodeOptions(POSTAL_CODES.map((code) => {
            return { value: code, label: code }
        }))
    }, [])

    useEffect(() => {
        if (showOwnEvents) {
            addFilter('owner', user.id)
        }
    }, [showOwnEvents])

    useEffect(() => {
        if (categories) {
            setCategoryOptions(categories.map((item) => {
                return { value: item.name, label: item.name }
            }))
        }

    }, [categories])


    useEffect(() => {
       if (!selectedFestival || !selectedFestival.evs) return;

       const ids = selectedFestival.evs.map((item) => item.id)
       setEventIds(ids);
    }, [selectedFestival])

    useEffect(() => {
        if (selectedFestival) {
            let tmp = festivalDates.map((date) => moment(date).format('YYYY-MM-DD'))
            setShowDates(tmp)
            setAllDates(tmp)
        }
    }, [festivalDates])


    // Sort events by festival dates
    useEffect(() => {
        let tmp = {}
        if (selectedFestival) {
            festivalDates.map((date) => {
                let d = moment(date).format('YYYY-MM-DD')
                tmp[d] = []
                events && events.map((ev) => {
                    if (ev.occurrence == 'range' || ev.occurrence == 'single') {
                        let st = moment(ev.occurrences[0].start_time).format('YYYY-MM-DD')
                        let ed = moment(ev.occurrences[0].end_time).format('YYYY-MM-DD')
                        if (moment(d).isBetween(st, ed, undefined, [])) {
                            tmp[d].push(ev)
                        }
                    } else {
                        ev.occurrences.map((eventDate) => {
                            let st = moment(eventDate.start_time).format('YYYY-MM-DD')
                            let ed = moment(eventDate.end_time).format('YYYY-MM-DD')
                            if (moment(d).isBetween(st, ed, undefined, [])) {
                                if(tmp[st]) {
                                    tmp[st].push(ev)
                                } else {
                                    tmp[st] = [ev]
                                }
                            }
                        })
                    }
                })
            })

            setEventsPerDay(tmp)
        }
    }, [events])

    function setShowOwnCheck(value) {
        dispatch(setShowOwnEvents(value))
        if (value) {
            addFilter("owner", user.id)
        } else {
            setFilters(current => {
                const {owner, ...rest} = current;
                return rest;
            });
        }
    }

    function addFilter(key, value) {
        let tmp = { ...filters }
        tmp[key] = value
        setFilters(tmp)
    }

    function clearFilters() {
        setFilters({})
        setSelectedType('')
        setSelectedCategory('')
        setSelectedDate('')
        setSelectedPost('')
        dispatch(setShowOwnEvents(false))
    }

    // Edit single event
    function doEditEvent(ev) {
        dispatch(editEvent(ev))
        navigate(`/events/${ev.id}/edit`)
    }

    // Edit festival
    function doEditFestival(festival) {
        dispatch(editFestival(festival))
        navigate(`/festivals/${festival.id}/edit`)
    }


    function showOrHideDate(date) {
        let tmp = [...showDates]
        if (tmp.includes(date)) {
            tmp = removeElementFromArray(tmp, date)
        } else {
            tmp.push(date)
        }
        setShowDates(tmp)
    }

    function backClick() {
        navigate('/festivals')
    }

    function dayDropdown(dates) {
        return <>
            <div className="input-container">
                <Select className="w-100 cursor-pointer react-select-container"
                    placeholder="Allir dagar"
                    classNamePrefix="react-select"
                    name="daySelect"
                    value={selectedDate}
                    options={dates.map((item) => {
                        return { value: item, label: moment(item).format("DD. MMMM") }
                    })}
                    onChange={(ret) => {
                        setSelectedDate(ret);
                        addFilter('date', ret)
                    }}>
                </Select>
            </div>
        </>
    }

    function categoryDropdown() {
        return (<>
            {categoryOptions &&
                <div className="input-container">
                    <Select className="w-100 cursor-pointer react-select-container"
                        placeholder="Flokkur"
                        classNamePrefix="react-select"
                        name="categorySelect"
                        value={selectedCategory}
                        options={categoryOptions}
                        onChange={(item) => { setSelectedCategory(item); addFilter('category', item.value) }}>
                    </Select>
                </div>
            }
        </>)
    }

    function typeDropdown() {
        return (<>
            {types &&
                <div className='input-container'>
                    <Select className="w-100 cursor-pointer react-select-container"
                        placeholder="Tegund"
                        classNamePrefix="react-select"
                        name="categorySelect"
                        value={selectedType}
                        options={types.map((item) => {
                            return { value: item.name, label: item.name }
                        })}
                        onChange={(t) => { setSelectedType(t); addFilter('type', t.value) }}>
                    </Select>
                </div>
            }
        </>)
    }


    function postalCodeDropdown() {
        return (<>
            {postalCodeOptions &&
                <div className='input-container'>
                    <Select className="w-100 cursor-pointer react-select-container"
                        placeholder="Póstnúmer"
                        classNamePrefix="react-select"
                        name="categorySelect"
                        value={selectedPost}
                        options={postalCodeOptions}
                        onChange={(item) => { setSelectedPost(item); addFilter('post', item.value) }}>
                    </Select>
                </div>
            }
        </>)
    }


    // If filters are on
    function applyFilters(ev) {
        let tmp = [...ev]
        Object.keys(filters).map((filter) => {
            if (filter == 'post') {
                tmp = tmp.filter((ev) => {
                    return ev.postal == filters['post']
                })
            }

            if (filter == 'type') {
                tmp = tmp.filter((ev) => {
                    return ev.tags.includes(filters['type'])
                })
            }

            if (filter == 'category') {
                tmp = tmp.filter((ev) => {
                    return ev.tags.includes(filters['category'])
                })
            }

            if (filter == 'owner') {
                tmp = tmp.filter((ev) => {
                    return ev.owner == filters['owner']
                })
            }
        })
        return tmp
    }

    const EventLine = ({ ev }) => {
        return <div className="d-flex service align-items-center row">
            <div className='time d-flex col-4 pl-0'><ClockIcon />{getDates(ev)}</div>
            <div className="title bold col-6">{ev.title}</div>
            <div className='col-2 d-flex align-items-center justify-content-end'>
                {ev.active &&
                    <div className='status mr-5'><span className={`d-block active`}></span></div>
                }
                {(+user.id === +ev.owner || (user && user.permissions && user.permissions.superuser)) &&
                    <div className="cursor-pointer" onClick={() => doEditEvent(ev)}><PencilIcon /></div>
                }
            </div>
        </div>
    }

    function RenderDates() {
        return (<>
            {selectedDate ?
                <div className='container service-list'>
                    <div className="row justify-content-end">
                        <div className="w-100 mb-5 d-flex date">
                            <h4 className='bold'>{selectedDate.label}</h4>
                            <div className="line d-block col"></div>
                        </div>
                        <div className="w-100 col-xl-10 col-12">
                            {applyFilters(eventsPerDay[moment(selectedDate.value).format('YYYY-MM-DD')]).map((ev) =>
                                <EventLine ev={ev}></EventLine>
                            )}
                        </div>
                    </div>
                </div>
                :
                <div className='container service-list'>
                    {/* { selectedFestival.dates.map((d) => { */}
                    {eventsPerDay && Object.keys(eventsPerDay).map((d) => {
                        let date = moment(d).format("DD. MMMM")
                        return (
                            <div className="row justify-content-end">
                                <div onClick={() => { showOrHideDate(d) }} className={`${!showDates.includes(d) ? 'mb-5' : 'my-2'} w-100 d-flex date`}>
                                    <div className={`${!showDates.includes(d) ? '' : 'open'} close-row d-flex align-items-center justify-content-center`}>
                                        <span className="d-block"></span>
                                        <span className="d-block"></span>
                                    </div>
                                    <h4 className='bold'>{date}</h4>
                                    <div className="line d-block col"></div>
                                </div>
                                {showDates.includes(d) &&
                                    <div className="w-100 col-xl-10 col-12">
                                        {applyFilters(eventsPerDay[d]).map((ev) =>
                                            <EventLine ev={ev}></EventLine>
                                        )}
                                    </div>
                                }
                            </div>
                        )
                    })}
                </div>
            }
        </>)
    }

    function getDates(ev) {
        let occ = ev.occurrences
        if (ev.occurrence == 'single') {
            return <div>{moment(occ[0].start_time).format('DD.MM.yyyy')}</div>
        } else if (ev.occurrence == 'range') {
            return <div>{moment(occ[0].start_time).format('DD.MM.yyyy')} - {moment(occ[0].end_time).format('DD.MM.yyyy')}</div>
        } else {
            return <div>{moment(occ[0].start_time).format('DD.MM.yyyy')} - {moment(occ[occ.length - 1].end_time).format('DD.MM.yyyy')}</div>
        }
    }

   
    return (<>
        {selectedFestival &&
            <>
                <div className='container back-btn'>
                    <div className="row justify-content-center">
                        <div className='col-sm-12 col-11 d-flex align-items-center'>
                            <BackArrowIcon />
                            <div className="ml-3" onClick={() => backClick()}>Til baka</div>
                        </div>
                    </div>
                </div>
                <div className='gray-bg mb-70'>
                    <div className='container filter-container position-relative'>
                        <div className="row justify-content-center mb-5">
                            <div className='col-sm-12 col-11 d-flex flex-wrap'>
                                <div className="d-flex">
                                    <h1 className="mb-4">{selectedFestival.title}</h1>
                                    <div className="ml-2 mr-5 cursor-pointer" onClick={() => doEditFestival(selectedFestival)}>
                                        <div className="mt-4 pt-3 cursor-pointer d-flex align-items-center"><PencilIcon /></div>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center mb-lg-0 mb-4'>
                                    <input type="checkbox" id={'myEvent'} checked={showOwnEvents} onChange={() => setShowOwnCheck(!showOwnEvents)}></input>
                                    <label className="mb-0" htmlFor={'myEvent'}><h5 className='bold ml-3'>Sýna einungis mína viðburði</h5></label>
                                </div>
                                <div className='ml-0 ml-md-auto d-flex justify-content-end align-items-center'>
                                    <button className='secondary smaller thin mr-4 white-bg' onClick={() => toggle()}>Tengja viðburð</button>
                                    <button className='primary smaller' onClick={() => navigate('/events/new')}>Skrá viðburð</button>
                                </div>
                            </div>
                        </div>
                        <div className='row justify-content-center'>
                            <div className='col-md-3 col-11'>
                                {dayDropdown(selectedFestival.dates)}
                            </div>
                            <div className='col-md-3 col-11'>
                                {categoryDropdown()}
                            </div>
                            <div className='col-md-3 col-11'>
                                {typeDropdown()}
                            </div>
                            <div className='col-md-3 col-11'>
                                {postalCodeOptions && postalCodeDropdown()}
                            </div>
                        </div>
                        {Object.keys(filters).length > 0 && <div className="clear-filter position-absolute" onClick={() => clearFilters()}>Hreinsa síur </div>}
                    </div>
                </div>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-sm-12 col-11'>
                            {RenderDates()}
                        </div>
                    </div>
                </div>
                <Modal isShowing={isShowing} hide={toggle} customClass={"festival-details"}>
                    <ConnectEventsModal hide={toggle}></ConnectEventsModal>
                </Modal>
            </>
        }
    </>)

}

FestivalDetails.defaultProps = {}

import { api } from "../index"

export const eventsApi = api
    .enhanceEndpoints({
        // Events list and event detail use different serializers
        // So they contain different data and should be invalidated separately
        addTagTypes: ["Event", "EventDetail"],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getEvents: build.query({
                query: ({
                    group,
                    category,
                    date,
                    postal,
                    type,
                    owner,
                    search,
                    page = 1,
                    pageSize = 30,
                }) => ({
                    url: `events/`,
                    params: {
                        group,
                        category,
                        date,
                        postal,
                        type,
                        owner,
                        q: search,
                        limit: pageSize,
                        offset: (page - 1) * pageSize,
                    },
                }),
                providesTags: (result) =>
                    result
                        ? [
                              ...result.results.map(({ id }) => ({
                                  type: "Event",
                                  id,
                              })),
                              { type: "Event", id: "LIST" },
                          ]
                        : [{ type: "Event", id: "LIST" }],
            }),

            getEventDetail: build.query({
                query: ({ id, anyEvent = false }) => ({
                    url: `events/${id}/`,
                    params: {
                        any_event: anyEvent,
                    },
                }),
                providesTags: (result) => [{ type: "EventDetail", id: result.id }],
            }),

            copyEvent: build.mutation({
                query: ({ id }) => ({
                    url: `events/${id}/copy/`,
                    method: "POST",
                }),
                invalidatesTags: [{ type: "Event", id: "LIST" }],
            }),

            deleteEvent: build.mutation({
                query: ({ id }) => ({
                    url: `events/${id}/`,
                    method: "DELETE",
                }),
                invalidatesTags: (_result, error, { id }) =>
                    error
                        ? []
                        : [
                              { type: "Event", id: "LIST" },
                              { type: "Event", id },
                              { type: "EventDetail", id: id },
                          ],
            }),
        }),
    })

export const {
    useGetEventsQuery,
    useGetEventDetailQuery,
    useCopyEventMutation,
    useDeleteEventMutation,
} = eventsApi

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

import UserManager from "../../components/user-manager";
import NewEvent from "../../components/new-event";
import EventEditor from "../../components/event-editor";
import EventDetails from "../../components/event-details";
import NewFestival from "../../components/new-festival";
import FestivalDetails from "../../components/festival-details";
import FestivalEditor from "../../components/festival-editor";
import NewService from "../../components/new-service";
import ServiceEditor from "../../components/service-editor";
import Settings from "../../components/settings";


import { getEventCategories, getServiceCategories } from "../../slices/categories";
import { getEventTypes } from "../../slices/types";
import {  getUserFestivals  } from "../../slices/festivals";
import { getUser } from "../../slices/user";
import { getServices, getAllServices } from "../../slices/services";

import { BrowserRouter, Routes, Route } from "react-router-dom";


export default function Dashboard(props){
    const dispatch = useDispatch()
    const view = useSelector((state) => state.gui.currView)
    const [State, setState] = useState({Component: UserManager})

    useEffect(() => {
        dispatch(getEventCategories());
        dispatch(getServiceCategories());
        dispatch(getEventTypes());
        dispatch(getUserFestivals());
        dispatch(getServices({ 'owner': true, 'limit': 10}))
        dispatch(getAllServices())
        dispatch(getUser())
    })

    return (
      <BrowserRouter basename="/home">
        <Routes>
          <Route path="/" element={<UserManager {...props} />}></Route>
          <Route path="/events" element={<EventEditor {...props} />}></Route>
          <Route path="/events/new" element={<NewEvent {...props} />}></Route>
          <Route path="/events/:id" element={<EventDetails {...props} />}></Route>
          <Route path="/events/:id/edit" element={<NewEvent {...props} />}></Route>
          <Route path="/festivals" element={<FestivalEditor {...props} />}></Route>
          <Route path="/festivals/:id" element={<FestivalDetails {...props} />}></Route>
          <Route path="/festivals/:id/edit" element={<NewFestival {...props} />}></Route>
          <Route path="/festivals/new" element={<NewFestival {...props} />}></Route>
          <Route path="/services" element={<ServiceEditor {...props} />}></Route>
          <Route path="/services/:id/edit" element={<NewService {...props} />}></Route>
          <Route path="/services/new" element={<NewService {...props} />}></Route>
          <Route path="/settings" element={<Settings {...props} />}></Route>
        </Routes>
      </BrowserRouter>
    )

    // return (<State.Component {...props}/>)
}


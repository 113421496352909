import React from 'react';
import DateTime from 'react-datetime';
import moment from 'moment'
import translateLabel from 'react-rrule-generator/src/lib/utils/translateLabel'
import translations from '../../utils/translations';
import { CalendarIcon } from '../svg';

import { TIME_FORMAT, DATE_TIME_FORMAT, DATE_FORMAT } from '../../constants'

class HvirfillDatepicker extends React.Component {

    constructor(props) {
        super(props)
        this.handleChangeDaye = this.handleChangeDate.bind(this)

    }

    handleChangeDate(inputTime) {
        this.props.handleChangeDate(inputTime)
    }

    render() {
        let calendarAttributes = {
            'aria-label': translateLabel(translations, 'start.tooltip'),
        }
        return (

            <div className='date-picker-container'>
                <DateTime
                    value={ this.props.placeholder ? this.props.placeholder : ''}
                    readOnly={true}
                    dateFormat={DATE_FORMAT}
                    timeFormat={false}
                    locale="is"
                    inputProps={{readOnly:true}}
                    closeOnSelect
                    closeOnTab
                    required
                    onChange={(inputDate) => {
                        const editedEvent = {
                            value: moment(inputDate).format(DATE_FORMAT),
                            type: this.props.type
                        };
                        this.handleChangeDate(editedEvent);
                    }}
                />
                <CalendarIcon />
            </div>
  
        )
    }
}

export default HvirfillDatepicker
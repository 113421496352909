import { createSlice } from '@reduxjs/toolkit'

// Maybe should be part of events
const initialState = { 
    rrule: "",
    // knownPlace: '',

 }

const miscSlice = createSlice({
  name: 'misc',
  initialState,
  reducers: {
    setRRule(state, view){
        state.rrule = view.payload
    },
    clearRRule(state){
        state.rrule = ""
    },
    setKeyVal(state, action){
        state[action.payload.key] = action.payload.val
    },
    clearKeyVal(state, action){
        state[action.payload] = ""
    }
  },
})

export const { setRRule, clearRRule, setKeyVal, clearKeyVal } = miscSlice.actions
const { reducer } = miscSlice;
export default reducer;

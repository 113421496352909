import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../spinner'
import { getServices, editService, deleteService, editCopy, setSelectedTab, setShowOwn} from "../../slices/services";
import { BackArrowIcon, EllipsisVerticalIcon } from '../svg';
import ServiceMap from './map';
import { Tab, Tabs } from 'react-bootstrap';
import ContextMenu from '../context-menu';


export default function ServiceEditor(props) {
    const dispatch = useDispatch()
    const [openList, setOpenList] = useState('')
    const ownPostal = useSelector((state) => state.services.ownByPostalCode)
    const allPostal = useSelector((state) => state.services.allByPostalCode)
    const [currPostal, setCurrPostal] = useState(ownPostal)
    const ownPlaces = useSelector((state) => state.services.results)
    const allPlaces = useSelector((state) => state.services.allServices)
    const [currPlaces, setCurrPlaces] = useState(ownPlaces)
    const fetching = useSelector((state) => state.services.fetching)
    const selectedTab = useSelector((state) => state.services.selectedTab)
    const showOwn = useSelector((state) => state.services.showOwn)
    const [showContextMenu, setShowContextMenu] = useState(false)
    const [thinking, setThinking] = useState(false)
    const navigate = useNavigate()


    useEffect(() => {
        if (showOwn) {
            setCurrPostal(ownPostal)
            setCurrPlaces(ownPlaces)
        } else {
            setCurrPostal(allPostal)
            setCurrPlaces(allPlaces)
        }

    }, [showOwn, ownPostal, allPostal])

    function setCheck(value){
        dispatch(setShowOwn(value))
    }

    function toggleList(code) {
        if (code === openList) {
            setOpenList('')
        } else {
            setOpenList(code)
        }
    }

    function setTab(value){
        dispatch(setSelectedTab(value))
    }

    function doEdit(s) {
        // dispatch(editService(s))
        navigate(`/services/${s.id}/edit`)
    }

    function doCopy(s) {
        dispatch(editCopy(s))
        navigate('/services/new')
    }

    function doDelete(s) {
        if (window.confirm('Ertu viss um að þú viljir eyða þessum stað?')) {
            setThinking(true)
            dispatch(deleteService({ 'id': s.id })).then(() => {
                // dispatch(getExample())
                setThinking(false)
            })
        }
    }



    const Header = () => {
        return (<>
            <div className='gray-bg'>
                <div className='container filter-container'>
                    <div className="row mb-5">
                        <div className='col-xl-9 col-12 d-flex flex-wrap'>
                            <h1 className='mb-0'>Staðir</h1>
                            <div className="d-flex align-items-center my-4 my-lg-0 ml-5">
                                <input type="checkbox" value={1} id={1} checked={showOwn} onChange={() => setCheck(!showOwn)}></input>
                                <label htmlFor={1} className="mb-0"><h5 className="bold ml-3">Sýna einungis mína staði</h5></label>
                            </div>
                        </div>
                        <div className='col-xl-3 col-12'>
                            <button className="primary smaller float-right" onClick={() => navigate('/services/new') }>Skrá stað</button>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }

    const RenderMap = () => {
        return <div className="service-editor-map"><ServiceMap places={currPlaces}></ServiceMap></div>
    }

    const RenderList = () => {
        return (<>
            { currPostal && Object.entries(currPostal).map(([code, list]) => {
                return <>
                    <div className="container service-list mt-5 pt-3">
                        <div className="row justify-content-end">
                            <div onClick={() => { toggleList(code) }} className="postalcode w-100 d-flex">
                                <div className={`${openList == code && 'open'} close-row d-flex align-items-center justify-content-center`}>
                                    <span className="d-block"></span>
                                    <span className="d-block"></span>
                                </div>
                                <h4 className="bold" style={{ width: '40px' }}>{code}</h4>
                                <span className="ml-4" style={{ fontSize: '14px' }}>{list.length} </span><span className="ml-1" style={{ fontSize: '14px' }}>{list.length > 1 ? ' staðir' : ' staður'}</span>
                                <div className="line d-block col"></div>
                            </div>
                            <div className="w-100 col-xl-10 col-12">
                                {openList == code && list.map((place) => {
                                    return (
                                       <>
                                          <div
                                             className='d-flex service align-items-center row'
                                             key={place.id}
                                          >
                                             <div className='title bold pl-0 col-4'>
                                                {place.title}
                                             </div>
                                             <div className='address col-6'>{place.street}</div>
                                             <div className='status col-1'>
                                                <span
                                                   className={`d-block ${
                                                      place.active ? 'active' : ''
                                                   }`}
                                                ></span>
                                             </div>
                                             {/* <div className='settings col-1' onClick={() => setShowContextMenu(true)}><span className="d-block"></span><span className="d-block"></span><span className="d-block"></span></div> */}
                                             <div
                                                className='settings col-1 position-relative'
                                                onClick={() => setShowContextMenu(true)}
                                             >
                                                <EllipsisVerticalIcon className='w-12' />
                                                <ContextMenu
                                                   show={showContextMenu}
                                                   edit={() => doEdit(place)}
                                                   del={() => doDelete(place)}
                                                   copy={() => doCopy(place)}
                                                   close={() => setShowContextMenu(false)}
                                                   placeholders={{
                                                      edit: 'Breyta stað',
                                                      delete: 'Eyða stað',
                                                      copy: 'Afrita stað',
                                                   }}
                                                />
                                             </div>
                                          </div>
                                          {thinking && <Spinner></Spinner>}
                                       </>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </>

            })

            }
        </>)
    }

    return (<>
        <div className='container back-btn'>
            <div className="row justify-content-center">
                <div className='col-sm-12 col-11 d-flex align-items-center'>
                    <BackArrowIcon />
                    <Link to="/" className="ml-2 ">Til baka</Link>
                </div>
            </div>
        </div>
        <Header></Header>
        <div className='container tabs-container'>
            <div className='row'>
                <div className='col-12'>
                    {fetching ?
                        <div className='lower'>
                            <Spinner></Spinner>
                        </div>
                        :
                        <div className='container'>
                            <div className='row'>
                                <div className='col-12'>
                                    <Tabs
                                        defaultActiveKey={selectedTab}
                                        id="uncontrolled-tab"
                                        className="mb-3 position-relative border-0 flex-nowrap"
                                        onSelect={(key) => setTab(key)}>
                                            
                                        <Tab eventKey={'map'} title={'Kort'}>
                                            <RenderMap></RenderMap>
                                        </Tab>
                                        <Tab eventKey={'list'} title={'Listi'}>
                                            <div className='col-12 pt-3 postal-code-header' style={{ marginLeft: '20px' }}><p className='smaller bold'>Póstnúmer</p></div>
                                            <RenderList></RenderList>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    </>)

}

ServiceEditor.defaultProps = {}





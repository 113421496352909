
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ServiceService from "../../api/services";
import moment from 'moment'

function errorHandler(thunkAPI, error){
    console.log("Error: ", error)
    const message =
        (error.response &&
            error.response.data &&
            error.response.data.message) ||
        error.message ||
        error.toString();
    //thunkAPI.dispatch(setMessage(message));
    return thunkAPI.rejectWithValue();
}


export const getService = createAsyncThunk(
	"service/get",
	async (args, thunkAPI) => {
		try {
			const response = await ServiceService.getService(args);
			//thunkAPI.dispatch(setMessage(response.data.message));
			return response;
		} catch (error) {
			return errorHandler(thunkAPI, error)
		}
	}
);


export const getServices = createAsyncThunk(
	"services/get",
	async (args, thunkAPI) => {
		try {
			const response = await ServiceService.getServices(args);
			//thunkAPI.dispatch(setMessage(response.data.message));
			return response;
		} catch (error) {
			return errorHandler(thunkAPI, error)
		}
	}
);


export const getAllServices = createAsyncThunk(
	"services/getAll",
	async (args, thunkAPI) => {
		try {
			const response = await ServiceService.getAllServices(args);
			//thunkAPI.dispatch(setMessage(response.data.message));
			return response;
		} catch (error) {
			return errorHandler(thunkAPI, error)
		}
	}
);

export const createService = createAsyncThunk(
	"service/create",
	async (args, thunkAPI) => {
		try {
			const response = await ServiceService.createService(args);
			//thunkAPI.dispatch(setMessage(response.data.message));
			return response;
		} catch (error) {
			return errorHandler(thunkAPI, error)
		}
	}
);

export const updateService = createAsyncThunk(
	"service/update",
	async (args, thunkAPI) => {
		try {
			const response = await ServiceService.updateService(args);
			//thunkAPI.dispatch(setMessage(response.data.message));
			return response;
		} catch (error) {
			return errorHandler(thunkAPI, error)
		}
	}
);

export const deleteService = createAsyncThunk(
	"service/delete",
	async (args, thunkAPI) => {
		try {
			const response = await ServiceService.deleteService(args);
			//thunkAPI.dispatch(setMessage(response.data.message));
			return response;
		} catch (error) {
			return errorHandler(thunkAPI, error)
		}
	}
);


const initialState = {
	fetching: false,
    editing: false,
    cpEdit: false,
	results: [],
	allServices: [],
	allByPostalCode: [],
	ownByPostalCode: [],
    selectedService: '',
	selectedTab: 'map',
	showOwn: true
};

function getServicesByPostalCode(data) {
	let places = {}
	{ data.map((place) => {
		if (place.postal in places){
			places[place.postal].push(place)
		} else {
			places[place.postal] = [place]
		}
	})
	}
	return places
}

const serviceSlice = createSlice({
	name: "services",
	initialState,
    reducers: {
        editService(state, action){
            state.editing = true
            state.selectedService = action.payload
        },
        cancelEditService(state){
            state.editing = false
            state.selectedService = ''
        },
        editCopy(state, action){
            state.cpEdit = true
            state.selectedService = action.payload
        },
        cancelEditCopy(state){
            state.cpEdit = false
            state.selectedService = ''
        },
        setSelectedTab(state, data){
            state.selectedTab = data.payload
        },
        setShowOwn(state, data){
            state.showOwn = data.payload
        }
    },
	extraReducers: {
		[getService.pending]: (state, action) => { 
			state.fetching = true
		},
		[getService.fulfilled]: (state, action) => {
			state.fetching = false
			state.selectedService = action.payload;
			state.editing = true
		},
		[getServices.pending]: (state, action) => { 
			state.fetching = true
		},
		[getServices.fulfilled]: (state, action) => {
			state.fetching = false
			state.results = action.payload.results.services;
			state.ownByPostalCode = getServicesByPostalCode(action.payload.results.services)
		},
		[getServices.rejected]: (state, action) => {
			state.error = "Ekki tókst að sækja viðburði";
		},
		[getAllServices.pending]: (state, action) => { 
			state.fetching = true
		},
		[getAllServices.fulfilled]: (state, action) => {
			state.fetching = false
			state.allServices = action.payload.results.services;
			state.allByPostalCode = getServicesByPostalCode(action.payload.results.services)
		},
		[getAllServices.rejected]: (state, action) => {
			state.error = "Ekki tókst að sækja viðburði";
		},
		[createService.pending]: (state, action) => { 
			state.fetching = true
		},
		[createService.fulfilled]: (state, action) => {
			state.fetching = false
			state.results = action.payload.results.services;
		},
		[createService.rejected]: (state, action) => {
			state.error = "Ekki tókst að búa til viðburð";
		},
		// [deleteService.pending]: (state, action) => { 
		// 	state.fetching = true
		// },
		[deleteService.fulfilled]: (state, action) => {
			state.results = action.payload.results.services;
            state.allByPostalCode = getServicesByPostalCode(action.payload.results.services)
		},
		[deleteService.rejected]: (state, action) => {
			state.error = "Ekki tókst að eyða stað";
		},
	},
});

export const { editService, cancelEditService, editCopy, cancelEditCopy, setSelectedTab, setShowOwn } = serviceSlice.actions
const { reducer } = serviceSlice;
export default reducer;
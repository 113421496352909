import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AddressService from "../../api/addressSearch";

export const fetchAddressSearch = createAsyncThunk(
    "address/search",
    async (args, thunkAPI) => {
      try {
        const response = await AddressService.fetchAddressSearch(args);
        //thunkAPI.dispatch(setMessage(response.data.message));
        return response;
      } catch (error) {
          return errorHandler(thunkAPI, error)
      }
    }
);


const initialState = {
    isFetching: false,
    results: [],
    searchItems: [],
    selectedItem: ''
};


const addressSlice = createSlice({
    name: "address",
    initialState,
    reducers: {
        addSelected(state, data){
            state.selectedItem = data.payload;
        },
        removeSelected(state){
            state.selectedItem = ''
        },
        clearAddressList(state){
            state.searchItems = []
        },
    },
    extraReducers: {
        [fetchAddressSearch.fulfilled]: (state, action) => {
            state.results = action.payload
            state.searchItems = state.results.slice(0,10).map(address => {
                return address.search_string
            })
        },
        [fetchAddressSearch.rejected]: (state, action) => {
            state.error = "Ekki tókst að sækja heimilisföng";
        },
    },
});
  
  export const { addSelected, removeSelected, clearAddressList } = addressSlice.actions
  const { reducer } = addressSlice;
  export default reducer;
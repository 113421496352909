import React from 'react';
import { Provider } from 'react-redux';
import { toast, Toaster, ToastBar } from 'react-hot-toast';

import { store } from '../../store';
import Dashboard from './dashboard';
import ErrorBoundary from '../../components/error-boundary';

export default function Wrapper(props) {
   return (
      <ErrorBoundary>
         <Provider store={store}>
            <Toaster
               position='top-center'
               toastOptions={{
                  className: '',
                  style: {
                     border: '1px solid #c7cbd0',
                     padding: '20px',
                  },
               }}


            >
               {(t) => (
                  <ToastBar toast={t}>
                     {({ icon, message }) => (
                        <>
                           <div className="container">
                              <div className="row mt-5">
                                 <div className="col-3 p-5">
                                    {icon}
                                 </div>
                                 <div className="col-9">
                                    {message}
                                 </div>
                              </div>
                              <div className="row mt-5">
                                 <div className="col-12 text-center">

                                    {t.type !== 'loading' && (
                                       <button className="secondary" onClick={() => toast.dismiss(t.id)}>Loka</button>
                                    )}
                                 </div>
                              </div>
                           </div>
                        </>
                     )}
                  </ToastBar>
               )}
            </Toaster>
            <Dashboard {...props}></Dashboard>
         </Provider>
      </ErrorBoundary>
   );
}

// import React from 'react';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'


export default function ServiceOverview(props) {
    let { title } = props
	const dispatch = useDispatch()
    const services = useSelector((state) => state.services.results)
    const [showItems, setShowItems] = useState([])

    const navigate = useNavigate()


    const RenderServices = ({service}) => {
        return (<>
            <div onClick={() => navigate(`/services/${service.id}/edit`)} className="col-lg-3 col-md-4 col-11 mb-5">
                <div className="content-container h-100">
                    <div className="text-container d-flex flex-column">
                        <h4 className='title position-relative bold'>{service.title}</h4>
                        { service.active &&
                            <div className='status'><span className={`d-block active`}></span></div>
                        }
                    </div>  
                </div>
            </div>
        </>
        )
    }

    // Slice 8 first
    useEffect(() => {
        let tmp = []
        if(services){
            tmp = services.length > 8 ? services.slice(0, 8) : services
            tmp = tmp.slice().sort((a, b) => a.title.localeCompare(b.title))
        }
        setShowItems(tmp)

    },[services])

	return (<>
        <div className="container pt-120 pb-80 service-overview">
            <div className='row justify-content-center justify-content-sm-center'>
                <div className='col-sm-12 col-11 d-flex'>
                    <div>
                        <h1>{title}</h1>
                    </div>
                    <div className="ml-auto">
                        <button className="primary smaller" onClick={() => navigate('/services/new')}>Nýr staður</button>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center justify-content-md-start service-header">
                { showItems && showItems.map((serv, index) => {
                    return <RenderServices service={serv}></RenderServices>
                })}
            </div>
            <div className="d-flex justify-content-center">
                <button className="secondary thin"><Link to="/services/">Sjá alla staði</Link></button>
            </div>
        </div>
    </>)

}
import React, { useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getEvents, getFestivals } from '../../slices/guest'
import { getUser } from '../../slices/user'
import Landing from './landing'
import FestivalLanding from './festival-view'
import FPEventDetails from './event-view'

export default function Frontpage(props) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getEvents())
    dispatch(getFestivals())
    dispatch(getUser())
  })

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing {...props} />}></Route>
        <Route path="/events/:id" element={<FPEventDetails {...props} />}></Route>
        <Route path="/festivals/:id" element={<FestivalLanding {...props} />}></Route>
      </Routes>
    </BrowserRouter>
  )
}


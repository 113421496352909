import axios from "axios";
import {getCookie} from "../../utils/index";
import moment from 'moment';


const API_URL = "/api/v1/user/";

// Get info about seld
const getUser = (args) => {
    return axios
        .get(API_URL)
        .then((response) => {
            return response.data;
        });
};

// Get info about seld
const resetUserPass = (args) => {
    let url = '/reset_user_pass/'
    return axios
        .post(url, args, {
            headers: {
                'X-CSRFToken': getCookie("csrftoken"),
            }
        })
        .then((response) => {
            return response.data;
        });
};

// .patch(url, 
//     JSON.stringify({'eventIds': eventIds}), 
//     { headers: headers})


const UserService = {
    getUser,
    resetUserPass
};

export default UserService;

import React, { useEffect, useState, useRef } from 'react'
import { connect, Provider, useDispatch, useSelector } from 'react-redux'
import { useParams, Link } from 'react-router-dom'
// import { getEvents } from '../../slices/events'
import { getFestival } from '../../slices/festivals'
import { getFestivalById } from '../../slices/guest'
import EventOverview from '../../components/event-overview'
import { BackArrowIcon } from '../../components/svg'
import FestivalOverview from '../../components/festival-overview'
import { getUser } from '../../slices/user'

// For unauthenticated users only
export default function FestivalLanding(props) {
    const festivals = useSelector((state) => state.guest.festivals)
    // const selectedFestival = useSelector((state) => state.festivals.selectedFestival)
    const selectedFestival = useSelector((state) => state.guest.selectedFestival)
    const dispatch = useDispatch()
    const view = useSelector((state) => state.gui.currView)
    const user = useSelector((state) => state.user.info)

    const params = useParams()

    useEffect(() => {
        dispatch(getUser())
        dispatch(getFestivalById({'id': params.id }))
        dispatch(getFestival(params.id))
    }, [])

    return (<>
        <div className='container back-btn'>
            <div className="row justify-content-center">
                <div className='col-sm-12 col-11 d-flex align-items-center'>
                    <BackArrowIcon />
                    <a href={user && user.permissions && user.permissions.festival_admin ? '/home/festivals' : '/'} className="ml-2 ">Til baka</a>
                </div>
            </div>
        </div>
        <div className='container festival-view mt-50'>
            <div className='row justify-content-center'>
                <div className='col-sm-12 col-11'>
                    <h1>{selectedFestival.title}</h1>
                </div>
            </div>
            <div className='row justify-content-center'>
                <div className='col-md-6 col-sm-12 col-11 mb-5'>
                    <div className="text" dangerouslySetInnerHTML={{ __html: selectedFestival.description }}></div>
                    <div className="mt-5">
                        <a className="link primary" href={selectedFestival.website} target="_blank">Vefur hátíðarinnar</a><br />
                    </div>
                </div>
                <div className='col-md-6 col-sm-12 col-11'>
                    {selectedFestival.festival_image &&
                        <img src={selectedFestival.festival_image} className="w-100 mb-5" />
                    }
                </div>
            </div>
        </div>
        {selectedFestival.events && selectedFestival.events.length > 0 &&
            <EventOverview renderType={'festival'} title={'Viðburðir'} events={selectedFestival.events}></EventOverview>
        }
    </>
    )
}
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, useFieldArray } from "react-hook-form";
import TagAutocomplete from '../tag-autocomplete/index';
import { updateEvent, createEvent, removeEditEvent, removeEditCopy, editEvent, getEvent } from "../../slices/events";
import moment from 'moment'
import Select from 'react-select';
import ImgCropper from "../img-cropper";
import HvirfillDatePicker from "./dates";
import HvirfillTimePicker from "./times";
import RepeatEvent from "./repeat-events";
import { removeDuplicates } from '../../utils'
import { RRule } from 'rrule'
import { Tab, Tabs } from 'react-bootstrap';
import BasicMap from "../map";
import AddressSearch from '../address-autocomplete/index';
import { EVENT_ERRORS, DATE_FORMAT, TIME_FORMAT, API_DATE_TIME_FORMAT } from '../../constants'
import { addSelected, removeSelected } from '../../slices/address'
import Spinner from "../spinner";
import toast from 'react-hot-toast';

moment.locale('is')

const makeDate = (startDate, endDate, startTime, endTime) => {
    let start = moment(startDate).clone()
    startTime = moment(startTime)
    endTime = moment(endTime)
    start = start.set({
        hour: startTime.get('hour'),
        minute: startTime.get('minute'),
        second: 0,
        millisecond: 0,
    });
    let end = moment(endDate).clone()
    end = end.set({
        hour: endTime.get('hour'),
        minute: endTime.get('minute'),
        second: 0,
        millisecond: 0,
    });
    return {
        start: start,
        end: end,
        key: `${start.format('X')}-${end.format('X')}`
    }
}

// Used for new, edit and copy events
// TODO: Rewrite to use RTK Query
export default function NewEvent(props) {
    const { register, setValue, getValues, handleSubmit, control, reset, clearErrors, formState: { errors } } = useForm();

    const { replace } = useFieldArray({
        control,
        name: 'evDates',
        rules: {
            required: true,
            minLength: 1
        }
    });
    const categories = useSelector((state) => state.categories.events)
    const types = useSelector((state) => state.types.events)
    const editing = useSelector((state) => state.events.editing)
    const editingCopy = useSelector((state) => state.events.cpEdit)
    const editedEvent = useSelector((state) => state.events.event)
    const places = useSelector((state) => state.services.allServices)
    const rrule = useSelector((state) => state.misc.rrule)
    const address = useSelector((state) => state.addressSearch.selectedItem)
    const festivals_future = useSelector((state) => state.festivals.byDate.future)
    const festivals_now = useSelector((state) => state.festivals.byDate.now)
    const [selectedCategories, setSelectedCategories] = useState([])
    const [selectedFestivals, setSelectedFestivals] = useState([])
    const [selectedTypes, setSelectedTypes] = useState([])
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [startTime, setStartTime] = useState(null)
    const [endTime, setEndTime] = useState(null)
    const [eventDates, setEventDates] = useState([])
    const [publish, setPublish] = useState(false)
    const [selectedTags, setSelectedTags] = useState([])
    const [eventImageSrc, setEventImageSrc] = useState('')
    const [knownPlace, setKnownPlace] = useState(0)
    const [wheelchairAccess, setWheelchairAccess] = useState(false)
    const [saving, setSaving] = useState(false)
    const dispatch = useDispatch()
    const params = useParams()
    const navigate = useNavigate()
    const events = useSelector(state => state.events)
    const [festivals, setFestivals] = useState('')


    useEffect(() => {
        if (params.id) {
            const event = events.allEvents.find(f => +f.id === +params.id)

            if (event) {
                dispatch(editEvent(event))
            } else {
                dispatch(getEvent({ id: +params.id, any_event: true }))
            }
        }

        return () => {
            dispatch(removeEditCopy())
            dispatch(removeEditEvent())
        }
    }, [])

    useEffect(() => {
        if(festivals_future && festivals_now){
            setFestivals(festivals_future.concat(festivals_now))
        }
    }, [festivals_future, festivals_now])

    // Helper function
    const getEventErrorMsg = (key) => { return EVENT_ERRORS[key] }

    const onSubmit = (data) => {
        setSaving(true)

        let addr = data.address
        data['formatted_address'] = addr.address,
            data['location'] = addr.lat + " " + addr.lng,
            data['postal'] = addr.postal,
            data['street'] = addr.street,
            data['city'] = addr.city,
            data['occurrences'] = data.evDates.map(item => { return { start_time: item.start.format(API_DATE_TIME_FORMAT), end_time: item.end.format(API_DATE_TIME_FORMAT) } }),
            data['tags'] = selectedTags.concat(selectedCategories, selectedTypes),
            data['linked_service'] = knownPlace ? knownPlace.value : '',
        data['active'] = publish
        data['festivals'] = selectedFestivals
        data['wheelchair_access'] = wheelchairAccess

        // Do not attach image file if editing and not adding
        if (data.img && data.img['0'].base64) {
            let image = data.img['0']
            data['image'] = { ...image, file: image.base64 }
        }

        function getFullPath(obj) {
            let tmp = obj
            if (!obj.includes('//')) {
                tmp = 'http://' + obj
            }
            return tmp
        }

        // Ignore if no input
        data['website'] = data['website'] ? getFullPath(data['website']) : ''
        data['tickets'] = data['tickets'] ? getFullPath(data['tickets']) : ''
        data['facebook'] = data['facebook'] ? getFullPath(data['facebook']) : ''


        // Clear address
        dispatch(removeSelected())

        if (editing) {
            dispatch(updateEvent({ id: editedEvent.id, data: data })).then(() => {
                reset()
                navigate('/events/' + editedEvent.id)
            })
        } else { // new and copy
            dispatch(createEvent(data)).then((returned) => {
                toast.success('Viðburður hefur verið skráður. Birting á vef gæti tekið allt að tveimur tímum', { duration: Infinity })
                reset()
                navigate('/events/' + returned.payload.id)
            })
        }
    };


    useEffect(() => {
        slideLabelsUp();
    }, [])

    // Reset form if editing
    useEffect(() => {
        if ((editing || editingCopy)) {
            const addrObj = {
                address: editedEvent.formatted_address,
                city: editedEvent.city,
                lat: editedEvent.location[0],
                lng: editedEvent.location[1],
                postal: editedEvent.postal,
                street: editedEvent.street
            }

            dispatch(addSelected(addrObj))

            let t = editedEvent.language.is.title
            let t_en = editedEvent.language.en.title

            if (editingCopy) {
                t += ' afrit'
                t_en += ' copy'
            }

            reset({
                title: t,
                title_en: t_en,
                description: editedEvent.language.is.text,
                description_en: editedEvent.language.en.text,
                detailed_description: editedEvent.language.is.details,
                detailed_description_en: editedEvent.language.en.details,
                active: editedEvent.active,
                tickets: editedEvent.media.tickets,
                website: editedEvent.media.website,
                facebook: editedEvent.media.facebook,
                img: editedEvent.event_image,
                address: addrObj,
                place: editedEvent.language.is.place,
                place_en: editedEvent.language.en.place,
            })

            setPublish(editedEvent.active)
            setEventImageSrc(editedEvent.event_image)
            setSelectedFestivals(editedEvent.festivals)
            setWheelchairAccess(editedEvent.wheelchair_access)


            let tmpCategories = []
            let tmpTypes = []
            let tmpOther = []

            // Sort event-tags into categories, types and tags
            editedEvent.tags.map((t) => {
                if (categories.find(e => e.tag == t)) {
                    tmpCategories.push(t)
                }
                else if (types.find(e => e.tag == t)) {
                    tmpTypes.push(t)
                } else {
                    tmpOther.push(t)
                }
            })

            setSelectedCategories(tmpCategories)
            setSelectedTypes(tmpTypes)
            setSelectedTags(tmpOther)

            // Make date into correct format
            let tmpDates = editedEvent.dates.map((date, index) => {
                let st = moment(date.start)
                let en = moment(date.end)
                return {
                    start: st,
                    end: en,
                    key: `${st.format('X')}-${en.format('X')}`
                }
            })
            setEventDates(tmpDates)

            if (editedEvent.linked_service) {
                places.map((item) => {
                    if (item.id == editedEvent.linked_service) {
                        setKnownPlace({ value: item.id, label: item.title })
                    }
                })
            }

            // Add class to labels when editing
            Object.keys(getValues()).map((key) => {
                $('label[for=' + key + ']').addClass("animate-label")
            })
        }
    }, [editing])

    // If editing, when places are loaded
    // Set known place if exists
    useEffect(() => {
        if (!editing || !places.length || !editedEvent.linked_service) return;

        const place = places.find((item) => {
            return item.id == editedEvent.linked_service
        }
        )

        if (place) {
            setKnownPlace({ value: place.id, label: place.title, wa: place.wheelchair_access })
        }
    }, [editing, places, editedEvent.linked_service])

    // useFieldArray for form validation
    useEffect(() => {
        replace(eventDates)
        if (eventDates.length > 0) { clearErrors('evDates') }
    }, [eventDates])

    // Overwrite only if no value pre-excisting
    useEffect(() => {
        if (knownPlace) {
            if (!getValues('place')) {
                setValClearErr('place', knownPlace.label)
                $('label[for=place]').addClass("animate-label")
            }
            if (!getValues('place_en')) {
                setValClearErr('place_en', knownPlace.label)
                $('label[for=place_en]').addClass("animate-label")
            }
        }
        setWheelchairAccess(knownPlace ? knownPlace.wa : false)
    }, [knownPlace])


    function updateTags(tags) {
        let tmp = tags.map((item) => {
            return item.name
        })
        setSelectedTags(tmp)
    }

    function handleDateChange(e) {
        if (e.target.name === 'startDate') {
            setStartDate(moment(e.target.value, DATE_FORMAT))
        } else {
            setEndDate(moment(e.target.value, DATE_FORMAT))
        }
    }

    function singleDateButtonValid() {
        return startDate && endDate && startTime && endTime
    }

    function addSingleDate() {
        let dates = [...eventDates]
        let newDate = makeDate(startDate, endDate, startTime, endTime)
        dates.push(newDate)
        filterAndSetDates(dates)
    }

    function addMultipleDates() {
        var dates = [...eventDates]
        const rule = RRule.fromString(rrule)
        let newDates = rule.all().map((date) => {
            return makeDate(date, date, startTime, endTime)
        })
        dates = dates.concat(newDates)
        filterAndSetDates(dates)
    }

    function filterAndSetDates(dates) {
        dates = removeDuplicates(dates, 'key')
        dates = dates.sort((a, b) => a.start.valueOf() - b.start.valueOf())
        setEventDates(dates)
    }

    function removeDate(key) {
        let dates = [...eventDates]
        dates = dates.filter(item => item.key !== key)
        filterAndSetDates(dates)
    }


    function handleTimeChange(e) {
        if (e.target.name === 'startTime') {
            setStartTime(moment(e.target.value, TIME_FORMAT))
        } else {
            setEndTime(moment(e.target.value, TIME_FORMAT))
        }
    }

    // DRY
    function checkboxArrayHelper(arr, item) {
        if (arr.includes(item)) {
            let index = arr.indexOf(item)
            arr.splice(index, 1)
        } else {
            arr.push(item)
        }
    }

    function setValClearErr(name, value) {
        setValue(name, value)
        clearErrors(name)
    }

    function handleCategoryChange(id) {
        let updatedCategories = [...selectedCategories]
        checkboxArrayHelper(updatedCategories, id)
        setSelectedCategories(updatedCategories)
    }

    function handleFestivalChange(value) {
        let id = parseInt(value)
        let updatedFestivals = [...selectedFestivals]
        if (updatedFestivals.filter(item => item.id === id).length > 0) {
            updatedFestivals = updatedFestivals.filter(item => item.id !== id)
        } else {
            updatedFestivals.push({ id: id })
        }
        setSelectedFestivals(updatedFestivals)
    }

    function handleTypeChange(id) {
        let updatedTypes = [...selectedTypes]
        checkboxArrayHelper(updatedTypes, id)
        setSelectedTypes(updatedTypes)
    }

    // react-hook-form template
    const getInputField = (name, required, id = "", classname = "", pattern = {}) => {
        return (<>
            <input id={name} className={classname} {...register(name, { required: required, pattern: pattern, })} />
            {errors[name] ? <div className="error-msg text-left">{getEventErrorMsg(name)}</div> : null}
        </>)
    }

    // React-hook-form template
    const getTextArea = (name, required = false, id = "", classname = "", pattern = {}) => {
        return (
            <>
                <textarea id={name} className={classname}
                    {...register(name, { required: required, pattern: pattern })}></textarea>
                {errors[name] ? <div className="error-msg text-left">{getEventErrorMsg(name)}</div> : null}
            </>
        )
    }

    function getAnnad(someArr, selectedArr, callback) {
        let annad = someArr.find(e => {
            if (e.name == 'Annað') {
                return e
            }
            return false
        })
        return (
            <>
                {annad &&
                    <div className="category mb-4 col-6 d-flex align-items-center">
                        <input className="mr-3" type="checkbox" value={annad.tag} id={annad.tag} checked={selectedArr.includes(annad.tag)} onChange={(e) => callback(e.target.value)}></input>
                        <label className="mb-0" htmlFor={annad.tag}><h5 className="mb-0">{annad.name}</h5></label>
                    </div>
                }
            </>
        )
    }

    const Categories = () => {
        return (<>
            <div className="row mb-70 justify-content-center" id="categories">
                <div className="col-md-6 col-11 mb-50">
                    <h4 className="bold mb-4" id="headingTwo">Flokkur</h4>
                    <div className="container gray-bg py-5 h-100">
                        <div className="row">
                            <div className="col-12 mb-4"><p className="smaller border-bottom pb-4">Með flokk er átt við um hvers konar viðburð er að ræða. Notandi getur leitað eftir flokkum. Hægt er að velja meira en einn flokk.</p></div>
                            {categories && categories.map((cat) => {
                                if (cat.name !== 'Annað') {
                                    return (
                                        <div className="category mb-4 col-6 d-flex align-items-center">
                                            <input className="mr-3" type="checkbox" value={cat.tag} id={cat.tag} checked={selectedCategories.includes(cat.tag)} onChange={(e) => handleCategoryChange(e.target.value)}></input>
                                            <label className="mb-0" htmlFor={cat.tag}><h5 className="mb-0">{cat.name}</h5></label>
                                        </div>
                                    )
                                }
                            }
                            )}
                            {getAnnad(categories, selectedCategories, handleCategoryChange)}
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-11 mb-50">
                    <h4 className="bold mb-4" id="headingTwo">Tegund</h4>
                    <div className="container gray-bg py-5 h-100">
                        <div className="row">
                            <div className="col-12 mb-4"><p className="smaller border-bottom pb-4">Með tegund er átt við mikilvægar viðbótarupplýsingar við flokk viðburða. Notandi getur leitað eftir flokkum. Hægt er að velja meira en einn flokk.</p></div>
                            {types && types.map((type) => {
                                if (type.name !== 'Annað') {
                                    return (
                                        <div className="type mb-4 col-6 d-flex align-items-center">
                                            <input className="mr-3" type="checkbox" value={type.tag} id={type.tag} checked={selectedTypes.includes(type.tag)} onChange={(e) => handleTypeChange(e.target.value)}></input>
                                            <label className="mb-0" htmlFor={type.tag}><h5 className="mb-0">{type.name}</h5></label>
                                        </div>
                                    )
                                }
                            }
                            )}
                            {getAnnad(types, selectedTypes, handleTypeChange)}
                        </div>
                    </div>
                </div>
            </div>
        </>)

    }

    const Festivals = () => {
        return (<>
            <div className="col-md-6 col-11 mb-50">
                <h4 className="bold mb-5" id="headingTwo">Tengja við hátíð</h4>
                <div className="container gray-bg py-5 h-100">
                    <div className="row">
                        <div className="col-12 mb-4"><p className="smaller border-bottom pb-4">Hægt er að tengja viðburð við hátíð. Viðburðurinn birtist undir dagskrá hátíðarinnar.</p></div>
                        {festivals && festivals.map((festival) =>
                            <div className="festival mb-4 col-6 d-flex align-items-center">
                                <input className="mr-3" type="checkbox" value={festival.id} id={festival.id} checked={selectedFestivals.find(item => item.id === festival.id)} onChange={(e) => handleFestivalChange(e.target.value)}></input>
                                <label className="mb-0" htmlFor={festival.id}><h5 className="mb-0">{festival.title}</h5></label>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>)

    }

    function Location() {
        return (
           <>
              <div className='row justify-content-center mb-5'>
                 <div className='col-md-12 col-11'>
                    <h4 className='bold'>Staðsetning</h4>
                 </div>
              </div>
              <div className='row justify-content-center mb-50'>
                 <div className='col-md-6 col-11 location-container'>
                    <div className='react-select-container'>
                       <Select
                          className='mb-5'
                          placeholder='Tengja við skráðan stað (valkvætt)'
                          name='knownPlace'
                          classNamePrefix='react-select'
                          isClearable
                          options={places.map((item) => {
                             return {
                                value: item.id,
                                label: item.title,
                                wa: item.wheelchair_access,
                             };
                          })}
                          value={knownPlace}
                          onChange={(item) => {
                             setKnownPlace(item);
                          }}
                       ></Select>
                    </div>
                    <div className='input-container'>
                       <label htmlFor='place'>Heiti staðar</label>
                       {getInputField('place', true)}
                    </div>
                    <div className='input-container'>
                       <label htmlFor='place_en'>Heiti staðar á ensku</label>
                       {getInputField('place_en', true)}
                    </div>
                    <AddressSearch
                       handleChange={(val) => {
                          setValClearErr('address', val);
                       }}
                       {...register('address', { required: true })}
                    />
                    <div className='toggle-outer-container d-flex align-items-center'>
                       <label htmlFor='wheelchair_access' className="mr-5">Hjólastólaaðgengi</label>
                       <div className='toggle-container'>
                          <input
                             className='toggle'
                             type='checkbox'
                             value={wheelchairAccess}
                             id='wheelchair_access'
                             checked={wheelchairAccess}
                             onChange={(e) => setWheelchairAccess(e.target.checked)}
                             disabled={!!knownPlace}
                          />
                       </div>
                    </div>
                    {errors['address'] ? (
                       <div className='error-msg text-left'>{getEventErrorMsg('address')}</div>
                    ) : null}
                 </div>
                 <div className='col-md-6 col-12 location-container'>
                    <div style={{ minHeight: '314px' }}>
                       <BasicMap
                          marker={address}
                          mapStyles={{ width: '100%', height: '100%' }}
                       ></BasicMap>
                    </div>
                 </div>
              </div>
           </>
        );
    }

    function Links() {
        return (
            <>
                <div className="links-container row mb-50 justify-content-center">
                    <div className="col-md-12 col-11">
                        <h4 className="bold">Hlekkir (valkvætt)</h4>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6 col-11">
                        <div className="input-container">
                            <label htmlFor="tickets">Miðakaup hlekkur</label>
                            {getInputField('tickets', false)}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-11">
                        <div className="input-container">
                            <label htmlFor="website">Vefsíða viðburðar</label>
                            {getInputField('website', false)}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-11">
                        <div className="input-container">
                            <label htmlFor="facebook">Hlekkur á facebook</label>
                            {getInputField('facebook', false)}
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <div className="new-event-container">
                {/* <div className={this.props.newEventVisible ? "new-event" : "new-event d-none d-lg-block"}> */}
                <div className={"new-event container"}>
                    <div className="row justify-content-center header-row">
                        <div className="col-md-12 col-11 d-flex justify-content-between align-items-center">
                            {editing ? <h2 className="bold">Breyta viðburði</h2> : <h2 className="bold">Nýr viðburður</h2>}
                            <button className="close-button secondary" onClick={() => history.back()}>Hætta við</button>
                            {/* <button onClick={() => toast.success('Viðburður hefur verið skráður. Birting á vef gæti tekið allt að tveimur tímum', { duration: Infinity })}>Test</button> */}
                        </div>
                    </div>
                </div>
                <form className="container" onSubmit={handleSubmit(onSubmit)}>
                    <div className="row mb-50 justify-content-center">
                        {/* islenska */}
                        <div className="col-md-6 col-11">
                            <h4 className="bold mb-4">Íslenska</h4>
                            <div className="input-container">
                                <label htmlFor="title">Heiti viðburðar</label>
                                {getInputField('title', true, 'title', 'is-title')}
                            </div>
                            <div className="input-container">
                                <label htmlFor="description">Lýsing á viðburði</label>
                                {getTextArea('description', true)}
                            </div>
                        </div>
                        {/* enska */}
                        <div className="col-md-6 col-11">
                            <h4 className="bold mb-4">Enska</h4>
                            <div className="input-container">
                                <label htmlFor="title_en">Heiti viðburðar á ensku</label>
                                {getInputField('title_en', true)}
                            </div>
                            <div className="input-container">
                                <label htmlFor="description_en">Stutt lýsing á viðburði á ensku</label>
                                {getTextArea('description_en', true)}
                            </div>
                        </div>

                        <div className="col-md-6 col-11">
                            <div className="input-container">
                                <label htmlFor="detailed_description">Nánari lýsing á viðburði <span style={{ fontSize: '13px' }}>(valkvætt)</span></label>
                                {getTextArea('detailed_description', false, 'detailedDescription')}
                            </div>
                        </div>
                        <div className="col-md-6 col-11">
                            <div className="input-container">
                                <label htmlFor="detailed_description_en">Nánari lýsing á viðburði á ensku <span style={{ fontSize: '13px' }}>(valkvætt)</span></label>
                                {getTextArea('detailed_description_en', false, 'enDetailedDescription')}
                            </div>
                        </div>
                    </div>
                    <div className="date-container row justify-content-center mb-50">
                        <div className="col-md-6 col-11">
                            <h4 className="bold mb-4" htmlFor="datetimes">Dagsetning og tími</h4>
                            <div className="alert alert-info">
                                Notaðu formið hér að neðan til að skrá dagsetningar fyrir viðburðinn.
                            </div>
                            <div className="tabs-container" id="datetimes">
                                <Tabs defaultActiveKey="single_occurrence" id="datetime-tabs" className="position-relative border-0 flex-nowrap">
                                    <Tab eventKey="single_occurrence" title="Stakur viðburður">
                                        <HvirfillDatePicker startDate={startDate} endDate={endDate} onChange={handleDateChange}></HvirfillDatePicker>
                                        <HvirfillTimePicker onChange={handleTimeChange} startTime={startTime} endTime={endTime}></HvirfillTimePicker>
                                        {singleDateButtonValid() &&
                                            <input type='button' value="Skrá dagsetningu" className="mt-3 mb-5 add-date-button border-0 w-100 primary-button" onClick={addSingleDate} />
                                        }
                                    </Tab>
                                    <Tab eventKey="multi_occurrence" title="Endurtekinn viðburður">
                                        <HvirfillDatePicker startDate={startDate} endDate={endDate} onChange={handleDateChange}></HvirfillDatePicker>
                                        <HvirfillTimePicker onChange={handleTimeChange} startTime={startTime} endTime={endTime}></HvirfillTimePicker>
                                        <RepeatEvent startDate={startDate} endDate={endDate}></RepeatEvent>
                                        {singleDateButtonValid() &&
                                            <>
                                                <label htmlFor="add-dates" className="sr-only">Skrá dagsetningar</label>
                                                <input type="button" id="add-dates" value="Skrá dagsetningar" className="mt-3 mb-5 w-100 add-date-button border-0 primary-button" onClick={addMultipleDates} />
                                            </>
                                        }
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                        <div className="col-md-6 col-11">
                            <div className="gray-bg selected-dates-container">
                                <h5 className="bold">Skráðar dagsetningar</h5>
                                <div id="all-dates">
                                    {eventDates.length > 0 ?
                                        <ul className="p-0">
                                            {eventDates.map(d =>
                                                <li className="mb-2" key={d.key}>
                                                    {d.start.format('dddd D. MMMM. YYYY [kl.] HH:mm')}
                                                    {!d.start.isSame(d.end, 'day') ?
                                                        <>
                                                            {Math.abs(d.start.diff(d.end, 'days')) === 1 ?
                                                                <> - {d.end.format('HH:mm')} (næsta dag)</>
                                                                :
                                                                <> - {d.end.format('D. MMMM. YYYY [kl.] HH:mm')}</>
                                                            }

                                                        </>
                                                        :
                                                        <> - {d.end.format('HH:mm')}</>
                                                    }
                                                    <span className="remove-date float-right" onClick={() => removeDate(d.key)}>X</span></li>
                                            )}
                                        </ul>
                                        :
                                        <><p className="smaller">Engin dagsetning hefur verið skráð</p></>
                                    }
                                    {errors['evDates'] ? <div className="alert alert-danger d-block error-msg">{getEventErrorMsg('dates')}</div> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mb-5">
                        <div className="col-md-12 col-11">
                            <h4 className="bold">Mynd</h4>
                        </div>
                    </div>
                    <ImgCropper
                        ref={register('img', { required: true })}
                        onUpload={(e) => { setValClearErr('img', e) }}
                        onFocalSelect={(rect) => setValClearErr('focal_point', rect)}
                        register={register}>
                    </ImgCropper>
                    {errors['img'] ? <div className="error-msg img text-left mb-50">Mynd vantar</div> : null}
                    {errors['focal_point'] ? <div className="error-msg img text-left mb-50">Vinsamlegast veljið fókussvæði</div> : null}
                    {eventImageSrc &&
                        <>
                            <div className="row justify-content-center justify-content-md-start mb-50">
                                <div className="col-lg-6 col-11">
                                    <h5 className="bold mb-4">Núverandi mynd:</h5>
                                    <div style={{ height: '400px' }}><img className="h-100 w-100" style={{ objectFit: 'contain' }} src={eventImageSrc} /></div>
                                </div>
                            </div>
                        </>
                    }
                    <Categories></Categories>
                    <div className="row justify-content-center mb-70">
                        <div className="col-md-6 col-11 mb-50">
                            <h4 className="bold mb-5">Merki</h4>
                            <div className="container gray-bg py-5 h-100">
                                <TagAutocomplete updateTags={updateTags} predefinedTags={selectedTags} />
                            </div>
                        </div>
                        <Festivals></Festivals>
                    </div>
                    {Location()}
                    {Links()}
                    {Object.keys(errors).length > 0 &&
                        <div className="row d-flex">
                            <div className="col-md-12 col-11">
                                <div className="alert alert-danger d-block error-msg">
                                    Það eru villur í forminu hér að ofan, vinsamlegast leiðréttu þær og reyndu aftur:
                                    <ul>
                                        {Object.keys(errors).map(e => <li>{getEventErrorMsg(e)}</li>)}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="row d-flex justify-content-center justify-content-md-end">
                        <div className="col-md-12 col-11 d-flex justify-content-between justify-content-md-end">
                            <div className="toggle-outer-container d-flex align-items-center">
                                <label className="mr-5" htmlFor={'publishNow'}>{editing ? 'Í birtingu' : 'Birta viðburð strax'}</label>
                                <div className="toggle-container"><input className="toggle" type="checkbox" value={publish} id={'publishNow'} checked={publish} onChange={(e) => setPublish(!publish)}></input></div>
                            </div>
                            {saving && <Spinner></Spinner>}
                            <button className="primary" type="submit" disabled={saving}> Vista viðburð </button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}
import axios from 'axios';
import { getCookie } from '../../utils/index';

const API_URL = '/api/v1';

// Get categories for events
const getEventCategories = () => {
   const url = API_URL + '/eventcategories/';
   return axios
      .get(url, {
         headers: {
            'X-CSRFToken': getCookie('csrftoken'),
         },
      })
      .then((response) => {
         return response.data;
      });
};

// Get categories for places/services
const getServiceCategories = () => {
   const url = API_URL + '/servicecategories/';
   return axios
      .get(url, {
         headers: {
            'X-CSRFToken': getCookie('csrftoken'),
         },
      })
      .then((response) => {
         return response.data;
      });
};

const CategoriesService = {
   getEventCategories,
   getServiceCategories,
};

export default CategoriesService;

import React from 'react'
import ReactDOM from 'react-dom'
// import Homepage from './homepage'
import { store } from '../../store' 
import Wrapper from './wrapper'

$.fn.frontpage = function () {
  return this.each(function () {
    var options = $(this).data();
    options.store = store;
    ReactDOM.render(React.createElement(Wrapper, options), this);
  });
};


/**
 * Initialize Data Attribute
 */

$(document).ready(function () {
    $('[data-frontpage]').frontpage();
});
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import FestivaladminsService from "../../api/festivaladmins";


export const getFestivalAdmin = createAsyncThunk(
    "festivaladmin/get",
    async (args, thunkAPI) => {
      try {
        const response = await FestivaladminsService.getFestivalAdmins(args);
        //thunkAPI.dispatch(setMessage(response.data.message));
        return response;
      } catch (error) {
          console.log("Error: ", error)
          const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        //thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  );
  

  const initialState = {
    fetching: false,
    festivalAdmins: [],
};


const festivaladminsSlice = createSlice({
    name: "festivaladmins",
    initialState,
    extraReducers: {
      [getFestivalAdmin.pending]: (state, action) => {
          state.fetching = true;
      },
      [getFestivalAdmin.fulfilled]: (state, action) => {
          state.fetching = false;
          state.festivalAdmins = action.payload;
      },
      [getFestivalAdmin.rejected]: (state, action) => {
          state.error = "Ekki tókst að sækja viðburðastjóra";
      },
    },
  });
  

const { reducer } = festivaladminsSlice;
export default reducer;
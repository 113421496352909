import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import moment from "moment"

import { eventsApi } from "../../api/events-list"
import EventService from "../../api/events"

function errorHandler(thunk, error) {
    console.log("Error: ", error)
    return thunk.rejectWithValue()
}

// TODO: Rewrite event API to RTK Query
export const getEvent = createAsyncThunk("event/get", async (args, thunkAPI) => {
    try {
        const response = await EventService.getEvent(args)
        return response
    } catch (error) {
        return errorHandler(thunkAPI, error)
    }
})

export const getAnonEvent = createAsyncThunk("event/get", async (args, thunkAPI) => {
    try {
        const response = await EventService.getAnonEvent(args)
        return response
    } catch (error) {
        return errorHandler(thunkAPI, error)
    }
})

export const getExample = createAsyncThunk("events/get", async (args, thunkAPI) => {
    try {
        const response = await EventService.getExample(args)
        return response
    } catch (error) {
        return errorHandler(thunkAPI, error)
    }
})

// Events with parameters & filters
export const filterEvents = createAsyncThunk("events/find", async (args, thunkAPI) => {
    try {
        const response = await EventService.filterEvents(args)
        return response
    } catch (error) {
        return errorHandler(thunkAPI, error)
    }
})

// For logged in user
export const getParamEvents = createAsyncThunk("paramevents/get", async (args, thunkAPI) => {
    try {
        const response = await EventService.getEventsByParameters(args)
        return response
    } catch (error) {
        return errorHandler(thunkAPI, error)
    }
})

// For logged in user
export const createEvent = createAsyncThunk("events/create", async (args, thunkAPI) => {
    try {
        const response = await EventService.createEvent(args)
        eventsApi.util.invalidateTags([{ type: "Event", id: "LIST" }])
        return response
    } catch (error) {
        return errorHandler(thunkAPI, error)
    }
})

// For logged in user
export const updateEvent = createAsyncThunk("events/update", async (args, thunkAPI) => {
    try {
        const response = await EventService.updateEvent(args)
        eventsApi.util.invalidateTags([
            { type: "Event", id: response.id },
            { type: "EventDetail", id: response.id },
        ])
        return response
    } catch (error) {
        return errorHandler(thunkAPI, error)
    }
})

// For logged in user
export const deleteEvent = createAsyncThunk("events/delete", async (args, thunkAPI) => {
    try {
        const response = await EventService.deleteEvent(args)
        eventsApi.util.invalidateTags([{ type: "Event", id: "LIST" }])
        return response
    } catch (error) {
        return errorHandler(thunkAPI, error)
    }
})

function getDate(d) {
    // Veit ekki hversu pottþétt þetta er - ss. ekki skila start date ef hún er < en dagurinn í dag
    // Treysti því að apinn skili bara viðeigandi events (sem eru ekki liðnir þ.e.)
    return moment(d).isAfter(moment())
        ? moment(d).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD")
}

function structureDataByOccurrence(data) {
    let tmp = {
        single: [],
        range: [],
        multiple: [],
    }
    data.map((ev) => {
        tmp[ev.occurrence].push(ev)
    })
    return tmp
}

const initialState = {
    example: [],
    fetching: false,
    allEvents: [],
    byDate: [],
    byParam: [],
    filtered: [],
    editing: false,
    cpEdit: false,
    event: {},
    showOnlyOwn: false,
}

const eventsSlice = createSlice({
    name: "events",
    initialState,
    reducers: {
        editEvent(state, data) {
            state.editing = true
            state.event = data.payload
        },
        removeEditEvent(state) {
            state.editing = false
            state.event = {}
        },
        editCopy(state, data) {
            state.cpEdit = true
            state.event = data.payload
        },
        removeEditCopy(state) {
            state.cpEdit = false
            state.event = {}
        },
        setShowState(state, data) {
            state.showOnlyOwn = data.payload
        },
    },
    extraReducers: {
        [getEvent.fulfilled]: (state, action) => {
            state.event = action.payload
            state.editing = true
        },
        [getEvent.rejected]: (state, action) => {
            state.error = "Ekki tókst að sækja viðburði"
        },
        [getExample.fulfilled]: (state, action) => {
            state.example = action.payload
            state.fetching = false
            // state.byDate = structureDataByDate(action.payload.results);
        },
        [getExample.pending]: (state) => {
            state.fetching = true
            // state.byDate = structureDataByDate(action.payload.results);
        },
        [getExample.rejected]: (state, action) => {
            state.error = "Ekki tókst að sækja viðburði"
        },
        [getParamEvents.fulfilled]: (state, action) => {
            state.fetching = false
            state.byParam = action.payload
        },
        [getParamEvents.pending]: (state, action) => {
            state.fetching = true
        },
        [getParamEvents.rejected]: (state, action) => {
            state.fetching = false
            state.error = "Ekki tókst að sækja viðburði"
        },
        [filterEvents.fulfilled]: (state, action) => {
            state.filtered = structureDataByOccurrence(action.payload)
        },
        [filterEvents.rejected]: (state, action) => {
            state.error = "Ekki tókst að sækja viðburði"
        },
        [createEvent.fulfilled]: (state, action) => {
            console.log("event created events: ", action.payload)
            // state.filtered = structureDataByOccurrence(action.payload)
        },
        [createEvent.rejected]: (state, action) => {
            state.error = "Ekki tókst að gera viðburði"
        },
        [deleteEvent.fulfilled]: (state, action) => {
            state.byParam = action.payload
            // state.filtered = structureDataByOccurrence(action.payload)
        },
        [deleteEvent.rejected]: (state, action) => {
            state.error = "Ekki tókst að eyða viðburði"
        },
    },
})

export const { editEvent, removeEditEvent, editCopy, removeEditCopy, setShowState } =
    eventsSlice.actions
const { reducer } = eventsSlice
export default reducer

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import FestivalCard from '../festival-card';
import { BackArrowIcon } from '../svg';
import { Tab, Tabs } from 'react-bootstrap';
import Spinner from '../spinner';
import { useNavigate } from 'react-router-dom'
import { getUserFestivals } from '../../slices/festivals';

const TYPE_TO_TITLE = {
    now: 'Í gangi',
    future: 'Framundan',
    nodate: 'Í vinnslu',
    past: 'Liðnar'
}

export default function FestivalEditor(props) {
    const { authenticated } = props
    const festivals = useSelector((state) => state.festivals.byDate)
    const fetching = useSelector((state) => state.festivals.fetching)
    const [selectedTab, setSelectedTab] = useState('now')
    const [check, setCheck] = useState(true)
    const dispatch = useDispatch()
    const festivalTypes = ['now', 'future', 'nodate', 'past']

    const navigate = useNavigate()

    useEffect(() => {
        dispatch(getUserFestivals())
    }, [])


    return (<>
        <div className='container back-btn'>
            <div className="row justify-content-center">
                <div className='col-sm-12 col-11 d-flex align-items-center'>
                    <BackArrowIcon />
                    <a href="/home" className="ml-2 ">Til baka</a>
                </div>
            </div>
        </div>
        <div className='gray-bg'>
            <div className='container filter-container'>
                <div className="row mb-5">
                    <div className='col-xl-9 col-12 d-flex flex-wrap'>
                        <h1 className='mb-0'>Hátíðir</h1>
                        <div className="d-flex align-items-center my-4 my-lg-0 ml-5">
                            {/* <input type="checkbox" value={"myFestivals"} id={'myFestivals'} checked={check} onChange={() => setCheck(!check)}></input> */}
                            {/* <label className="mb-0" htmlFor={'myFestivals'}><h5 className="bold ml-3">Sýna einungis mínar hátíðir</h5></label> */}
                        </div>
                    </div>
                    <div className='col-xl-3 col-12'>
                        <button className="primary smaller float-right" onClick={(e) => navigate('/festivals/new')}>Skrá hátíð</button>
                    </div>
                </div>
            </div>
        </div>
        {festivals && fetching ?
            <Spinner></Spinner>
            :
            <div className='container tabs-container'>
                <div className='row justify-content-center'>
                    <div className='col-sm-12 col-11'>
                        <div className='festival-overview container mt-50'>
                            <div className='dashboard-header row justify-content-center justify-content-md-start'>
                                {festivals && festivals['all'] && festivals['all'].length ?
                                    festivals['all'].map((festival) => {
                                        return <FestivalCard authenticated={authenticated} festival={festival} showDetails={false}></FestivalCard>
                                    })
                                    :
                                    <div>Engar hátíðir skráðar</div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
    </>)

}

FestivalEditor.defaultProps = {}

import React, { useState } from 'react';
// import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'
// import moment from 'moment';
import FestivalCard from '../festival-card';
import { useNavigate, Link } from "react-router-dom";


const DashboardHeader = (props) => {
    const { title, festivals, newFestivalButton, authenticated } = props
    const navigate = useNavigate()
    return (
        <>
            <div className='row justify-content-center justify-content-sm-start'>
                <div className='col-sm-12 col-11 d-flex'>
                    <div className='title-container'><h1>{title}</h1></div>
                    { newFestivalButton && 
                        <div className="ml-auto"><button className="primary smaller" onClick={() => navigate('/festivals/new')}>Ný hátíð</button></div>
                    }
                </div>
            </div> 
            <div className='row dashboard-header justify-content-center justify-content-md-start'>
                { festivals && festivals.map((fest) => {
                    return <FestivalCard authenticated={authenticated} festival={fest} showDetails={false}/>
                })}
            </div>
        </>
    )
}

const FrontpageHeader = (props) => {
    const { title, festivals, newFestivalButton, authenticated } = props
    const navigate = useNavigate()
    return (
        <>
            <div className='row justify-content-center justify-content-md-start frontpage-header'>
                <div className='col-lg-3 col-md-4 col-sm-6 col-11 title-container'>
                    <h1>{title}</h1>
                </div> 
                { newFestivalButton && 
                    <button onClick={() => navigate('/festivals/new')}>Ný hátíð</button>
                }
                { festivals && festivals.map((fest) => {
                    return <FestivalCard authenticated={authenticated} festival={fest} showDetails={true}/>
                })}
            </div>
        </>
    )
}


export default function FestivalOverview(props) {
    let { showDetails, title, festivals, newFestivalButton, authenticated } = props
    const [isDash, setIsDash] = useState(!showDetails)
	const dispatch = useDispatch()
    const navigate = useNavigate()

	return (<>
        <div className="festival-overview"> 
            { isDash ? 
            <div className="container pt-120 pb-80">
                <DashboardHeader title={title} festivals={festivals} newFestivalButton={newFestivalButton} authenticated={authenticated} />
                <div className="d-flex justify-content-center">
                    <button className="secondary thin"><Link to="/festivals">Sjá allar hátíðir</Link></button>
                </div>
            </div>
            : 
            <div className="gray-bg">
                <div className="container pt-120 pb-80">
                    <FrontpageHeader title={title} festivals={festivals} newFestivalButton={newFestivalButton} authenticated={authenticated}  />
                </div>
            </div>
            }
        </div>
    </>)
}

import React from 'react';


class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
      console.log(error, errorInfo);
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return (

            <div className="container login-page">
                <div className="row justify-content-center">
                <div className="col-12 col-md-8 col-lg-6 col-xl-4 login-form">
                    <h4>Villa</h4>
                    <h4>Það kom upp villa við að afgreiða vinnsluna þína. Vinsamlegast prófaðu aftur eða hafðu samband við tölvuþjónustu Reykjavíkurborgar ef vandamálið lagast ekki.</h4>
                </div>
                </div>
            </div>


        )
       
      }
  
      return this.props.children; 
    }
  }

export default ErrorBoundary
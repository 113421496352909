import axios from "axios";
import {getCookie} from "../../utils/index";

const API_URL = '/api/v1/festivals/'


const getFestival = (args) => {
    var url = `${API_URL}${args}/`

    return axios
        .get(url, {
            headers: {
                'X-CSRFToken': getCookie("csrftoken"),
            }
        })
        .then((response) => {
            return response.data;
        });
};

const getUserFestivals = (args) => {
    var url = API_URL
    if (args && args['id']){
        url += args['id']
    }
    if (args && args['owner']){
        url += args['owner']
    }
 
    return axios
        .get(url, {
            headers: {
                'X-CSRFToken': getCookie("csrftoken"),
            }
        })
        .then((response) => {
            return response.data;
        });
};

const createFestival = (newFestival, args) => {
    var url = API_URL

    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': window.csrf_token,
        // 'X-CSRFToken': getCookie("csrftoken"),
    }
 
    return axios
        .post(url, 
            JSON.stringify({'festival': newFestival}),
            { headers: headers }
        )
        .then((response) => {
            return response.data;
        });
};

const addEventsToFestival = ({id, eventIds}) => {
    var url = API_URL + id + '/'
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': window.csrf_token,
        // 'X-CSRFToken': getCookie("csrftoken"),
    }

    return axios
        .patch(url, 
            JSON.stringify({'eventIds': eventIds}), 
            { headers: headers})

}

const updateFestival = ({id, data}) => {
    var url = API_URL + id + '/'
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': window.csrf_token,
        // 'X-CSRFToken': getCookie("csrftoken"),
    }

    return axios
        .patch(url, 
            JSON.stringify(data), 
            { headers: headers})

}

const FestivalService = {
    getFestival,
    getUserFestivals,
    createFestival,
    updateFestival,
    addEventsToFestival
};

export default FestivalService;

import React from 'react';
import { connect } from 'react-redux';
import { searchEvents, clearSearch } from '../../slices/guest';
import { SearchIcon } from '../svg';
import moment from 'moment';

class NavbarSearch extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    if(prevProps.gui.currView !== this.props.gui.currView){
      this.props.dispatch(clearSearch())
    }
  }


  onSearch(e) {
    if(e.target.value.length >= 3){
      // this.props.dispatch(searchHomepageEvents(e.target.value));
      this.props.dispatch(searchEvents({'searchTerm': e.target.value}));
    } else if (e.target.value.length < 3){
      this.props.dispatch(clearSearch())
      // this.props.dispatch(clearHomepageSearch())
    }
  }


  render() {
    return (
      <>   
      <div className="nav-link search activate-search">
        <div className="search d-flex align-items-center">
            <input name="q" type="text" id="search-input" placeholder="Hvað viltu finna?" className="header-search-input" onChange={this.onSearch.bind(this)} autoComplete="off"/>
          <SearchIcon />
          <h5 className="search-text d-none d-sm-block bold">Leita</h5>
        </div>
      </div>
      { this.props.guest.searchResults.length > 0 && 
        <ul>
          { this.props.guest.searchResults.map((item) => 
              <li>{item.language.is.title} {moment(item.start).format('DD.MM.YY')}</li>
          )}
        </ul>
      }
      </>
    )
  }
}

NavbarSearch.defaultProps = {
}

function mapStateToProps(state){
  var f = {
    searchEvents: {},
    events: {},
    guest: {},
    gui: {},
  }
  f.searchEvents = (state.searchEvents === undefined ? {} : state.searchEvents)
  f.events = (state.events === undefined ? {} : state.events)
  f.guest = (state.guest === undefined ? {} : state.guest)
  f.gui = (state.gui === undefined ? {} : state.gui)

  return f;
}


export default connect(mapStateToProps)(NavbarSearch);
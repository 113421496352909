import React, { Component, useEffect, useState } from 'react'; 
import { useDispatch, useSelector } from 'react-redux'
import { Map, GoogleApiWrapper, Marker, InfoWindow, Listing } from 'google-maps-react';

// import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';




// Takes in 0-1 marker, sets map center as Ráðhúsið or selected marker
// Draggable marker -> use only for creating places & events
export function BasicMap(props) {
    const { marker, mapStyles } = props
    // Ráðhús coordinates
    const [lat, setLat] = useState(64.1462)
    const [lng, setLng] = useState(-21.9424)
    const [zoom, setZoom] = useState(14)

    useEffect(() => {
      if(marker){
        setLat(marker.lat)
        setLng(marker.lng)
        setZoom(17)
      }
    }, [marker])


    return (
      <Map
        google={window.google}
        zoom={zoom}
        center={{
          lat: lat,
          lng: lng
        }}
        style={mapStyles}
        initialCenter={{
          lat: lat,
          lng: lng
        }}
      > 

      { marker && 
        <Marker name={marker.address} title={marker.address} position={{ lat: lat, lng: lng }}></Marker>
      }
      </Map>
    );
}
export default GoogleApiWrapper({
  apiKey: 'AIzaSyBepmwfdPRFXkdV55-JOECBdELipeyaEiw' 
})(BasicMap);



// Working implementation for react-google-maps, zoom was not working on initialization
// import React, { useState, useCallback } from 'react'
// import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

// const containerStyle = {
//     width: '400px',
//     height: '400px'
// };

// const center = {
//     lat: 64.1462,
//     lng: -21.9424
// };

// function BasicMap(props) {
//     const { isLoaded } = useJsApiLoader({
//         id: 'google-map-script',
//         googleMapsApiKey: "AIzaSyBepmwfdPRFXkdV55-JOECBdELipeyaEiw"
//     })

//   const [map, setMap] = useState(null)

//   const onLoad = useCallback(function callback(map) {
//     // This is just an example of getting and using the map instance!!! don't just blindly copy!
//     const bounds = new window.google.maps.LatLngBounds(center);
//     map.fitBounds(bounds);

//     setMap(map)
//   }, [])

//   const onUnmount = useCallback(function callback(map) {
//     setMap(null)
//   }, [])

//   return isLoaded ? (
//     <>
//         {/* {GoogleMap.setZoom(5)} */}
//       <GoogleMap
//         mapContainerStyle={containerStyle}
//         center={center}
//         zoom={5}
//         onLoad={onLoad}
//         onUnmount={onUnmount}
//       >
//         { /* Child components, such as markers, info windows, etc. */ }
//         <Marker position={center} draggable={true} onDragEnd={(e) => console.log("Drag end: ", e.latLng.lat() + ' ' + e.latLng.lng())}></Marker>
//         <></>
//       </GoogleMap>
//     </>
//   ) : <></>
// }

// // export default useJsApiLoader({
// //     id: 'google-map-script',
// //     googleMapsApiKey: "AIzaSyBepmwfdPRFXkdV55-JOECBdELipeyaEiw"
// // })(BasicMap);


// export default React.memo(BasicMap)
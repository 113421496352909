import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import TagService from "../../api/tags";


export const getTags = createAsyncThunk(
    "tags/get",
    async (args, thunkAPI) => {
      try {
        const response = await TagService.fetchTags(args);
        //thunkAPI.dispatch(setMessage(response.data.message));
        return response;
      } catch (error) {
          console.log("Error: ", error)
          const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        //thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  );
  

  const initialState = {
    fetching: false,
    results: [],
};


const tagSlice = createSlice({
    name: "tags",
    initialState,
    extraReducers: {
      [getTags.pending]: (state, action) => {
          state.fetching = true;
      },
      [getTags.fulfilled]: (state, action) => {
          state.fetching = false;
          state.results = action.payload;
      },
      [getTags.rejected]: (state, action) => {
          state.error = "Ekki tókst að sækja flokka";
      },
    },
  });
  

const { reducer } = tagSlice;
export default reducer;
import React from 'react';
import DateTime from 'react-datetime';
import moment from 'moment'
import translateLabel from 'react-rrule-generator/src/lib/utils/translateLabel'
import translations from '../../utils/translations';

import TimePicker from 'rc-time-picker';


import { TIME_FORMAT, DATE_TIME_FORMAT, DATE_FORMAT } from '../../constants'

class HvirfillTimepicker extends React.Component {

    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(inputDate) {
        const editedEvent = {
            value: this.props.date.set({hour: inputDate.hour(), minute: inputDate.minute()}),
            day: this.props.dayOfWeek,
            type: this.props.type
        };
        this.props.handleChangeTime(editedEvent)
    }

    render() {
        let calendarAttributes = {
            'aria-label': translateLabel(translations, 'start.tooltip'),
        }
        return (

            <div className="date-time d-flex">
                <TimePicker
                    showSecond={false}
                    allowEmpty={false}
                    value={moment(this.props.time, TIME_FORMAT)}
                    format={TIME_FORMAT}
                    className="timepicker-hvirfill"
                    onChange={this.handleChange}
                />
            </div>
        )
    }
}

HvirfillTimepicker.defaultProps = {
    date: moment(),
}

export default HvirfillTimepicker
import React, { useCallback, useEffect, useMemo, useState, useRef, memo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"
import Nav from "react-bootstrap/Nav"
import Tab from "react-bootstrap/Tab"
import Select from "react-select"
import DateTime from "react-datetime"

import { EVENT_GROUP_TYPES, POSTAL_CODES, DATE_FORMAT } from "../../constants"
import Spinner from "../spinner"
import EventCard from "../event-card"
import { CalendarIcon, BackArrowIcon } from "../svg"
import { getEventCategories } from "../../slices/categories"
import { useGetEventsQuery } from "../../api/events-list"
import { useDebouncedValue } from "../../hooks"

const TYPE_TO_TITLE = {
    now: "Í gangi",
    future: "Framundan",
    nodate: "Í vinnslu",
    past: "Liðnir",
}

export default function EventEditor() {
    const [params, setParams] = useSearchParams()
    const navigate = useNavigate()
    const categories = useSelector((state) => state.categories.events)
    const types = useSelector((state) => state.types.events)
    const dispatch = useDispatch()
    const [showOwnCheck, setShowOwnCheck] = useState(false)
    const [selectedTab, setSelectedTab] = useState(getEventTypeOrDefault(params.get("type")))
    const [categoryOptions, setCategoryOptions] = useState(false)
    const [postalCodeOptions, setPostalCodeOptions] = useState(false)

    const [filters, setFilters] = useState({})
    const debouncedFilters = useDebouncedValue(filters, 300)
    const [selectedType, setSelectedType] = useState("")
    const [selectedCategory, setSelectedCategory] = useState("")
    const [selectedDate, setSelectedDate] = useState("")
    const [selectedPost, setSelectedPost] = useState("")
    const [search, setSearch] = useState("")

    useEffect(() => {
        setSelectedTab(getEventTypeOrDefault(params.get("type")))
    }, [params])

    useEffect(() => {
        setParams({ type: selectedTab })
    }, [selectedTab])

    useEffect(() => {
        dispatch(getEventCategories())
        setPostalCodeOptions(
            POSTAL_CODES.map((code) => {
                return { value: code, label: code }
            })
        )
    }, [])

    useEffect(() => {
        if (!categories) return

        setCategoryOptions(
            categories.map((item) => {
                return { value: item.name, label: item.name }
            })
        )
    }, [categories])

    const addFilter = useCallback((key, value) => {
        setFilters((prev) => {
            return { ...prev, [key]: value }
        })
    }, [])

    const clearFilters = useCallback(() => {
        setFilters({})
        setSelectedType("")
        setSelectedCategory("")
        setSelectedDate("")
        setSelectedPost("")
        setSearch("")
        setShowOwnCheck(false)
    }, [])

    const CategoryDropdown = useMemo(() => {
        if (!categoryOptions) return null

        return (
            <div className="input-container">
                <Select
                    className="w-100 cursor-pointer react-select-container"
                    placeholder="Flokkur"
                    classNamePrefix="react-select"
                    name="categorySelect"
                    value={selectedCategory}
                    options={categoryOptions}
                    onChange={(c) => {
                        addFilter("category", c.value)
                        setSelectedCategory(c)
                    }}
                />
            </div>
        )
    }, [categoryOptions, selectedCategory])

    const TypeDropdown = useMemo(() => {
        if (!types) return null

        return (
            <Select
                className="w-100 cursor-pointer react-select-container"
                placeholder="Tegund"
                classNamePrefix="react-select"
                name="categorySelect"
                value={selectedType}
                options={types.map((item) => {
                    return { value: item.name, label: item.name }
                })}
                onChange={(t) => {
                    addFilter("type", t.value)
                    setSelectedType(t)
                }}
            />
        )
    }, [types, selectedType])

    const DatePicker = useMemo(() => {
        return (
            <div className="date-picker-container">
                <DateTime
                    value={selectedDate}
                    placeholder="Dagsetning"
                    timeFormat={false}
                    viewMode="days"
                    dateFormat={DATE_FORMAT}
                    locale="is"
                    inputProps={{
                        placeholder: "Dagsetning",
                        readOnly: true,
                    }}
                    closeOnSelect
                    closeOnTab
                    showYearPicker
                    onChange={(date) => {
                        addFilter("date", date.format(DATE_FORMAT))
                        setSelectedDate(date)
                    }}
                />
                <CalendarIcon />
            </div>
        )
    }, [selectedDate])

    const PostalCodeDropdown = useMemo(() => {
        if (!postalCodeOptions) return null

        return (
            <Select
                className="w-100 cursor-pointer react-select-container"
                placeholder="Póstnúmer"
                classNamePrefix="react-select"
                name="categorySelect"
                value={selectedPost}
                options={postalCodeOptions}
                onChange={(p) => {
                    addFilter("postal", p.value)
                    setSelectedPost(p)
                }}
            />
        )
    }, [postalCodeOptions, selectedPost]);


    const SearchInput = useMemo(() => {
        return (
            <div className="input-container">
                <input
                    type="search"
                    className="px-4 py-0"
                    placeholder="Leita að viðburði"
                    value={search}
                    onChange={(e) => {
                        addFilter("search", e.target.value)
                        setSearch(e.target.value)
                    }}
                />
            </div>
        )
    }, [search])

    return (
        <>
            <div className="container back-btn">
                <div className="row justify-content-center">
                    <div className="col-sm-12 col-11 d-flex align-items-center">
                        <BackArrowIcon />
                        <a href="/home" className="ml-2 ">
                            Til baka
                        </a>
                    </div>
                </div>
            </div>
            <div className="gray-bg">
                <div className="container filter-container">
                    <div className="row mb-5">
                        <div className="col-lg-9 col-12 d-flex flex-wrap">
                            <h1 className="mb-0">Viðburðir</h1>
                            <div className="d-flex align-items-center my-4 my-lg-0 ml-5">
                                <input
                                    type="checkbox"
                                    value={"myEvents"}
                                    id={"myEvents"}
                                    checked={showOwnCheck}
                                    onChange={(e) => {
                                        addFilter("owner", e.target.checked)
                                        setShowOwnCheck(e.target.checked)
                                    }}
                                />
                                <label className="mb-0" htmlFor={"myEvents"}>
                                    <h5 className="bold ml-3">Sýna einungis mína viðburði</h5>
                                </label>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3">
                            <button
                                className="primary smaller float-right"
                                onClick={() => navigate("/events/new")}
                            >
                                Skrá viðburð
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            {SearchInput}
                        </div>
                        <div className='w-100 d-none d-lg-block'/>
                        <div className="col-xl-3 col-lg-6 col-12">{CategoryDropdown}</div>
                        <div className="col-xl-3 col-lg-6 col-12 mb-4 mb-lg-0">{TypeDropdown}</div>
                        <div className="col-xl-3 col-lg-6 col-12 mb-4 mb-lg-0">{DatePicker}</div>
                        <div className="col-xl-3 col-lg-6 col-12 mb-4 mb-lg-0">
                            {PostalCodeDropdown}
                        </div>
                    </div>
                    <div className="row mt-3 justify-content-end">
                        {Object.keys(filters).length > 0 && (
                            <button
                                className="col-auto appearance-none text"
                                type="button"
                                tabIndex={0}
                                onClick={clearFilters}
                            >
                                Hreinsa síur
                            </button>
                        )}
                    </div>
                </div>
            </div>

            <div className="container tabs-container event-overview">
                <div className="row">
                    <div className="col-12">
                        <Tab.Container
                            activeKey={selectedTab}
                            onSelect={(k) => setSelectedTab(k)}
                            className="border-0 flex-nowrap position-relative"
                        >
                            <Nav className="nav-tabs">
                                {EVENT_GROUP_TYPES.map((type) => (
                                    <Nav.Item key={type}>
                                        <Nav.Link eventKey={type}>{TYPE_TO_TITLE[type]}</Nav.Link>
                                    </Nav.Item>
                                ))}
                            </Nav>
                            <Tab.Content>
                                {EVENT_GROUP_TYPES.map((type) => (
                                    <EventListTab
                                        key={type}
                                        group={type}
                                        filters={debouncedFilters}
                                        active={selectedTab === type}
                                    />
                                ))}
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </div>
            </div>
        </>
    )
}

const EventListTab = ({ group, filters, active }) => {
    const tabRef = useRef()
    const [page, setPage] = useState(1)

    const { data, isFetching, isError } = useGetEventsQuery(
        {
            pageSize: 36, // Divisible by 2, 3, 4 columns so we can have a nice grid
            page,
            group,
            ...filters,
        },
        {
            skip: !active,
        }
    )

    useEffect(() => {
        setPage(1)
    }, [filters])

    const events = useMemo(() => {
        return data ? data.results : []
    }, [data])

    const hasNextPage = useMemo(() => {
        return data && data.next !== null
    }, [data])

    const hasPrevPage = useMemo(() => {
        return data && data.previous !== null
    }, [data])

    const handleNextPage = useCallback(() => {
        setPage((prev) => prev + 1)
        tabRef.current.scrollIntoView({ behavior: "smooth" })
    }, [])

    const handlePrevPage = useCallback(() => {
        setPage((prev) => prev - 1)
        tabRef.current.scrollIntoView({ behavior: "smooth" })
    }, [])

    const showPagination = useMemo(() => {
        return events.length > 0 && (hasNextPage || hasPrevPage)
    }, [events, hasNextPage, hasPrevPage])

    return (
        <Tab.Pane eventKey={group} className="position-relative" ref={tabRef}>
            {isFetching && (
                <div
                    className="position-absolute w-100 h-100"
                    style={{ zIndex: 1, backgroundColor: "#FFFFFF99" }}
                >
                    <div className="position-relative" style={{ top: "6rem" }}>
                        <Spinner />
                    </div>
                </div>
            )}
            {events.length === 0 && !isFetching && !isError && (
                <div className="col-12">
                    <p>Engir viðburðir eiga við gefin skilyrði</p>
                </div>
            )}
            {isError && (
                <div className="col-12 text-center p-2 text-danger">
                    <p>Villa kom upp við að sækja viðburði</p>
                </div>
            )}
            <div className="container p-0">
                <div className="row">
                    <EventsList events={events} type={group} />
                    {showPagination && (
                        <nav className="col-12 d-flex justify-content-between">
                            <button
                                className="secondary thin"
                                onClick={handlePrevPage}
                                disabled={!hasPrevPage}
                            >
                                Fyrri síða
                            </button>
                            <button
                                className="secondary thin"
                                onClick={handleNextPage}
                                disabled={!hasNextPage}
                            >
                                Næsta síða
                            </button>
                        </nav>
                    )}
                </div>
            </div>
        </Tab.Pane>
    )
}

const EventsList = memo(({ events = [], type }) => {
    return events.map((ev) => <EventCard key={ev.id} event={ev} type={type} />)
})

const getEventTypeOrDefault = (type = "") => {
    return EVENT_GROUP_TYPES.includes(type) ? type : EVENT_GROUP_TYPES[0]
}

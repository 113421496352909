import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from 'react-datetime';
import moment from 'moment'
import translateLabel from 'react-rrule-generator/src/lib/utils/translateLabel'
import TimePicker from 'rc-time-picker';
import { CalendarIcon } from '../svg'

import { DATE_FORMAT } from '../../constants'

export default function HvirfillDatePicker(props) {
    const { onChange, startDate, endDate } = props
    var single = false // See if needed

    function validEndDate( current ){
        return current.isSameOrAfter( startDate );
    };

    return (<>
        { single ?
            <div className="row mb-50">
                <div className="col date-picker-container position-relative">
                    <DateTime
                        // {...calendarAttributes}
                        value={0}
                        // locale={translateLabel(translations, 'locale')}
                        timeFormat={false}
                        viewMode="days"
                        dateFormat={DATE_FORMAT}
                        locale='is'
                        inputProps={{readOnly:true, placeholder: 'Dagsetning'}}
                        closeOnSelect
                        closeOnTab
                        required
                        onChange={(inputDate) => {
                            const editedEvent = {
                                target: {
                                    value: moment(inputDate).format(DATE_FORMAT),
                                    name: 'start.onDate.date',
                                },
                            };
                            onChange(editedEvent)
                            // this.handleChangeDate(editedEvent);
                        }}
                    />
                    <CalendarIcon />
                </div>
            </div>
            :
            <div className="row mb-50">
                <div className="col date-picker-container position-relative">
                    <DateTime
                        // {...calendarAttributes}
                        value={startDate}
                        // locale={translateLabel(translations, 'locale')}
                        timeFormat={false}
                        viewMode="days"

                        dateFormat={DATE_FORMAT}
                        locale='is'
                        inputProps={{readOnly:true, placeholder: 'Dagsetning frá'}}
                        closeOnSelect
                        closeOnTab
                        required
                        onChange={(inputDate) => {
                            const editedEvent = {
                                target: {
                                    value: moment(inputDate).format(DATE_FORMAT),
                                    name: 'startDate',
                                },
                            };
                            onChange(editedEvent)
                        }}
                    />
                    <CalendarIcon />
                </div>
                <div className="col date-picker-container position-relative">
                    <DateTime
                        // {...calendarAttributes}
                        value={endDate}
                        minDate={new Date()}
                        // locale={translateLabel(translations, 'locale')}
                        timeFormat={false}
                        viewMode="days"
                        isValidDate={validEndDate}
                        dateFormat={DATE_FORMAT}
                        locale='is'
                        inputProps={{readOnly:true, placeholder: 'Dagsetning til'}}
                        closeOnSelect
                        closeOnTab
                        required
                        onChange={(inputDate) => {
                            const editedEvent = {
                                target: {
                                    value: moment(inputDate).format(DATE_FORMAT),
                                    name: 'endDate',
                                },
                            };
                            onChange(editedEvent)
                        }}
                    />
                    <CalendarIcon />
                </div>
            </div>
        }
    </>
    )

}

import { RRule, RRuleSet, rrulestr } from 'rrule'

export const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm'
export const DATE_FORMAT = 'DD.MM.YYYY'
export const TIME_FORMAT = 'HH:mm'
export const API_DATE_TIME_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss[Z]' //"2014-06-10T11:30:00Z"
export const API_DATE_FORMAT = 'YYYY-MM-DD' //"2014-06-10"

// Ath, breyting á xx_ERRORS veldur breytingu á villumeðhöndlun í new-service/event 
export const EVENT_ERRORS = {
  title: 'Lýsing og hnitmiðað heiti fyrir viðburðinn.',
  title_en: 'Lýsing og hnitmiðað heiti fyrir viðburðinn á ensku.',
  description: 'Lýsing á viðburðinum, hverjir halda hann, um hvað snýst hann, dagskrá ofl.',
  description_en: 'Lýsing á viðburðinum á ensku, hverjir halda hann, um hvað snýst hann, dagskrá ofl.',
  place: 'Staðarheiti viðburðar, t.d. Borgarleikhúsið',
  place_en: 'Staðarheiti viðburðar á ensku, t.d. Reykjavík City Theatre.',
  address: 'Velja þarf staðsetningu viðburðar með því að skrifa hana inn í textareitinn "Heimilisfang".',
  dates: 'Velja þarf a.m.k. eina dagsetningu fyrir viðburðinn',
  // website: 'Heimasíðu fyrir þennan stað vantar',
  // phone: 'Síma fyrir þennan stað vantar',
  // email: 'Tölvupóst fyrir þennan stað vantar',
}

export const SERVICE_ERRORS = {
  title: 'Heiti staðar vantar.',
  title_en: 'Heiti staðar á ensku vantar.',
  description: 'Stutt og hnitmiðuð lýsing á staðnum og þjónustunni sem boðið er upp á.',
  description_en: 'Stutt og hnitmiðuð lýsing á staðnum og þjónustunni sem boðið er upp á, á ensku.',
  // place: 'Staðarheiti',
  // place_en: 'Staðarheiti á ensku, t.d. Reykjavík City Theatre.',
  website: 'Heimasíðu fyrir þennan stað vantar',
  phone: 'Síma fyrir þennan stað vantar',
  email: 'Tölvupóst fyrir þennan stað vantar',
  address: 'Velja þarf staðsetningu viðburðar með því að skrifa hana inn í textareitinn "Heimilisfang".',
  wheelchair_access: 'Vinsamlegast veljið viðeigandi reit'
}

export const FESTIVAL_ERRORS = {
  title: 'Heiti hátíðar vantar.',
  title_en: 'Heiti hátíðar á ensku vantar.',
  description: 'Stutt lýsing á hátíð vantar',
  description_en: 'Stutt lýsing á hátíð á ensku vantar.',
  // place: 'Staðarheiti',
  // place_en: 'Staðarheiti á ensku, t.d. Reykjavík City Theatre.',
  website: 'Heimasíðu fyrir þessa hátíð vantar',
  phone: 'Síma fyrir þessa hátíð vantar',
  email: 'Tölvupóst fyrir þessa hátíð vantar',
  festival_admin: 'Vinsamlegast veldu viðburðastjóra fyrir hátíð',
  date: 'Dagsetningu vantar'
}

export const ADDRESS_ERRORS = {
  address: 'Velja þarf staðsetningu viðburðar með því að skrifa hana inn í textareitinn "Heimilisfang".',
  place: 'Staðarheiti viðburðar, t.d. Borgarleikhúsið',
  place_en: 'Staðarheiti viðburðar á ensku, t.d. Reykjavík City Theatre.'
}

export const WEEKDAYS = {
  mon: "Mánudagar",
  tue: "Þriðjudagar",
  wed: "Miðvikudagar",
  thu: "Fimmtudagar",
  fri: "Föstudagar",
  sat: "Laugardagar",
  sun: "Sunnudagar"
}

export const EVENT_GROUP_TYPES = ["now", "future", "nodate", "past"]

export const POSTAL_CODES = ['101', '102', '103', '104', '105', '106', '107', '108', '109', '110', '111', '112', '113', '116']

// FOR TESTING PURPOSES, should be deleted!

export const OPENING_HOURS_DEFAULT = {
  mon: { open: false, from: "08:00", to: "16:00" },
  tue: { open: false, from: "08:00", to: "16:00" },
  wed: { open: false, from: "08:00", to: "16:00" },
  thu: { open: false, from: "08:00", to: "16:00" },
  fri: { open: false, from: "08:00", to: "16:00" },
  sat: { open: false, from: "08:00", to: "16:00" },
  sun: { open: false, from: "08:00", to: "16:00" }
}

export const opening_hours = {
  "default":{
    "mon": {
        "open": true,
        "from": "09:00",
        "to": "17:00"
    },
    "tue": {
        "open": true,
        "from": "09:00",
        "to": "17:00"
    },
    "wed": {
        "open": true,
        "from": "09:00",
        "to": "17:00"
    },
    "thu": {
        "open": true,
        "from": "09:00",
        "to": "17:00"
    },
    "fri": {
        "open": true,
        "from": "09:00",
        "to": "17:00"
    },
    "sat": {
        "open": true,
        "from": "09:00",
        "to": "17:00"
    },
    "sun": {
        "open": false
    },
},
  "overrides":[
      {"date": "2020/06/10", "from": "09:00", "to": "17:00"},
      {"date": "2021/05/29", "from": "09:00", "to": "17:00"},
      {"date": "2022/02/02", "from": "09:00", "to": "17:00"},
      {"date": "2024/02/01", "from": "09:00", "to": "17:00"},
  ],
  "exceptions":[
      "2022/09/10",
      "2022/10/02",
      "2024/01/03",
      "2029/09/04",
      "2030/10/07",
  ]
}

// Constants for RRULE (repeat-events.js)
export const WEEKDAY_MAPPER = {
  mon: RRule.MO,
  tue: RRule.TU,
  wed: RRule.WE,
  thu: RRule.TH,
  fri: RRule.FR,
  sat: RRule.SA,
  sun: RRule.SU,
}

export const NUMERAL_MAPPER = {
  first: 1,
  second: 2,
  third: 3,
  fourth: 4,
  last: -1,
}

export const MONTH_MAPPER = {
  jan: 1,
  feb: 2,
  mar: 3,
  apr: 4,
  may: 5,
  jun: 6,
  jul: 7,
  aug: 8,
  sep: 9,
  oct: 10,
  nov: 11,
  dec: 12,
}

// Initial values
export const INVAL = {
  repeatInterval: 1,
  numeral: 'first',
  onRepeat: 'on_day',
  selected: 'daily',
  weekday: 'mon',
  month: 'jan',
  repeatDays: [],
  onDay: 1,
  selectedNumeral: null
}
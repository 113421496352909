import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

export default function FestivalCard(props){
    let { showDetails, authenticated, festival } = props
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user = useSelector((state) => state.user.info)

    return (
        <div onClick={() => navigate(`/festivals/${festival.id}`)} className="col-lg-3 col-md-4 col-sm-6 col-11 mb-5 festival-card">
            <div className="content-container h-100">
                { showDetails && 
                <>
                    <img className="h-100 w-100" src={festival.image.large}/>
                </>
                }
                <div className="text-container d-flex flex-column">
                    <h4 className='title position-relative bold'>{festival.title}</h4>
                    { showDetails && 
                    <>
                        <h5 className="bold mt-auto mb-2">{ moment(festival.from_date, 'YYYY-MM-DD').format('DD. MMMM')} - { moment(festival.to_date, 'YYYY-MM-DD').format('DD. MMMM')}</h5>
                        <span>{ festival.evs.length } viðburðir</span>
                    </>
                    }
                </div>
            </div> 
        </div>
    )
}

FestivalCard.defaultProps = {}

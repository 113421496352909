import { createSlice } from '@reduxjs/toolkit'

const initialState = { currView: "initial" }

const guiSlice = createSlice({
  name: 'gui',
  initialState,
  reducers: {
    setSelectedView(state, view){
        state.currView = view.payload
    },
    clearSelectedView(state){
        state.currView = "initial"
    }
  },
})

export const { setSelectedView, clearSelectedView } = guiSlice.actions
const { reducer } = guiSlice;
export default reducer;

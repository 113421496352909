export default {
    locale: 'is',
    invalid_rrule: "Ógilt gildi",
    eventPlaceholders: {
      website: 'Vefsíða viðburðar',
      facebookLink: 'Viðburðurinn á facebook',
      tickets: 'Hlekkur til miðakaupa',
      title: 'Heiti viðburðar',
      title_en: 'Heiti viðburðar á ensku',
      description: 'Stutt lýsing á viðburði',
      description_en: 'Stutt lýsing á viðburði á ensku',
      detailedDescription: 'Nánari lýsing á viðburði (valkvætt)',
      enDetailedDescription: 'Nánari lýsing á viðburði á ensku (valkvætt)',
      place: 'Heiti staðar',
      place_en: 'Heiti staðar á ensku',
    },
    festivalPlaceholders: {
      website: 'Vefsíða hátíðar',
      email: 'Tölvupóstur',
      phone: 'Sími',
      facebook_link: 'Hátíðin á facebook',
      ticket_link: 'Hlekkur til miðakaupa',
      title: 'Heiti hátíðar',
      title_en: 'Heiti hátíðar á ensku',
      description: 'Lýsing hátíðar',
      description_en: 'Lýsing hátíðar á ensku',
      event_managers: 'Tengja viðburðastjóra',
    },
    servicePlaceholders: {
      website: 'Vefsíða',
      email: 'Tölvupóstur',
      phone: 'Sími',
      placeName: 'Heiti staðar',
      placeName_en: 'Heiti staðar á ensku',
      description: 'Lýsing á stað',
      description_en: 'Lýsing á stað á ensku',
    },
    months: {
      jan: 'Jan',
      feb: 'Feb',
      mar: 'Mar',
      apr: 'Apr',
      may: 'Maí',
      jun: 'Jún',
      jul: 'Júl',
      aug: 'Ágú',
      sep: 'Sep',
      oct: 'Okt',
      nov: 'Nóv',
      dec: 'Des',
    },
    months_long: {
      jan: 'Janúar',
      feb: 'Febrúar',
      mar: 'Mars',
      apr: 'Apríl',
      may: 'Maí',
      jun: 'Júní',
      jul: 'Júlí',
      aug: 'Ágúst',
      sep: 'September',
      oct: 'Október',
      nov: 'Nóvember',
      dec: 'Desember',
    },
    days_short: {
      mon: 'Mán',
      tue: 'Þri',
      wed: 'Mið',
      thu: 'Fim',
      fri: 'Fös',
      sat: 'Lau',
      sun: 'Sun',
    },
    days: {
      mon: 'Mánudag',
      tue: 'Þriðjudag',
      wed: 'Miðvikudag',
      thu: 'Fimmtudag',
      fri: 'Föstudag',
      sat: 'Laugardag',
      sun: 'Sunnudag',
      // day: 'Dag',
      // weekday: 'Virkan dag',
      // weekend: 'Helgi',
    },
    numerals: {
      first: 'Fyrsta',
      second: 'Annan',
      third: 'Þriðja',
      fourth: 'Fjórða',
      last: 'Síðasta',
    },
    start: {
      label: 'Byrjar',
      tooltip: 'Fyrsta mögulega dagsetning viðburðar',
    },
    repeat: {
      label: 'Endurtekning',
      yearly: {
        label: 'Árlega',
        on: 'á',
        on_the: 'á',
        of: 'í',
      },
      monthly: {
        label: 'Mánaðarlega',
        every: 'á',
        months: 'mánað(ar) fresti',
        rep: 'mánaðar fresti',
        on_day: 'á mánaðardegi',
        on_the: '',
      },
      weekly: {
        label: 'Vikulega',
        every: 'á',
        weeks: 'viku/vikna fresti',
        rep: 'viku/vikna fresti',
      },
      daily: {
        label: 'Daglega',
        every: 'á',
        days: 'dags/daga fresti',
        rep: 'dags/daga fresti',
      },
      hourly: {
        label: 'Stündlich',
        every: 'Jede',
        hours: 'Stunde(n)',
      },
    },
    end: {
      label: 'Lýkur',
      tooltip: 'Síðasta mögulega dagsetning viðburðar',
      never: 'Aldrei',
      after: 'Eftir',
      on_date: 'á dagsetningu',
      executions: 'endurtekningu/-ar.',
    },
  };
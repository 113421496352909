import axios from "axios"
import { getCookie } from "../../utils/index"

const API_URL = "/api/v1/events/"

const getEventsByParameters = (args) => {
    var url = API_URL

    if (args && args["owner"]) {
        url += "?owner=" + args["owner"]
    }

    return axios
        .get(url, {
            headers: {
                "X-CSRFToken": getCookie("csrftoken"),
            },
        })
        .then((response) => {
            return response.data
        })
}

// Get a subset of user-events for dashboard. Limit not working though
const getEvent = (args) => {
    var url = API_URL

    if (args && args["id"]) {
        url += args["id"] + "/"
    }
    if (args && args["details"]) {
        url += "details/"
    }

    if (args && args["any_event"]) {
        url += "?any_event=True"
    }

    return axios
        .get(url, {
            headers: {
                "X-CSRFToken": getCookie("csrftoken"),
            },
        })
        .then((response) => {
            return response.data
        })
}

// For non-users
const getAnonEvent = (args) => {
    var url = "/search_homepage_events/"

    if (args && args["id"]) {
        url += args["id"] + "/"
    }

    return axios
        .get(url, {
            headers: {
                "X-CSRFToken": getCookie("csrftoken"),
            },
        })
        .then((response) => {
            return response.data
        })
}

// Template events
const filterEvents = (args) => {
    var url = API_URL + "filter/"

    if (args && args["range"]) {
        url += "?range=" + args["range"]
    }

    return axios
        .get(url, {
            headers: {
                "X-CSRFToken": getCookie("csrftoken"),
            },
        })
        .then((response) => {
            return response.data
        })
}

const createEvent = (newEvent) => {
    let url = API_URL + "?timespan=all"
    let headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRFToken": window.csrf_token,
    }

    return axios
        .post(url, JSON.stringify({ event: newEvent }), { headers: headers })
        .then((response) => {
            return response.data
        })
}

const updateEvent = (args) => {
    let url = API_URL + args["id"] + "/"
    let headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRFToken": window.csrf_token,
    }

    return axios.patch(url, JSON.stringify(args["data"]), { headers: headers }).then((response) => {
        return response.data
    })
}

const deleteEvent = (id) => {
    let url = API_URL + id + "/"
    let headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRFToken": window.csrf_token,
    }

    return axios.delete(url, { headers: headers }).then((response) => {
        return response.data
    })
}

const copyEvent = (id) => {
    let url = API_URL + id + "/copy/"
    let headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRFToken": window.csrf_token,
    }

    return axios.post(url, "", { headers: headers }).then((response) => {
        return response.data
    })
}

const EventService = {
    getEvent,
    getAnonEvent,
    getEventsByParameters,
    filterEvents,
    createEvent,
    updateEvent,
    deleteEvent,
    copyEvent,
}

export default EventService

import axios from "axios";
import {getCookie} from "../../utils/index";

const API_URL = '/api/v1'

// Get types for events
const getEventTypes = () => {
    var url = API_URL + '/eventtypes/'
    return axios
        .get(url, {
            headers: {
                'X-CSRFToken': getCookie("csrftoken"),
            }
        })
        .then((response) => {
            return response.data;
        });
};

const TypesService = {
    getEventTypes,
};

export default TypesService;
import React, { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"

import { useGetEventsQuery } from "../../api/events-list"
import ErrorBoundary from "../error-boundary"
import { getEventCategories, getServiceCategories } from "../../slices/categories"
import { getUserFestivals } from "../../slices/festivals"
import EventOverview from "../event-overview"
import { getEventTypes } from "../../slices/types"
import FestivalOverview from "../festival-overview"
import ServiceOverview from "../service-overview"

const UserManager = (props) => {
    const dispatch = useDispatch()

    const user = useSelector((state) => state.user.info)
    const festivals = useSelector((state) => state.festivals)
    const { data: currentEvents, isFetching: isFetchingCurrentEvents } = useGetEventsQuery({
        group: "now",
        owner: true,
    })
    const { data: futureEvents, isFetching: isFetchingFutureEvents } = useGetEventsQuery({
        group: "future",
        owner: true,
    })

    const events = useMemo(() => {
        const current = currentEvents ? currentEvents.results : []
        const future = futureEvents ? futureEvents.results : []
        return [...current, ...future]
    }, [currentEvents, futureEvents])

    const isFetching = useMemo(
        () => isFetchingCurrentEvents || isFetchingFutureEvents,
        [isFetchingCurrentEvents, isFetchingFutureEvents]
    )

    useEffect(() => {
        dispatch(getUserFestivals())
        dispatch(getServiceCategories())
        dispatch(getEventCategories())
        dispatch(getEventTypes())
    }, [])

    return (
        <ErrorBoundary>
            <div className="event-manager column">
                {user && user.permissions && user.permissions.festival_admin && (
                    <FestivalOverview
                        title={"Mínar hátíðir"}
                        newFestivalButton={true}
                        showDetails={false}
                        festivals={festivals.byDate.all}
                        authenticated={props.authenticated}
                    />
                )}
                <EventOverview
                    events={events}
                    fetching={isFetching}
                    renderType={"param"}
                    title={"Mínir viðburðir"}
                    newEventButton={true}
                />
                <ServiceOverview title={"Mínir staðir"} />
            </div>
        </ErrorBoundary>
    )
}

export default UserManager

import { configureStore, combineReducers } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query"
import thunkMiddleware from "redux-thunk"
import { createLogger } from "redux-logger"

import eventsReducer from "./slices/events"
import festivalReducer from "./slices/festivals"
import guiReducer from "./slices/gui"
import miscReducer from "./slices/misc"
import categoryReducer from "./slices/categories"
import typesReducer from "./slices/types"
import guestReducer from "./slices/guest"
import userReducer from "./slices/user"
import addressReducer from "./slices/address"
import serviceReducer from "./slices/services"
import tagReducer from "./slices/tags"
import festivaladminReducer from "./slices/festivaladmin"

import { api } from "./api"

const loggerMiddleware = createLogger()

const reducers = combineReducers({
    categories: categoryReducer,
    addressSearch: addressReducer,
    guest: guestReducer,
    events: eventsReducer,
    festivals: festivalReducer,
    services: serviceReducer,
    gui: guiReducer,
    misc: miscReducer,
    tags: tagReducer,
    types: typesReducer,
    user: userReducer,
    festivaladmins: festivaladminReducer,
    [api.reducerPath]: api.reducer,
})

export const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat([thunkMiddleware, api.middleware])
            .concat(
                process.env.NODE_ENV !== "production" ? loggerMiddleware : []
            ),
    devTools: process.env.NODE_ENV !== "production",
})

setupListeners(store.dispatch)

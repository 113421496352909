import axios from "axios";

const API_URL = '/stadfong/'

// Get address suggestions for input
const fetchAddressSearch = (addr) => {
    var url = API_URL + '?address=' + addr
    return axios
        .get(url)
        .then((response) => {
            return response.data;
        });
};



const AddressService = {
    fetchAddressSearch,
};

export default AddressService;

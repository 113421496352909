import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { addSelected, clearAddressList, fetchAddressSearch } from '../../slices/address'


export default function AddressSearch({handleChange, ...props}){
	const [text, setText] = useState("")
	const [shouldSearch, setShouldSearch] = useState(true)
    const formattedAddresses = useSelector((state) => state.addressSearch.searchItems)
    const searchResults = useSelector((state) => state.addressSearch.results)
    const selectedItem = useSelector((state) => state.addressSearch.selectedItem)
	const dispatch = useDispatch()
	
	useEffect(() => {
        if(text.length > 2){
            if(shouldSearch){
                dispatch(fetchAddressSearch(text));
            } else {
                dispatch(clearAddressList())
            }
        } else {
            setShouldSearch(true)
        }
	}, [text])

    // Keep previously selected address on form-submit-error (re-render)
    useEffect(() => {
        if(selectedItem){
            setShouldSearch(false)
            setText(selectedItem.address)
        }
    }, [selectedItem])

    useEffect(() => {
        slideLabelsUp();
    }, [])
	
    
    function suggestionSelected(value){
        searchResults.map((address) => {
            if (value === address.search_string) {
                const lat = parseFloat((address.lat).replace(",", "."));
                const lng = parseFloat((address.lon).replace(",", "."));
                handleChange({address: value, street: address.address_nf + " " + address.husnr, city: address.stadur, postal: address.pnr, lat, lng})
                dispatch(addSelected({address: value, street: address.address_nf + " " + address.husnr, city: address.stadur, postal: address.pnr, lat, lng}));
            }
        })
        setShouldSearch(false)
        setText(value)
	}

	const renderSuggestions = (ev) => { 
		const items = formattedAddresses;

        if (items.length === 0) {
            return null;
        }

        return (
            <ul className="px-3">{items.map((item, key) => <li key={key} onClick={() => suggestionSelected(item)}>{item}</li>)}</ul>
        )  
	}
	

    return (
        <div className="autoComplete-text input-container dropdown-list">
            <label htmlFor="search" className={`${text ? 'animate-label' : ''}`} {...props}>Heimilisfang</label>
            <input type="text" id="search" value={text} autoComplete="off" onChange={(e) => {setText(e.target.value); setShouldSearch(true);}}/>
            {renderSuggestions()}
        </div>
    )
}

AddressSearch.defaultProps = {}

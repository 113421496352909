import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getUser, resetUserPass } from "../../slices/user";
import { useForm } from "react-hook-form";


export default function Settings(props){
    const dispatch = useDispatch()
    const info = useSelector((state) => state.user.info)
    const [currEmail, setCurrEmail] = useState('')
    const [success, setSuccess] = useState(false)
    const { register, unregister, setValue, getValues, handleSubmit, control, reset, resetField, watch, setError, clearErrors, formState: { errors } } = useForm()

    useEffect(() => {
        dispatch(getUser())
    }, [])

    useEffect(() => {
        setValue('email', info.email)
        setCurrEmail(info.email)
    }, [info])


    function onSubmit(data){
        if(data['new_password1'] && data['new_password2']){
            dispatch(resetUserPass(data)).then((resp) => {
                if(resp.payload.status == 'invalid'){
                    Object.keys(resp.payload.errors).map((errKey) => {
                        console.log("Err: ", resp.payload.errors[errKey][0])
                        setError(errKey, {type: 'invalid', message: resp.payload.errors[errKey][0]})
                    })
                } else {
                    reset()
                    setSuccess(true)
                }
            })
        }
    }

    return (
        <form className="container login-page" onSubmit={handleSubmit(onSubmit)}>
            <div className="row mb-5 justify-content-center"> 
                <div class="col-12 col-md-8 col-lg-6 login-form">
                    <h2 class="mb-5">Stillingar</h2>
                    <p>Sláðu inn gamla lykilorðið og veldu svo nýtt lykilorð. Þú verður að staðfesta nýtt lykilorð með því að slá það inn aftur í síðasta reitinn.</p>
                    <div class="input-container">
                        <label for="old_password" class="sr-only">Núverandi lykilorð</label>
                        <input {...register('old_password', { required: {value: true, message: 'Vinsamlegast fyllið út þennan reit' }})} type="password" name="old_password" id="old_password" required placeholder="Núverandi lykilorð"/>
                    </div>
                    <div className="input-container">
                        {errors['old_password'] ? <div className="alert alert-danger d-block error-msg">{errors['old_password'].message}</div> : null}
                        <label for="new_password1" class="sr-only">Nýtt lykilorð</label>
                        <input {...register('new_password1', { required: false })} type="password" name="new_password1" id="new_password1" required placeholder="Nýtt lykilorð"/>
                    </div>
                    <div className="input-container">
                        <label for="new_password2" class="sr-only">Endurtaka nýtt lykilorð</label>
                        <input {...register('new_password2', { required: false })} type="password" name="new_password2" id="new_password2" required placeholder="Endurtaka nýtt lykilorð"/>
                        {errors['new_password2'] ? <div className="alert alert-danger d-block error-msg">{errors['new_password2'].message}</div> : null}
                    </div>
                    <div className="mt-5">
                        { success && <div className="alert alert-info">Breyting tókst</div>}
                        <button className="primary smaller" type="submit">Vista</button>
                    </div>
                </div>
            </div>
        </form>
    )
}

import React from 'react';
// import accounting from 'accounting'

export function parseJSON(response) {
  return response.json();
}

export const handleNetworkError = (error) => {
  if (window.Raven !== undefined) {
    Raven.captureException(error)
  } else {
    throw (error)
  }
}

export const Spinner = (props) => <i className="spinner fa fa-spinner fa-pulse"></i>;


export const connectWithStore = (store, WrappedComponent, ...args) => {
  var ConnectedWrappedComponent = connect(...args)(WrappedComponent)
  return function (props) {
    return <ConnectedWrappedComponent {...props} store={store} />
  }
}

export const formatKennitala = (value) => {
  return `${value.substring(0, 6)}-${value.substring(6, 10)}`
}

export const getUrlVars = () => {
  var vars = {};
  var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
    vars[key] = value;
  });
  return vars;
}


// export const formatMoney = (num) => {
//   return accounting.formatMoney(+num, '', 2, ".", ",")
// }

export const getUrlParam = (parameter, defaultvalue) => {
  var urlparameter = defaultvalue;
  if (window.location.href.indexOf(parameter) > -1) {
    urlparameter = getUrlVars()[parameter];
  }
  return urlparameter;
}



export const removeDuplicates = (myArr, prop) => {
  return myArr.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
  });
}

export const removeElementFromArray = (arr, el) => {
  var tmpArr = [...arr]
  const index = tmpArr.indexOf(el);
  if (index > -1){
    tmpArr.splice(index, 1)
  }
  return tmpArr
}

export const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}


export const getCookie = (name) => {
  var cookieValue = null;
  if (document.cookie && document.cookie != '') {
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?

      if (cookie.substring(0, name.length + 1) == (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

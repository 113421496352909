import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from 'react-datetime';
import moment from 'moment'
// import translateLabel from 'react-rrule-generator/src/lib/utils/translateLabel'
// import TimePicker from 'rc-time-picker';
import {Tab, Tabs} from 'react-bootstrap';

import { CalendarIcon } from '../svg'
import { DATE_FORMAT } from '../../constants'

export default function FestivalDatePicker(props) {
    const { onChange, startDate, endDate, type } = props
    // const [key, setKey] = useState(type)

    const DatePicker = ({value, placeholder, name}) => {
        return (
            <>
            <DateTime
                    value={value}
                    timeFormat={false}
                    viewMode="days"
                    dateFormat={DATE_FORMAT}
                    locale='is'
                    inputProps={{readOnly:true, placeholder: placeholder}}
                    closeOnSelect
                    closeOnTab
                    // required
                    showYearPicker
                    onChange={(inputDate) => {
                        const editedFestival = {
                            value: moment(inputDate).format(DATE_FORMAT),
                            name: name,
                        };
                        onChange(editedFestival)
                        // this.handleChangeDate(editedFestival);
                    }}
                />
                <CalendarIcon />
            </>
        )
    }

    return (<>
            <Tabs defaultActiveKey={type} id="festival-date-tabs" className="position-relative border-0 flex-nowrap">
                <Tab eventKey="single" title="Einn dagur">
                    <div className="col date-picker-container position-relative">
                        <DatePicker value={startDate} placeholder="Dagsetning" name='singleDate'></DatePicker>
                    </div>
                </Tab>
                <Tab eventKey="multiple" title="Tímabil">
                    <div className="col date-picker-container position-relative">
                        <DatePicker value={startDate} placeholder="Dagsetning frá" name='startDate'></DatePicker>
                    </div>
                    <div className="col date-picker-container position-relative mt-3 mt-lg-0">
                        <DatePicker value={endDate} placeholder="Dagsetning til" name='endDate'></DatePicker>
                    </div>
                </Tab>
            </Tabs>
        </>
    )

}

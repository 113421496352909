import React from 'react';
import ReactDOM from 'react-dom';

import { ThinCloseIcon } from '../svg';

const Modal = ({ children, isShowing, hide, customClass}) => isShowing ? ReactDOM.createPortal(
  <React.Fragment>
    <div className="hvirfill-modal-overlay"/>
    <div className={`hvirfill-modal-wrapper ${customClass}`} aria-modal aria-hidden tabIndex={-1} role="dialog">
      <div className="hvirfill-modal position-relative">
        <div className="hvirfill-modal-header">
          <button type="button" className="hvirfill-modal-close-button position-absolute" data-dismiss="modal" aria-label="Close" onClick={hide}>
            Loka
            <ThinCloseIcon />
          </button>
        </div>
        <div className="hvirfill-modal-body">
          {children}
        </div>
      </div>
    </div>
  </React.Fragment>, document.body
) : null;

export default Modal;
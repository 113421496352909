import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector, batch } from 'react-redux'

import moment from 'moment'
import { ClockIcon, LocationIcon, WebIcon, FbIcon, CloseIcon, BackArrowIcon } from '../../components/svg';
import { removeEditEvent } from '../../slices/events';
import { getEvent, getAnonEvent, getParamEvents, editEvent } from '../../slices/events'
import EventMap from '../../components/event-card/map';
import { TIME_FORMAT } from '../../constants'

// FrontPage event details
export default function FPEventDetails(props){
    const { auth } = props
    const event = useSelector((state) => state.events.event)
    const user = useSelector((state) => state.user.info)
    const params = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    console.log("Authenticated: ", auth)

    useEffect(() => {
        dispatch(getAnonEvent({'id': params.id}))
    }, [])

    const doEditEvent = (ev) => { 
        // dispatch(getEvent({ 'id': ev.tmpl_id }))
        // dispatch(editEvent({ 'id': ev.tmpl_id }))
		navigate(`/home/events/${ev.tmpl_id}/edit`)
	}

    // Dates are stored differently for Template and non template events
	function getDateTimes(ev){
		let startDate = ''
		let endDate = ''
		let startTime = ''
		let endTime = ''
		if(event.template){
			if(ev.occurrence == 'single' || ev.occurrence == 'range'){
				startDate = moment(ev.dates[0].start).format('D. MMMM YYYY')
				endDate = moment(ev.dates[0].end).format('D. MMMM YYYY')
				startTime = moment(event.dates[0].start).format(TIME_FORMAT)
				endTime = moment(event.dates[0].end).format(TIME_FORMAT)
	
			} else if(ev.occurrence == 'multiple'){
				startDate = moment(ev.dates[0].start).format('D. MMMM YYYY')
				return <div className="">
						<p>{startDate}</p>
						<p>{event.dates.length} aðrar dagsetningar skráðar</p>
					</div> // This should be a link
			}
		} else {
			startDate = moment(ev.start).format('D. MMMM YYYY')
			endDate = moment(ev.end).format('D. MMMM YYYY')
			startTime = moment(event.start).format(TIME_FORMAT)
			endTime = moment(event.end).format(TIME_FORMAT)
		}

		return (<>
			<p>Tímasetning</p>
			{ startDate == endDate? 
				<p>{startDate}</p>
				:
				<p>{startDate} - {endDate}</p>
			}
			<p>{startTime} - {endTime}</p>
			</>
		)
	}

	return (
        <>	
            <div className='container back-btn event-view-back-btn'>
                <div className="row justify-content-center"> 
                    <div className='col-sm-10 col-11 d-flex align-items-center'>
                        <BackArrowIcon />
                        <button onClick={() => { dispatch(removeEditEvent()); navigate(-1)}} className="ml-2">Til baka</button>
                    </div>
                </div>
            </div>
            { event && 
            <>
                { auth && event.template && 
                    <div className="row">
                        <div className="col-12 position-relative mt-50">
                            {((user && user.permissions && user.permissions.superuser) || (+event.owner === +user.id)) && 
                                <button className="secondary thin position-absolute" style={{ left: '50%', transform: 'translateX(-50%', top: '-140px', height: '58px' }} onClick={() => doEditEvent(event)}>Breyta viðburði</button>
                            }
                        </div>
                    </div>
                }
                <div className="container event-view">
                    <div className='row justify-content-center'>
                        <div className='col-10'>
                            <h1>{event.title}</h1>
                        </div>
                    </div>
                    <div className='row time mb-5 justify-content-center'>
                        <div className='col-md-5 col-12 border-top border-bottom py-4'>
                            <ClockIcon />
                            {getDateTimes(event)}
                        </div>
                        <div className='col-md-5 col-12 border-top border-bottom py-4'>
                            <LocationIcon />
                            <p>Staðsetning</p>
                            <p>{event.place}</p>
                            <p>{event.formatted_address}</p>
                        </div>
                    </div>
                    <div className="row links justify-content-center">
                        { event.media &&     
                            <div className='col-10 d-flex'>
                                {event.media.tickets && 
                                <a href={event.media.tickets} className="d-flex align-items-center mr-5">
                                    <WebIcon />
                                    <h5 className="ml-3">Miðasala</h5>
                                </a>
                                }
                                { event.media.website &&
                                <a href={event.media.website} className="d-flex align-items-center mr-5" target="_blank">
                                    <WebIcon />
                                    <h5 className="ml-3">Vefsíða</h5>
                                </a>
                                }
                                { event.media.facebook && 
                                <a href={event.media.facebook} className="d-flex align-items-center mr-5" target="_blank">
                                    <FbIcon />
                                    <h5 className="ml-3">Facebook</h5>
                                </a>
                                }
                            </div> 
                        }
                    </div>
                    <div className='row justify-content-center'>
                        <div className='col-10'>
                            { event.event_image && 
                                <img src={event.event_image} className="event-img w-100 mb-5"/>
                            }
                            <div>
                                <div className="text">{ event.description }</div>
                                <br></br>
                                <div className="text">{ event.detailed_description }</div>
                            </div>
                        </div>
                    </div>
            
                </div>
                    { event.latitude && event.longitude && 
                        <div className="map-container container">
                            <div className='row justify-content-center'>
                                <EventMap event={event} location={[event.latitude, event.longitude]} google={window.google}></EventMap>
                            </div>
                        </div>
                    }
            </>
            }
        </>
    )

}

FPEventDetails.defaultProps = {}
import { useEffect, useCallback, useRef, useState } from "react"

export const useOnLostFocus = (ref, handler) => {
    const handleBlur = useCallback(
        (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                handler()
            }
        },
        [ref, handler]
    )

    const handleKeyDown = useCallback(
        (event) => {
            if (event.key === "Escape") {
                handler()
            }
        },
        [handler]
    )

    useEffect(() => {
        document.addEventListener("mousedown", handleBlur)
        document.addEventListener("touchstart", handleBlur)
        document.addEventListener("keydown", handleKeyDown)

        return () => {
            document.removeEventListener("mousedown", handleBlur)
            document.removeEventListener("touchstart", handleBlur)
            document.removeEventListener("keydown", handleKeyDown)
        }
    }, [ref, handleBlur, handleKeyDown])
}

export const useDebounce = (fn, delay) => {
    const timer = useRef()

    return useCallback(
        (...args) => {
            clearTimeout(timer.current)
            timer.current = setTimeout(() => {
                fn(...args)
            }, delay)
        },
        [fn, delay]
    )
}

export const useDebouncedValue = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value)

    const debouncedSetValue = useDebounce(setDebouncedValue, delay)

    useEffect(() => {
        debouncedSetValue(value)
    }, [value, debouncedSetValue])

    return debouncedValue
}

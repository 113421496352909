import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { capitalize } from '../../utils/index';
import Spinner from '../spinner';
import EventCard from '../event-card';
// Landing page  (non-template) events
import { getEvents } from '../../slices/guest';

// Container component for events.
export default function EventOverview(props) {
   const { renderType, title, newEventButton, fetchMoreButton, events, fetching } = props;
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const [festivalEvents, setFestivalEvents] = useState('');
   const [landingOffset, setLandingOffset] = useState(1); // offset 0 already fetched
   const selectedFestival = useSelector((state) => state.guest.selectedFestival);

   // Mix festival events so they fit data for EventOverview
   useEffect(() => {
      function addSingle(arr, date, ev) {
         if (arr[date]) {
            arr[date].push(ev);
         } else {
            arr[date] = [ev];
         }
      }

      function sortObj(obj) {
         return Object.keys(obj)
            .sort()
            .reduce(function (result, key) {
               result[key] = obj[key];
               return result;
            }, {});
      }

      if (selectedFestival.events) {
         let tmp = {};
         let festStart = moment(selectedFestival.from_date, 'YYYY-MM-DD');
         let festEnd = moment(selectedFestival.to_date, 'YYYY-MM-DD');
         selectedFestival.events.map((ev) => {
            let date = moment(ev.start).format('YYYY-MM-DD');
            if (moment(date).isSameOrAfter(festStart) && moment(date).isSameOrBefore(festEnd)) {
               addSingle(tmp, date, ev);
            }
         });
         setFestivalEvents(sortObj(tmp));
      }
   }, [selectedFestival]);

   const RenderByDate = (props) => {
      let { date, events } = props;
      let d = moment(date, 'YYYY-MM-DD');

      return (
         <>
            {date && (
               <>
                  <hr className='w-100 border-0 m-0' />
                  <a className='col-lg-3 col-md-4 col-12 date-info-wrapper' key={date}>
                     <div className='date-information position-absolute left-0'>
                        <h3 className='bold'>{capitalize(d.format('dddd'))}</h3>
                        <h3 className='bold'>{d.format('D. MMMM')}</h3>
                        <h3>{d.format('YYYY')}</h3>
                     </div>
                  </a>
               </>
            )}
            {events &&
               events.map((ev) => {
                  return <EventCard key={ev.id} event={ev}></EventCard>;
               })}
         </>
      );
   };

   // For unauth only (landing)
   const fetchAdditionalFrontpageEvents = () => {
      dispatch(getEvents({ offset: landingOffset }));
      setLandingOffset(landingOffset + 1);
   };

   return (
      <div className='event-overview container pt-120 pb-80'>
         <div className='row justify-content-center justify-conent-sm-start'>
            <div className='col-sm-12 col-11 d-flex'>
               <div>
                  <h1>{title}</h1>
               </div>
               <div className='ml-auto'>
                  {newEventButton && (
                     <button className='primary smaller' onClick={() => navigate('/events/new')}>
                        Skrá viðburð
                     </button>
                  )}
               </div>
            </div>
         </div>
         <div className='event-container row justify-content-center justify-content-md-start position-relative'>
            {fetching ? (
               <Spinner />
            ) : (
               <>
                  {renderType == 'date' &&
                     events &&
                     Object.entries(events).map((entry) => {
                        return <RenderByDate date={entry[0]} events={entry[1]}></RenderByDate>;
                     })}
                  {/* Show current events only */}
                  {renderType == 'param' &&
                     events &&
                     events.map((ev, index) => {
                        if (index < 8) {
                           return <EventCard key={ev.id} event={ev}></EventCard>;
                        }
                     })}
                  {renderType == 'festival' &&
                     festivalEvents &&
                     Object.entries(festivalEvents).map((entry) => {
                        return <RenderByDate date={entry[0]} events={entry[1]}></RenderByDate>;
                     })}
               </>
            )}
         </div>
         {!fetching && newEventButton && (
            <div className='d-flex justify-content-center'>
               <button className='secondary thin' onClick={() => navigate('/events')}>
                  Sjá alla viðburði
               </button>
            </div>
         )}
         {!fetching && fetchMoreButton && (
            <div className='d-flex justify-content-center'>
               <button className='secondary thin' onClick={fetchAdditionalFrontpageEvents}>
                  Sækja fleiri viðburði
               </button>
            </div>
         )}
      </div>
   );
}

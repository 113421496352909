import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import ReactTags from 'react-tag-autocomplete'
import { parseJSON } from '../../utils';
import { getTags } from '../../slices/tags'

export default function TagAutocomplete(props) {
    const { updateTags, predefinedTags } = props
    const dispatch = useDispatch()
    const [tags, setTags] = useState([])
    const suggestions = useSelector((state) => 
        state.tags.results.map((item, index) => {
            return { id: index + 1, name: item }
        })
    )

    useEffect(() => {
        if(predefinedTags){
            let tmp = predefinedTags.map((item) => {
                return  { name: item }
            })
            setTags(tmp)
        }
    }, [predefinedTags])

    function handleInputChange(i){
        if(i.length > 2){
            dispatch(getTags({"q": i}))
        } 
    }

    function handleAddition(a){
        let tmp = [...tags]
        tmp.push(a)
        updateTags(tmp)
    }

    function handleDelete(d){
        if(d > -1){
            let tmp = [...tags]
            tmp.splice(d, 1)
            updateTags(tmp)
        }
    }

    return (
        <div className="row">
          <div className="col-12 mb-4"><p className="smaller border-bottom pb-4">Með merki (e. tags) er átt við sértækar upplýsingar um viðburðinn. Dæmi um merki við viðburð er t.d. matur, pop-up. Birting merkja fer eftir framenda á því vefsvæði sem viðburður birtist á. Merki getur gagnast vel við leitarvélarbestun. Ekki er nauðsynlegt að setja merki á viðburð.</p></div>
          <div className='col-12'>
            <ReactTags
              tags={tags}
              suggestions={suggestions}
              autofocus={false}
              handleDelete={handleDelete}
              handleAddition={handleAddition}
              handleInputChange={handleInputChange}
              maxSuggestionsLength={10}
              allowNew={true}
              placeholder="Sláðu inn merki (tags) (valkvætt)"/>
          </div>
        </div>
      )
}
import axios from "axios";
import {getCookie} from "../../utils/index";

const API_URL = ''

// Get festival admins 
const getFestivalAdmins = () => {
    var url = API_URL + '/festivaladmins/'
    return axios
        .get(url, {
            headers: {
                'X-CSRFToken': getCookie("csrftoken"),
            }
        })
        .then((response) => {
            return response.data;
        });
};

const FestivaladminsService = {
    getFestivalAdmins,
};

export default FestivaladminsService;
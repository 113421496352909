
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CategoryService from "../../api/categories";
import moment from 'moment'

export const getEventCategories = createAsyncThunk(
  "categories/get",
  async (args, thunkAPI) => {
    try {
      const response = await CategoryService.getEventCategories(args);
      //thunkAPI.dispatch(setMessage(response.data.message));
      return response;
    } catch (error) {
        console.log("Error: ", error)
        const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      //thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getServiceCategories = createAsyncThunk(
	"servicecategories/get",
	async (args, thunkAPI) => {
		try {
			const response = await CategoryService.getServiceCategories(args);
			//thunkAPI.dispatch(setMessage(response.data.message));
			return response;
		} catch (error) {
			console.log("Error: ", error)
			const message =
				(error.response &&
					error.response.data &&
					error.response.data.message) ||
				error.message ||
				error.toString();
			//thunkAPI.dispatch(setMessage(message));
			return thunkAPI.rejectWithValue();
		}
	}
);

const initialState = {
    fetching: false,
    events: [],
    services: [],
};


const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  extraReducers: {
    [getEventCategories.pending]: (state, action) => {
        state.fetching = true;
    },
    [getEventCategories.fulfilled]: (state, action) => {
        state.fetching = false;
        state.events = action.payload;
    },
    [getEventCategories.rejected]: (state, action) => {
        state.error = "Ekki tókst að sækja flokka";
    },
    [getServiceCategories.pending]: (state, action) => {
        state.fetching = true;
    },
    [getServiceCategories.fulfilled]: (state, action) => {
        state.fetching = false;
        state.services = action.payload.categories;
    },
    [getServiceCategories.rejected]: (state, action) => {
        state.error = "Ekki tókst að sækja flokka";
    },
  },
});

const { reducer } = categoriesSlice;
export default reducer;
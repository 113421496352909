import React from 'react'


export const CloseIcon = (props) => {
    return (
        <svg viewBox="0 0 16 17"><defs></defs><path fill="#404040" fillRule="nonzero" d="M14.2361.5858L16 2.3496 9.763 8.5858 16 14.8219l-1.7639 1.7639L8 10.3488l-6.2361 6.237L0 14.8219l6.236-6.2361L0 2.3496 1.7639.5858 8 6.8218l6.2361-6.236z"></path></svg>
    )
}

export const ThinCloseIcon = (props) => {
    return (
        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
            <g id="Desktop-HD-1920" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Vidburður-modal" transform="translate(-1120.000000, -103.000000)" fill="#727477" fillRule="nonzero">
                    <path d="M1142.50777,103.256027 C1142.84913,102.914658 1143.4026,102.914658 1143.74397,103.256027 C1144.08534,103.597396 1144.08534,104.150865 1143.74397,104.492235 L1133.236,115 L1143.74397,125.507765 C1144.08534,125.849135 1144.08534,126.402604 1143.74397,126.743973 C1143.4026,127.085342 1142.84913,127.085342 1142.50777,126.743973 L1132,116.236 L1121.49223,126.743973 C1121.17931,127.056895 1120.68817,127.082972 1120.34554,126.822203 L1120.25603,126.743973 C1119.91466,126.402604 1119.91466,125.849135 1120.25603,125.507765 L1130.764,115 L1120.25603,104.492235 C1119.91466,104.150865 1119.91466,103.597396 1120.25603,103.256027 C1120.5974,102.914658 1121.15087,102.914658 1121.49223,103.256027 L1132,113.764 Z" id="Combined-Shape"></path>
                </g>
            </g>
        </svg>
    )
}

export const SearchIcon = (props) => {
    return (
        <svg className="mr-3" width="16px" height="16px" viewBox="0 0 16 16" version="1.1">
            <g id="Desktop-HD-1920" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Forsíða-" transform="translate(-1017.000000, -62.000000)" fill="#010B06" fillRule="nonzero">
                    <g id="Header-normal" transform="translate(80.000000, 42.000000)">
                        <g id="Search" transform="translate(937.000000, 20.000000)">
                            <path d="M16,14.526303 L11.4736727,10 C12.2104242,8.9473697 12.6315152,7.68421818 12.6315152,6.31578182 C12.6315152,2.84210909 9.78947879,0 6.31578182,0 C2.84210909,0 0,2.84210909 0,6.31578182 C0,9.78947879 2.84210909,12.6315152 6.31578182,12.6315152 C7.68421818,12.6315152 8.9473697,12.2104242 10,11.4736727 L14.526303,16 L16,14.526303 Z M2.10526061,6.31578182 C2.10526061,4 4,2.10526061 6.31578182,2.10526061 C8.63158788,2.10526061 10.5263273,4 10.5263273,6.31578182 C10.5263273,8.63158788 8.63158788,10.5263273 6.31578182,10.5263273 C4,10.5263273 2.10526061,8.63158788 2.10526061,6.31578182 Z" id="Shape"></path>
                        </g>
                    </g>
                </g>
            </g>
      </svg>
    )
}

export const ClipIcon = (props) => {
    return (
        <svg width="115px" height="105px" viewBox="0 0 115 105" version="1.1">
            <g id="Desktop-HD-1920" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="hd-Ný-hátíð" transform="translate(-769.000000, -1177.000000)">
                    <g id="Group-22-Copy" transform="translate(80.000000, 937.000000)">
                        <g id="Group-19" transform="translate(689.000000, 240.000000)">
                            <rect id="Rectangle" stroke="#C7CBD0" fillOpacity="0.5" fill="#D8D8D8" x="0.5" y="18.5" width="114" height="69"></rect>
                            <rect id="Rectangle" stroke="#C7CBD0" fillOpacity="0.5" fill="#D8D8D8" x="16.5" y="0.5" width="82" height="104"></rect>
                            <rect id="Rectangle" stroke="#0376E1" x="16.5" y="18.5" width="82" height="69"></rect>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export const CalendarIcon = (props) => {
    return (
        <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
            <g id="Desktop-HD-1920" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="hd-Ný-hátíð" transform="translate(-623.000000, -797.000000)" fill="#727477">
                    <g id="Group-2" transform="translate(80.000000, 780.000000)">
                        <g id="Group-15" transform="translate(522.000000, 12.000000)">
                            <path d="M41,23.5 C41,24.3284271 40.3284271,25 39.5,25 L22.5,25 C21.6715729,25 21,24.3284271 21,23.5 L21,8.5 C21,7.67157288 21.6715729,7 22.5,7 L25,7 L25,5 L28,5 L28,7 L34,7 L34,5 L37,5 L37,7 L39.5,7 C40.3284271,7 41,7.67157288 41,8.5 L41,23.5 Z M39,11 L23,11 L23,23 L39,23 L39,11 Z" id="Combined-Shape"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export const ClockIcon = (props) => {
    return (
        <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
            <g id="Desktop-HD-1920" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="hd-Nýr-viðburður" transform="translate(-625.000000, -1079.000000)" fill="#727477" fillRule="nonzero">
                    <g id="Group-12" transform="translate(390.000000, 1060.000000)">
                        <path d="M245,19 C250.522847,19 255,23.4771525 255,29 C255,34.5228475 250.522847,39 245,39 C239.477153,39 235,34.5228475 235,29 C235,23.4771525 239.477153,19 245,19 Z M245,21 C240.581722,21 237,24.581722 237,29 C237,33.418278 240.581722,37 245,37 C249.418278,37 253,33.418278 253,29 C253,24.581722 249.418278,21 245,21 Z M246,23.0317314 L246,28.586 L248.542611,31.1283978 L247.128398,32.5426114 L244,29.4142136 L244,23.0317314 L246,23.0317314 Z" id="Combined-Shape-Copy-11"></path>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export const BackArrowIcon = (props) => {
    return (
        <svg width="12px" height="10px" viewBox="0 0 12 10" version="1.1">
            <g id="Desktop-HD-1920" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="hd-Nýr-viðburður-útfyllt-5" transform="translate(-80.000000, -143.000000)" fill="#0376E1" fillRule="nonzero">
                    <path d="M84.8079845,143.849429 L85.9393553,144.9808 L83.304299,147.614429 L91.3,147.615114 L91.3,149.215114 L83.304299,149.214429 L85.9393553,151.849429 L84.8079845,152.9808 L80.242299,148.415114 L84.8079845,143.849429 Z" id="Combined-Shape"></path>
                </g>
            </g>
        </svg>
    )
}

export const LocationIcon = (props) => {
    return (
        <svg width="18px" height="24px" viewBox="0 0 18 24" version="1.1">
            <g id="Desktop-HD-1920" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Vidburður-modal" transform="translate(-733.000000, -367.000000)" fill="#727477">
                    <g id="Group-3" transform="translate(288.000000, 204.000000)">
                        <path d="M453.571429,167.285714 C455.938,167.285714 457.857143,169.204857 457.857143,171.571429 C457.857143,173.938 455.938,175.857143 453.571429,175.857143 C451.204857,175.857143 449.285714,173.938 449.285714,171.571429 C449.285714,169.204857 451.204857,167.285714 453.571429,167.285714 M453.571429,163 C458.305429,163 462.142857,166.837429 462.142857,171.571429 C462.142857,178.042857 453.571429,187 453.571429,187 C453.571429,187 445,177.955429 445,171.571429 C445,166.837429 448.837429,163 453.571429,163 M453.571429,164.714286 C449.784571,164.714286 446.714286,167.784571 446.714286,171.571429 C446.714286,176.753714 453.571429,184.371143 453.571429,184.371143 C453.571429,184.371143 460.428571,176.302 460.428571,171.571429 C460.428571,167.784571 457.358286,164.714286 453.571429,164.714286" id="Fill-1"></path>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export const WebIcon = (props) => {
    return (
        <svg version="1.1" id="Layer_1"  x="0px" y="0px"
                viewBox="0 0 20 20" styles="enable-background:new 0 0 20 20;">
            <polygon id="Fill-1" className="st0" fillRule="evenodd" clipRule="evenodd" fill="#727477" points="2,14 18,14 18,13 2,13 "/>
            <polygon id="Fill-2" className="st0" fillRule="evenodd" clipRule="evenodd" fill="#727477" points="2,7 18,7 18,6 2,6 "/>
            <defs>
                <filter id="Adobe_OpacityMaskFilter" filterUnits="userSpaceOnUse" x="10.5" y="0.3" width="5.2" height="19.3">
                    <feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
                </filter>
            </defs>
            <mask maskUnits="userSpaceOnUse" x="10.5" y="0.3" width="5.2" height="19.3" id="mask-2_00000003790928278849980200000014492626670378872732_">
                <g className="st1" filter="url(#Adobe_OpacityMaskFilter)">
                    <path id="path-1_00000041284924064484140130000010461335287937853591_" className="st2" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M11.5,1.8l0.2,0.4c1.3,2.6,1.9,5.2,1.9,7.8
                        l0,0c0,2.6-0.6,5.2-1.9,7.7l0,0l-0.2,0.4l0.9,0.5l0.2-0.4c1.4-2.7,2.1-5.4,2.1-8.1l0,0c0-2.8-0.7-5.5-2.1-8.3l0,0l-0.2-0.4
                        L11.5,1.8z"/>
                </g>
            </mask>
            <polygon id="Fill-3" className="st3" mask="url(#mask-2_00000003790928278849980200000014492626670378872732_)" fillRule="evenodd" clipRule="evenodd" fill="#727477" points="10.5,19.6 15.7,19.6 15.7,0.3 10.5,0.3 "/>
            <defs>
                <filter id="Adobe_OpacityMaskFilter_00000064341644005294455270000007684470206595553954_" filterUnits="userSpaceOnUse" x="4.5" y="0.3" width="5.2" height="19.3">
                <feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
                </filter>
            </defs>
            <mask maskUnits="userSpaceOnUse" x="4.5" y="0.3" width="5.2" height="19.3" id="mask-4_00000108996562335612797600000010770384332454431899_">
                <g styles="filter:url(#Adobe_OpacityMaskFilter_00000064341644005294455270000007684470206595553954_);">
                    <path id="path-3_00000160873618888512235390000002936567045357839237_" className="st2" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M7.6,1.8C6.2,4.5,5.5,7.3,5.5,10l0,0
                        c0,2.8,0.7,5.5,2.1,8.1l0,0l0.2,0.4l0.9-0.5l-0.2-0.4c-1.3-2.5-1.9-5.1-1.9-7.7l0,0c0-2.6,0.6-5.2,1.9-7.8l0,0l0.2-0.4L7.8,1.3
                        L7.6,1.8z"/>
                </g>
            </mask>
            <polygon id="Fill-6" className="st5" mask="url(#mask-4_00000108996562335612797600000010770384332454431899_)" fillRule="evenodd" clipRule="evenodd" fill="#727477" points="4.5,19.6 9.7,19.6 9.7,0.3 4.5,0.3 "/>
            <defs>
                <filter id="Adobe_OpacityMaskFilter_00000003100728682979027360000005196794593469213575_" filterUnits="userSpaceOnUse" x="-1" y="-1" width="22" height="22">
                <feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
                </filter>
            </defs>
            <mask maskUnits="userSpaceOnUse" x="-1" y="-1" width="22" height="22" id="mask-6_00000048501763549004021630000013516263223364635826_">
                <g styles="filter:url(#Adobe_OpacityMaskFilter_00000003100728682979027360000005196794593469213575_);">
                    <path id="path-5_00000123439279678201115430000016897009549028593809_" className="st2" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M0,10c0,5.5,4.5,10,10,10l0,0
                        c5.5,0,10-4.5,10-10l0,0c0-5.5-4.5-10-10-10l0,0C4.5,0,0,4.5,0,10L0,10z M2,10c0-4.4,3.6-8,8-8l0,0c4.4,0,8,3.6,8,8l0,0
                        c0,4.4-3.6,8-8,8l0,0C5.6,18,2,14.4,2,10L2,10z"/>
                </g>
            </mask>
            <polygon id="Fill-9" className="st7" mask="url(#mask-6_00000048501763549004021630000013516263223364635826_)" fillRule="evenodd" clipRule="evenodd" fill="#727477" points="-1,21 21,21 21,-1 -1,-1 "/>
        </svg>

    )
}

export const FbIcon = (props) => {
    return (
        <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
            <g id="Desktop-HD-1920" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Vidburður-modal" transform="translate(-449.000000, -507.000000)" fill="#727477" fillRule="nonzero">
                    <g id="Group-3" transform="translate(288.000000, 204.000000)">
                        <g id="facebook-app-logo" transform="translate(161.000000, 303.000000)">
                            <path d="M18.8954752,0 L1.10386617,0 C0.494632154,0 0,0.493644207 0,1.10386617 L0,18.8954752 C0,19.5056972 0.494632154,20 1.10386617,20 L10.6823421,20 L10.6823421,12.2548245 L8.07613779,12.2548245 L8.07613779,9.23598762 L10.6823421,9.23598762 L10.6823421,7.00981361 C10.6823421,4.42699071 12.2594349,3.0198248 14.563986,3.0198248 C15.6688402,3.0198248 16.6162814,3.10248304 16.8925772,3.13837845 L16.8925772,5.83810841 L15.2940789,5.83876704 C14.0410327,5.83876704 13.799315,6.43449911 13.799315,7.30817362 L13.799315,9.23499967 L16.7888428,9.23499967 L16.3979451,12.2535072 L13.7989857,12.2535072 L13.7989857,19.9990121 L18.8951459,19.9990121 C19.5050385,19.9990121 20,19.5040506 20,18.8954752 L20,1.10320753 C19.9996707,0.493644207 19.5053678,0 18.8954752,0 Z" id="Path"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export const TicketIcon = (props) => {
    return (
        <svg version="1.1" id="Layer_1" x="0px" y="0px"
             viewBox="0 0 25 15" styles="enable-background:new 0 0 25 15;">
        <path className="st0" fill="#727477" d="M7.8,14.4l0-0.6c0-0.6-0.4-1-0.9-1S6,13.2,6,13.6l0,0.7H2.6c-1.2,0-2.2-1-2.2-2.2V9.1H1c1,0,1.8-1,1.8-1.8
            C2.8,6,2.2,5.4,1,5.4H0.4V2.3c0-1.2,1-2.2,2.2-2.2H6l0,0.6c0,0.4,0.4,0.8,0.9,0.8c0.5,0,0.9-0.4,0.9-0.8l0-0.6l14.6,0
            c1.2,0,2.2,1,2.2,2.2v9.8c0,1.2-1,2.2-2.2,2.2L7.8,14.4z M6.9,11.5c1,0,1.8,0.7,2,1.6l13.5,0c0.6,0,1-0.4,1-1V2.3c0-0.6-0.4-1-1-1
            l-13.5,0c-0.3,0.8-1.1,1.4-2,1.4c-0.9,0-1.7-0.6-2-1.4H2.6c-0.6,0-1,0.4-1,1v1.9C3.1,4.5,4,5.6,4,7.3c0,1.3-0.9,2.7-2.4,3v1.9
            c0,0.6,0.4,1,1,1h2.3C5.1,12.2,5.9,11.5,6.9,11.5z M6.9,10.4c-0.8,0-1.5-0.7-1.5-1.5c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5
            C8.4,9.7,7.7,10.4,6.9,10.4z M6.9,8.6c-0.2,0-0.3,0.1-0.3,0.3c0,0.2,0.1,0.3,0.3,0.3s0.3-0.1,0.3-0.3C7.2,8.7,7.1,8.6,6.9,8.6z
             M6.9,6.4c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5S7.7,6.4,6.9,6.4z M6.9,4.6c-0.2,0-0.3,0.1-0.3,0.3
            s0.1,0.3,0.3,0.3s0.3-0.1,0.3-0.3S7.1,4.6,6.9,4.6z"/>
        </svg>
        
    )
}

export const UserIcon = (props) => {
    return (
        <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 14 16" styles="enable-background:new 0 0 14 16;">
            <path id="User" fill="#727477" d="M7,0c2.3,0,4.2,1.8,4.2,4s-1.9,4-4.2,4S2.8,6.3,2.8,4S4.7,0,7,0z M7,2.1C5.9,2.1,5,3,5,4s0.9,2,2,2s2-0.9,2-2
            S8.1,2.1,7,2.1z M7,8.9c3.9,0,7,3.2,7,7.1h-2.2c0-2.8-2.2-5-4.8-5s-4.8,2.2-4.8,5H0C0,12.1,3.1,8.9,7,8.9z"/>
        </svg>
    )
}

export const PencilIcon = (props) => {
return (
    <svg width="16" height="16">
        <g fill="#4A4A4A" fillRule="nonzero">
        <path id="Edit" className="st0" d="M14.9,1.1c-1.7-1.7-3.7-1-4.7-0.1L0,11.2V16h4.8L15.1,5.7C16,4.8,16.7,2.8,14.9,1.1z M13.4,2.6
            c0.6,0.6,0.4,1.2,0.1,1.5L13,4.7L11.3,3l0.5-0.5C12.2,2.2,12.7,2,13.4,2.6z M3.9,13.8H2.2v-1.7l7.9-7.9l1.7,1.7L3.9,13.8z"/>
        </g>
    </svg>
    )
}

export const EditIcon = (props) => {
return (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 16 16">
    <path id="Edit" fill="#727477" d="M14.9,1.1c-1.7-1.7-3.7-1-4.7-0.1L0,11.2V16h4.8L15.1,5.7C16,4.8,16.7,2.8,14.9,1.1z M13.4,2.6
        c0.6,0.6,0.4,1.2,0.1,1.5L13,4.7L11.3,3l0.5-0.5C12.2,2.2,12.7,2,13.4,2.6z M3.9,13.8H2.2v-1.7l7.9-7.9l1.7,1.7L3.9,13.8z"/>
    </svg>
    )
}

export const CopyIcon = (props) => {
return (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 17 17">
    <path id="Copy" fill="#727477" d="M13.5,0C15.4,0,17,1.6,17,3.5v7c0,1.8-1.3,3.2-3,3.5c0,0,0-0.1,0-0.2l0-0.6c0-0.3,0-0.7,0-0.9l0,1.7
        c0,1.6-1.2,2.9-2.8,3L11,17H3c-1.7,0-3-1.3-3-3V6c0-1.7,1.3-3,3-3h0l0-0.1C3.4,1.2,4.8,0,6.5,0H13.5z M11,5H3C2.4,5,2,5.4,2,6v8
        c0,0.6,0.4,1,1,1h8c0.6,0,1-0.4,1-1V6C12,5.4,11.6,5,11,5z M13.5,1.8h-7C5.7,1.8,5,2.3,4.8,3L11,3c1.7,0,3,1.3,3,3v6.2l0.1,0
        c0.6-0.3,1.1-0.9,1.1-1.6v-7C15.2,2.5,14.5,1.8,13.5,1.8z"/>
    </svg>
    )
}

export const TrashIcon = (props) => {
return (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 17 18">
    <path id="Trash" fill="#727477" d="M8.5,0.2c2.2,0,4,1.6,4.2,3.8l2.8,0c0.6,0,1,0.4,1,1s-0.4,1-1,1H15v9c0,1.7-1.3,3-3,3H5
        c-1.7,0-3-1.3-3-3V6H1.5c-0.6,0-1-0.4-1-1s0.4-1,1-1l2.8,0C4.5,1.9,6.3,0.2,8.5,0.2z M13.5,6h-10v9c0,0.8,0.6,1.4,1.4,1.5l0.1,0h7
        c0.8,0,1.5-0.7,1.5-1.5V6z M9,7v7H8V7H9z M6,7v7H5V7H6z M12,7v7h-1V7H12z M8.5,1.8C7.2,1.8,6,2.7,5.8,4h5.4C11,2.7,9.8,1.8,8.5,1.8z
        "/>
    </svg>
    )
}

export const WheelchairIcon = (props) => {
return (
        <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 24 24">
        <g id="Accessibility-wheelchair-accessible" transform="translate(736.000000, 450.000000)">
            <g id="g3242" transform="translate(12.000000, 12.000000) scale(-1, 1) rotate(-180.000000) translate(-12.000000, -12.000000) ">
                <path id="path3244" fill="#727477" d="M-733.1,450c-1.6,0-2.9,1.3-2.9,2.9v18.3c0,1.6,1.3,2.9,2.9,2.9h18.3c1.6,0,2.9-1.3,2.9-2.9
                    v-18.3c0-1.6-1.3-2.9-2.9-2.9H-733.1z"/>
            </g>
            
                <g id="g3246" transform="translate(12.000000, 12.000000) scale(-1, 1) rotate(-180.000000) translate(-12.000000, -12.000000) translate(1.000000, 1.000000)">
                <path id="path3248" fill="#FFFFFF" d="M-733.7,472c-1.3,0-2.3-1-2.3-2.3v-17.4c0-1.3,1-2.3,2.3-2.3h17.4c1.3,0,2.3,1,2.3,2.3v17.4
                    c0,1.3-1,2.3-2.3,2.3H-733.7z"/>
            </g>
            
                <g id="g3250" transform="translate(10.085755, 4.229456) scale(-1, 1) rotate(-180.000000) translate(-10.085755, -4.229456) translate(8.479447, 2.622822)">
                <path id="path3252" fill="#727477" d="M-734.4,450c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6
                    C-736,450.7-735.3,450-734.4,450"/>
            </g>
            
                <g id="g3254" transform="translate(14.142579, 12.185721) scale(-1, 1) rotate(-180.000000) translate(-14.142579, -12.185721) translate(8.646715, 6.126704)">
                <path id="path3256" fill="#727477" d="M-726.7,450.4c0.3-0.4,0.8-0.6,1.3-0.3c0.4,0.3,0.6,0.8,0.3,1.3l-2.7,4.7
                    c-0.2,0.3-0.5,0.4-0.8,0.5h-4l0,1.2h2.9c0.4,0,0.7,0.3,0.7,0.7c0,0.4-0.3,0.7-0.7,0.7h-3l-0.1,1.4c-0.1,0.8-0.8,1.5-1.6,1.5
                    c-0.9,0-1.5-0.8-1.5-1.6l0.3-4.9c0.1-0.9,0.8-1.5,1.6-1.5h5.2L-726.7,450.4z"/>
            </g>
            
                <g id="g3258" transform="translate(10.849582, 15.323217) scale(-1, 1) rotate(-180.000000) translate(-10.849582, -15.323217) translate(5.081914, 9.752179)">
                <path id="path3260" fill="#727477" d="M-730,451.3c-2.6,0-4.8,2.1-4.8,4.8c0,1.4,0.7,2.7,1.7,3.6l-0.1,1.5c-1.7-1.1-2.9-2.9-2.9-5.1
                    c0-3.3,2.7-6,6-6c2.5,0,4.6,1.5,5.5,3.6l-0.8,1.5C-725.8,452.9-727.7,451.3-730,451.3"/>
            </g>
        </g>
        </svg>
    )
}


export const EllipsisVerticalIcon = (props) => {
   return (
      <svg
         xmlns='http://www.w3.org/2000/svg'
         fill='none'
         viewBox='0 0 24 24'
         strokeWidth={1.5}
         stroke='currentColor'
         {...props}
      >
         <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z'
         />
      </svg>
   );
};
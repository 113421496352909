import React from 'react'
import ReactDOM from 'react-dom'
import NavSearch from '../../components/navbar-search'
import { store } from '../../store' 

$.fn.navsearch = function () {
  return this.each(function () {
    var options = $(this).data();
    options.store = store;
    ReactDOM.render(React.createElement(NavSearch, options), this);
  });
};


/**
 * Initialize Data Attribute
 */

$(document).ready(function () {
    $('[data-navsearch]').navsearch();
});
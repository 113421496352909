
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import GuestService from "../../api/guest";
import moment from 'moment'

export const getEvents = createAsyncThunk(
  "guest_events/get",
  async (args, thunkAPI) => {
    try {
      const response = await GuestService.getEvents(args);
      return response;
    } catch (error) {
        console.log("Error: ", error)
        const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue();
    }
  }
);

export const searchEvents = createAsyncThunk(
  "guest_events/search",
  async (args, thunkAPI) => {
    try {
      const response = await GuestService.searchEvents(args);
      return response;
    } catch (error) {
        console.log("Error: ", error)
        const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getFestivals = createAsyncThunk(
    "guest_festivals/get",
    async (args, thunkAPI) => {
      try {
        const response = await GuestService.getFestivals(args);
        return response;
      } catch (error) {
          console.log("Error: ", error)
          const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue();
      }
    }
  );

export const getFestivalById = createAsyncThunk(
    "guest_festival_id/get",
    async (args, thunkAPI) => {
      try {
        const response = await GuestService.getFestivalById(args);
        return response;
      } catch (error) {
          console.log("Error: ", error)
          const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue();
      }
    }
  );

const initialState = {
    fetchingEvents: false,
    fetchingFestivals: false,
    allEvents: [],
    searchResults: [],
    byDate: [],
    festivals: [],
    selectedFestival: '',
    selectedEvent: ''
};

function getDate(d){
    // Veit ekki hversu pottþétt þetta er - ss. ekki skila start date ef hún er < en dagurinn í dag
    // Treysti því að apinn skili bara viðeigandi events (sem eru ekki liðnir þ.e.)
    return moment(d).isAfter(moment()) ? moment(d).format("YYYY-MM-DD") : moment().format('YYYY-MM-DD')
}

function structureDataByDate(data){
    let tmpObj = {}
    data.map((item) => {
        if(item.occurrence == 'single'){
            console.log(item)
        }
        if(item.start){
            (tmpObj[getDate(item.start)] = tmpObj[getDate(item.start)] || []).push(item)
        } else if(item.dates.length > 0){
            // Is this used? - Þarf að laga lógík hér ef þetta er notað
            item.dates.map((date) => {
                (tmpObj[getDate(date.start)] = tmpObj[getDate(date.start)] || []).push(item)
            })
        }
    })
    return tmpObj
}

const guestSlice = createSlice({
  name: "guest",
  initialState,
  reducers: {
    clearSearch(state){
        state.searchResults = []
    },
    setSelectedFestival(state, festival){
        state.selectedFestival = festival
    },
    setSelectedEvent(state, event){
        state.selectedEvent = event
    }
  },
  extraReducers: {
    [getEvents.fulfilled]: (state, action) => {
        // Offset defined = fetching additional data
        if(action.meta.arg){
          state.allEvents.results.concat(action.payload.results);
          // let tmpDated = structureDataByDate(action.payload.results)
          state.byDate = {...state.byDate, ...structureDataByDate(action.payload.results)}
        } else {
          state.allEvents = action.payload;
          state.byDate = structureDataByDate(action.payload.results);
        }
        state.fetchingEvents = false;
    },
    [getEvents.rejected]: (state, action) => {
        state.error = "Ekki tókst að sækja viðburði";
        state.fetchingEvents = false;
    },
    [getEvents.pending]: (state, action) => {
        if(!action.meta.arg){
          state.fetchingEvents = true;
        }
    },
    [searchEvents.fulfilled]: (state, action) => {
        // state.allEvents = action.payload;
        // state.searchEvents = structureDataByDate(action.payload.results);
        state.searchResults = action.payload.results;
    },
    [searchEvents.rejected]: (state, action) => {
        state.error = "Ekki tókst að sækja viðburði";
    },
    [searchEvents.pending]: (state, action) => {
        state.fetching = true
    },
    [getFestivals.fulfilled]: (state, action) => {
        state.festivals = action.payload;
        state.fetchingFestivals = false;
    },
    [getFestivals.pending]: (state) => {
        state.fetchingFestivals = true;
    },
    [getFestivals.rejected]: (state, action) => {
        state.error = "Ekki tókst að sækja hátíðir";
        state.fetchingFestivals = false;
    },
    [getFestivalById.fulfilled]: (state, action) => {
      console.log(action.payload)
        let fest = action.payload.festival;
        fest['events'] = action.payload.iter_events;
        state.selectedFestival = fest;
        state.fetchingFestivals = false;
    },
    [getFestivalById.pending]: (state) => {
        state.fetchingFestivals = true;
    },
    [getFestivalById.rejected]: (state, action) => {
        state.error = "Ekki tókst að sækja hátíð";
        state.fetchingFestivals = false;
    },
  },
});

export const { clearSearch } = guestSlice.actions
const { reducer } = guestSlice;
export default reducer;
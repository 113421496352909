import React from 'react'
import ReactDOM from 'react-dom'
import Wrapper from './wrapper'
import { store } from '../../store' 

$.fn.dashboard = function () {
  return this.each(function () {
    var options = $(this).data();
    options.store = store;
    ReactDOM.render(React.createElement(Wrapper, options), this);
  });
};


/**
 * Initialize Data Attribute
 */

$(document).ready(function () {
  $('[data-dashboard]').dashboard();
});
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { RRule, RRuleSet, rrulestr } from 'rrule'
import { setSelectedFestival } from "../../slices/festivals";
import { setRRule, clearRRule } from "../../slices/misc";
import translations from "../../utils/translations";
import Select from 'react-select';
import moment from 'moment';
import HvirfillDatePicker from "./dates";
import HvirfillTimePicker from "./times";
import { MONTH_MAPPER, NUMERAL_MAPPER, WEEKDAY_MAPPER, INVAL } from "../../constants";


// RRule        ->   this
//--------------------
// Freq         ->   selected
// interval     ->   repeatInterval
// byweekday    ->   repeatDays
// bymonthday   ->   onDay
// bysetpos     ->   selectedNumeral
// bymonth      ->   month

// Get dropdown for react-select-2, needs value and label
function getOptions(type) {
    let tmp = []
    Object.keys(translations[type]).map((key) => {
        tmp.push({ value: key, label: translations[type][key]})
    })
    return tmp
}

export default function RepeatEvent(props){
    let { startDate, endDate } = props
    const dispatch = useDispatch()
    const [selected, setSelected] = useState(INVAL.selected)
    const repeatType = ['daily', 'weekly', 'monthly', 'yearly']
    const onRepeatType = ['on_day', 'on_the']
    const [repeatInterval, setRepeatInterval] = useState(INVAL.repeatInterval)
    const [repeatDays, setRepeatDays] = useState(INVAL.repeatDays)
    const [onRepeat, setOnRepeat] = useState(INVAL.onRepeat)
    const [onDay, setOnDay] = useState(INVAL.onDay)               // If on_day selected for onRepeat
    const [numeral, setNumeral] = useState(INVAL.numeral)
    const [selectedNumeral, setSelectedNumeral] = useState()
    const [weekday, setWeekday] = useState(INVAL.weekday)   
    const [month, setMonth] = useState(INVAL.month)

    const numeralOptions = getOptions('numerals')
    const weekdayOptions = getOptions('days')
    const monthOptions = getOptions('months_long')
    
    useEffect(() => {
        const tmpRule = {
            interval: repeatInterval,
            freq: RRule[selected.toUpperCase()]
        }
        if(repeatDays.length > 0){
            let mapped = repeatDays.map((day) => {
                return WEEKDAY_MAPPER[day]
            })
            tmpRule['byweekday'] = mapped
        }
        if(selected === 'yearly'){
            tmpRule['bymonth'] = [MONTH_MAPPER[month]]
        }
        if(selectedNumeral){
            tmpRule['bysetpos'] = selectedNumeral
        }
        tmpRule['dtstart'] = moment(startDate).toDate();
        tmpRule['until'] =  moment(endDate).toDate();
        // setRrule(tmpRule)
        let rule = new RRule(tmpRule)
        dispatch(setRRule(rule.toString()))
    },
    [repeatInterval, selected, repeatDays, month, selectedNumeral, startDate, endDate])
    
    // 
    useEffect(() => {
        if(onRepeat === 'on_the'){
            setRepeatDays([weekday])
            setSelectedNumeral(NUMERAL_MAPPER[numeral])
        } else {
            setRepeatDays([])
            setSelectedNumeral(null)
        }
    }, [onRepeat, weekday, numeral])



    // Reset values when radio buttons are clicked
    const onRadioChangeHandler = (e) => {
        let type = e.target.value
        setSelected(type)
        setRepeatInterval(INVAL.repeatInterval)
        setRepeatDays(INVAL.repeatDays)
        setNumeral(INVAL.numeral)
        setSelectedNumeral(INVAL.selectedNumeral)
        setWeekday(INVAL.weekday)
        setMonth(INVAL.month)
        setOnDay(INVAL.onDay)
        setOnRepeat(INVAL.onRepeat)

        // if(e.target.value === 'monthly' || e.target.value === 'yearly')
    }

    const handleDayClick = (day) => {
        let tmpDays = [...repeatDays]
        if (tmpDays.includes(day)){
            const index = tmpDays.indexOf(day);
            tmpDays.splice(index, 1);
        } else {
            tmpDays.push(day)
        }
        setRepeatDays(tmpDays)
    }

    function NumeralSelector(){
        return  (
            <div className="smaller-select-container mr-4">
                <Select 
                    value={{ value: numeral, label: translations['numerals'][numeral]}}
                    options={numeralOptions}
                    className=""
                    classNamePrefix="react-select"
                    components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                    onChange={(e) => { setNumeral(e.value)}}
                    >
                </Select>
            </div>
        )
    }

    function WeekdaySelector(){
        return (
            <div className="smaller-select-container mr-4">
                <Select 
                    value={{ value: weekday, label: translations['days'][weekday]}}
                    options={weekdayOptions}
                    className=""
                    components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                    classNamePrefix="react-select"
                    onChange={(e) => {setWeekday(e.value)}}
                    >
                </Select>
            </div>
        )
    }

    function MonthSelector(){
        return (
            <div className="smaller-select-container mr-4">
                <Select 
                    value={{ value: month, label: translations['months_long'][month]}}
                    options={monthOptions}
                    className=""
                    components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                    classNamePrefix="react-select"
                    onChange={(e) => {setMonth(e.value)}}
                    >
                </Select>
            </div>
        )
    }

    function RadioSelection(){
        return (<>
                { repeatType.map((rep) => {
                    return (
                        <>
                        <div className="radio-container d-flex align-items-center">
                            <input checked={ selected == rep } type='radio' value={rep} name={rep} onChange={onRadioChangeHandler} className="mr-3"/> 
                            <label className="mb-0">{ translations.repeat[rep].label }</label>
                        </div>
                        </>
                    )
                })}
            </>
        )
    }

    function RenderDaily() {
        return <RepeatSelection></RepeatSelection>
    }

    function RenderWeekly() {
        return (<>
            <RepeatSelection></RepeatSelection>
            <div className="d-flex justify-content-between">
                { Object.keys(translations.days_short).map((day) => {
                    return <div onClick={() => handleDayClick(day)} className={`d-flex align-items-center justify-content-center col weekdays ${repeatDays.includes(day) ? 'is-selected': ''}`}><h5>{translations.days_short[day]}</h5></div>
                }) }
            </div>
        </>
        )
    }

    function RenderMonthly() {
        return (<>
            <div className="border-bottom pb-4"><RepeatSelection></RepeatSelection></div>
            <div className="border-bottom p-4 d-flex align-items-center">
                <div className="radio-container d-flex align-items-center">
                    <input className="mr-4" checked={ onRepeat == 'on_day' } type='radio' value={'on_day'} name={'on_day'} onChange={() => setOnRepeat('on_day')}/> 
                    <label className="mr-4">{ translations.repeat[selected]['on_day'] }</label>
                </div>
                <div className="input-number-container">
                    <input className="mr-4" type="number" value={onDay} min='1' max="31" onChange={(e) => setOnDay(e.target.value)}/>
                </div>
            </div>
            <div className="p-4 d-flex align-items-center">
                <div className="radio-container m-0">
                    <input checked={ onRepeat == 'on_the' } type='radio' value={'on_the'} name={'on_the'} onChange={() => setOnRepeat('on_the')}/> 
                </div>
                <NumeralSelector></NumeralSelector>
                <WeekdaySelector></WeekdaySelector>
            </div>
        </>)
    }

    function RenderYearly() {
        return (<>
            <div className="border-bottom px-5 py-4 d-flex align-items-center">
                <div className="radio-container">
                    <label>{ translations.repeat[selected]['on_day'] }</label>
                    <input checked={ onRepeat == 'on_day' } type='radio' value={'on_day'} name={'on_day'} onChange={() => setOnRepeat('on_day')}/> 
                </div>
                <p className="mr-3 smaller mb-0">Þann</p>
                <div className="d-flex">
                    <div className="input-number-container mr-3">
                        <input type="number" value={onDay} min='1' max="31" onChange={(e) => setOnDay(e.target.value)}/>
                    </div>
                    <MonthSelector></MonthSelector>
                </div>
            </div>
            <div className="d-flex align-items-center px-5 py-4">
                <div className="radio-container">
                    <input checked={ onRepeat == 'on_the' } type='radio' value={'on_the'} name={'on_the'} onChange={() => setOnRepeat('on_the')}/> 
                </div>
                <NumeralSelector></NumeralSelector>
                <WeekdaySelector></WeekdaySelector>
                <p className="mr-3 smaller mb-0">í</p> <MonthSelector></MonthSelector>
            </div>
        </>)
    }

    // Number interval selector for days/weeks/months
    function RepeatSelection(){
        return (<>
            <div className="d-flex align-items-center px-4 pb-4">
                <h5 className="mr-3">{ translations.repeat[selected].every} </h5>
                <div className="input-number-container mr-3">
                    <input type="number" min="1" value={repeatInterval} onChange={(e) => setRepeatInterval(parseInt(e.target.value))}/>
                </div>
                <h5>{ translations.repeat[selected].rep} </h5>
            </div>
            </>
        )
    }

    return (<>
        <div className="border pt-4 repeat-event">
            <h5 className="bold px-4">{ translations.repeat.label }</h5>
            <div className="d-flex justify-content-between px-4">
                <RadioSelection></RadioSelection>
            </div>
            { selected === 'daily' && 
                <RenderDaily></RenderDaily>
            }
            { selected === 'weekly' && 
                <RenderWeekly></RenderWeekly>
            }
            { selected === 'monthly' && 
                <RenderMonthly></RenderMonthly>
            }
            { selected === 'yearly' && 
                <RenderYearly></RenderYearly>
            }
        </div>
    </>
    )

}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import FestivalService from "../../api/festivals";
import moment from 'moment'


function errorHandler(thunk, error){
  console.log("Error: ", error)
  const message =
    (error.response &&
      error.response.data &&
      error.response.data.message) ||
    error.message ||
    error.toString();
  //thunkAPI.dispatch(setMessage(message));
  return thunk.rejectWithValue();
}

export const getFestival = createAsyncThunk(
  "festival/get",
  async (args, thunkAPI) => {
    try {
      const response = await FestivalService.getFestival(args);
      //thunkAPI.dispatch(setMessage(response.data.message));
      return response;
    } catch (error) {
      return errorHandler(thunkAPI, error)
    }
  }
);

export const getUserFestivals = createAsyncThunk(
  "userfestivals/get",
  async (args, thunkAPI) => {
    try {
      const response = await FestivalService.getUserFestivals(args);
      //thunkAPI.dispatch(setMessage(response.data.message));
      return response;
    } catch (error) {
      return errorHandler(thunkAPI, error)
    }
  }
);

export const createFestival = createAsyncThunk(
  "userfestivals/create",
  async (args, thunkAPI) => {
    try {
      const response = await FestivalService.createFestival(args);
      //thunkAPI.dispatch(setMessage(response.data.message));
      return response;
    } catch (error) {
      return errorHandler(thunkAPI, error)
    }
  }
);

export const updateFestival = createAsyncThunk(
  "userfestivals/patch",
  async (args, thunkAPI) => {
    try {
      const response = await FestivalService.updateFestival(args);
      //thunkAPI.dispatch(setMessage(response.data.message));
      return response;
    } catch (error) {
      return errorHandler(thunkAPI, error)
    }
  }
);

export const addEventsToFestival = createAsyncThunk(
  "userfestivals/addEvents",
  async (args, thunkAPI) => {
    try {
      const response = await FestivalService.addEventsToFestival(args);
      //thunkAPI.dispatch(setMessage(response.data.message));
      return response;
    } catch (error) {
      return errorHandler(thunkAPI, error)
    }
  }
);

const initialState = {
  selectedFestival: {dates: [], evs: []},
  fetching: false,
  editing: false,
  results: [],
  byDate: { all: [], now: [], past: [], future: [], all: [], nodate: [] },
  showOwnEventsOnly: false
};

const festivalSlice = createSlice({
  name: "festivals",
  initialState,
  reducers: {
    setSelectedFestival(state, festival) {
      state.selectedFestival = festival.payload
    },
    clearSelectedFestival(state) {
      state.selectedFestival = ""
      state.editing = false
    },
    editFestival(state, festival) {
      state.selectedFestival = festival.payload,
        state.editing = true
    },
    setShowOwnEvents(state, data) {
      state.showOwnEventsOnly = data.payload
    }
  },
  extraReducers: {
    [getFestival.fulfilled]: (state, action) => {
      state.fetching = false;
      state.selectedFestival = action.payload
    },
    [getFestival.pending]: (state, action) => {
      state.fetching = true;
    },


    [addEventsToFestival.fulfilled]: (state, action) => {
      let res = action.payload.data.results
      state.byDate = res;
      let newFest = res.all.find(x => x.id === state.selectedFestival.id);
      state.selectedFestival = newFest
    },
    [addEventsToFestival.rejected]: (state, action) => {
      state.error = "Ekki tókst að hengja viðburði hátíð";
    },
    [getUserFestivals.fulfilled]: (state, action) => {
      state.byDate = action.payload.results;
      state.fetching = false;
    },
    [getUserFestivals.pending]: (state, action) => {
      state.fetching = true;
    },
    [getUserFestivals.rejected]: (state, action) => {
      state.error = "Ekki tókst að sækja hátíðir";
      state.fetching = false;
    },
    [createFestival.fulfilled]: (state, action) => {
      state.byDate = action.payload.results;
      console.log("Hátíð tókst")
    },
    [createFestival.rejected]: (state, action) => {
      state.error = "Ekki tókst að búa til hátíð";
    },
    [updateFestival.fulfilled]: (state, action) => {
      let res = action.payload.data.results
      state.byDate = res;
      let newFest = res.all.find(x => x.id === state.selectedFestival.id);
      state.selectedFestival = newFest
    },
    [updateFestival.rejected]: (state, action) => {
      state.error = "Ekki tókst að uppfæra til hátíð";
    },
  },
});

export const { setSelectedFestival, clearSelectedFestival, editFestival, setShowOwnEvents } = festivalSlice.actions
const { reducer } = festivalSlice;
export default reducer;